import { BasicFontSize } from '@/styles/Typography';
import { roundTo } from '@/util/number';

export const X_AXIS_HEIGHT = roundTo(3 * BasicFontSize);
export const LEFT_OFFSET = roundTo(5 * BasicFontSize);
export const COLUMN_HEIGHT = roundTo(3 * BasicFontSize);
export const BLOCK_HEIGHT = roundTo(2.25 * BasicFontSize);
export const LEFT_MENU_WIDTH = roundTo(6.25 * BasicFontSize);

// seconds
export const DEFAULT_VIEWPORT_RANGE: [number, number] = [0, 60];
// seconds, 15 minutes
export const DEFAULT_TIME_RANGE: [number, number] = [0, 60 * 15];
// min, max zoom scale
// 10% - 400%
export const ZOOM_SCALE_RANGE: [number, number] = [0.1, 5];
// Block colors
export const BLOCK_COLOR_LIST = ['#4C75DD'];
// snap time
export const SNAP_TIME = 0.2;
// Min resize width
export const MIN_RESIZE_WIDTH = 3.5 * BasicFontSize;
