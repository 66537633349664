import { Black, Grey, Secondary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

export const StyledZeroShotScreenPlayHeader = styled.header`
  background-color: ${Black};
  height: 3.375rem;
  border-bottom: 1px solid ${Grey[500]};
  .screenplay {
    display: flex;
    width: 100%;
    height: 100%;
    flex: auto;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    .logo {
      width: 19.3125rem;
      height: 1.25rem;
      position: relative;
      cursor: pointer;
      &::after {
        content: 'cbt';
        padding: 0.05rem 0.2rem;
        border-radius: 0.25rem;
        position: absolute;
        right: -95px;
        top: -5px;
        display: inline-block;
        color: ${Secondary[200]};
        border: 1px solid ${Secondary[200]};
        font-size: ${FontSize['Md']};
      }
    }
    .menu {
      height: 1.25rem;
      color: ${Grey[300]};
      text-align: center;
      font-family: Inter;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
    }
  }
  .user-info {
    position: absolute;
    right: 1.75rem;
    top: 0.65rem;
    .initial {
      width: 2rem;
      height: 2rem;
    }
  }
  /* tmp: zero-shot page */
  .btn-admin {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    position: absolute;
    top: 0.8rem;
    right: 8rem;
    z-index: 1;
    font-size: ${FontSize['Md']};
    font-weight: ${FontWeight['SemiBold']};
  }
`;
