import { Grey, Primary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import FolderIcon from '../components/assets/icons/FolderIcon.svg';

const StyledZeroShotResultItem = styled.li`
  position: relative;
  border-bottom: 1px solid ${Grey[500]};
  /** 아이템 공통 */
  .inner {
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
    margin-right: 0.5rem;
    align-items: center;
    .item-check {
      width: 3.25rem;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .item-name {
      flex: 1;
      color: ${Grey[100]};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
      line-height: 150%;
      margin-right: 1.25rem;
    }
    .editable {
      font-size: ${FontSize['Md']};
      width: 23.125rem;
      border: 0;
      outline: 0;
      height: 1.25rem;
      color: ${Grey[100]};
      background-color: transparent;
      word-break: keep-all;
      line-height: 150%;
      margin-right: 1.25rem;
      white-space: nowrap;
    }
    .item-time {
      margin-right: 1rem;
      white-space: nowrap;
      width: 6rem;
      text-align: right;
      .current-time {
        color: ${Primary[200]};
        &:after {
          content: ' / ';
          color: ${Grey[200]};
        }
      }
    }
  }
  .item-player {
    display: none;
    padding: 0.5rem 2rem 1rem 3rem;
    .sup-waveform {
      width: calc(100% - 8rem);
      height: 2rem;
    }
  }
  .player-button {
    margin-left: 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
    position: absolute;
    top: 0.7rem;
    right: 4rem;
    width: 5rem;
    display: none;
  }
  .expand-button {
    transform: rotate(-90deg);
  }
  &:hover {
    .item-time {
      display: none;
    }
    .item-name {
      padding-right: 6rem;
    }
    .player-button {
      display: flex;
    }
  }
  &.expanded {
    .item-player {
      display: flex;
      align-items: center;
    }
    .expand-button {
      transform: rotate(0deg);
    }
    .player-button {
      display: flex;
      top: 3.3rem;
    }
    &:hover {
      .item-time {
        display: block;
      }
    }
  }
  .item-radio {
    margin-left: 0.875rem;
    display: inline-flex;
    gap: 0.75rem;
    align-items: center;
    width: 8.5rem;
    color: ${Grey[100]};
  }
  &:hover {
    background-color: ${Grey[700]};
    .item-radio,
    .item-time,
    .item-name {
      color: ${Grey[50]};
    }
  }
  &.selected {
    .item-radio,
    .item-time,
    .item-name {
      color: ${Grey[50]};
    }
  }
  &.group {
    background-color: ${Grey[700]};
    align-items: center;
    .sup-group {
      font-weight: ${FontWeight.SemiBold};
      display: inline-flex;
      align-items: center;
      padding-left: 0.75rem;
      gap: 1.25rem;
      &::before {
        content: ' ';
        display: inline-flex;
        background: url(${FolderIcon});
        background-size: contain;
        width: 1.5rem;
        height: 1.5rem;
        left: 0.75rem;
        top: 0.5rem;
      }
    }
    .expand-button {
      margin-right: -0.3rem;
    }
    &.opened {
      .expand-button {
        transform: rotate(0deg);
      }
    }
  }
`;
export default StyledZeroShotResultItem;
