import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { Dropdown, DropdownItem, DropdownList } from '@/components/Dropdown';
import { lineListAtom } from '@/pages/screenplay/stores/atoms/project';
import { Grey } from '@/styles/Colors';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { _addLine, _loadLines, _loadScene, _loadTakes } from '../api/project';
import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import { useDataContext } from '../providers/DataContextProvider';
import { projectVoiceProfileListAtom } from '../stores/atoms/voice';
import { DEFAULT_PARAMETER } from '../stores/data/config';
import { Draft } from '../stores/project';
import { StyledScriptDropDown } from '../styles/StyledDropDown';
import StyledScriptTab from './StyledScriptTab';

const selectDocList = ['TXT'];
const ScriptTab = () => {
  const { t } = useTranslation();
  const { activeSceneId } = useDataContext();
  const { projectInfo } = useDataContext();
  const projectVoiceProfileList = useRecoilValue(projectVoiceProfileListAtom);
  const setLineList = useSetRecoilState(lineListAtom);
  const [isDocDropDownOpen, setIsDocDropDownOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const onCloseDocDropDown = useCallback(() => {
    setIsDocDropDownOpen(false);
  }, [setIsDocDropDownOpen]);

  const [selectedDocType, setSelectedDocType] = useState(selectDocList[0]);

  // 라인이 선택한 텍스트들만 조합해서 txt파일로 저장한다.
  const exportScene = useCallback(async () => {
    const lineList = await _loadLines(activeSceneId);
    const takeList = await _loadTakes(activeSceneId);
    const scene = await _loadScene(activeSceneId);
    const lineScriptList = lineList?.reduce((acc, line) => {
      const take =
        takeList?.find((take) => take.id === line.selectedTakeId) || line.draft;
      take?.text && acc.push(take.text);
      return acc;
    }, [] as string[]);
    const string = lineScriptList?.join('\n') || '';
    const blob = new Blob([string], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${scene?.name}.txt`;
    a.click();
    URL.revokeObjectURL(url);
  }, [activeSceneId]);

  const addLines = useCallback(
    async (content: string[]) => {
      const lineList = await _loadLines(activeSceneId);
      const takeList = await _loadTakes(activeSceneId);
      const line = lineList?.pop();
      const take =
        takeList?.find((take) => take.id === line?.selectedTakeId) ||
        line?.draft;
      const defaultVoice = projectVoiceProfileList[0]?.default_voice_id;
      for (const text of content) {
        const newLine = {
          sceneId: activeSceneId,
          projectId: projectInfo.id,
          draft: {
            text,
            voiceId: take?.voiceId || defaultVoice,
            parameter: DEFAULT_PARAMETER,
          } as Draft,
        };
        await _addLine(newLine);
      }
      const savedLines = await _loadLines(activeSceneId);
      if (savedLines) {
        setLineList(savedLines);
      }
    },
    [activeSceneId, projectInfo.id, projectVoiceProfileList, setLineList]
  );

  const importFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let reader = new FileReader();
      reader.onloadend = function (evt: ProgressEvent<FileReader>) {
        if (evt.target?.readyState === FileReader.DONE) {
          if (typeof evt.target.result !== 'string') {
            return;
          } else {
            const lineScriptArray = evt.target.result.split('\n');
            lineScriptArray.length && addLines(lineScriptArray);
          }
        }
      };
      if (e.target.files) {
        const file = e.target.files[0];
        file && reader.readAsText(file);
      }
    },
    [addLines]
  );

  return (
    <StyledScriptTab className="script-tab">
      <section className="file-convert">
        <label htmlFor="btn-import-file" className="btn-import-file">
          {t('Import File')}
          <input
            type="file"
            color="transparent"
            id="btn-import-file"
            accept="text/plain"
            multiple={false}
            onChange={importFile}
          />
        </label>
      </section>
      <section className="file-convert">
        <span className="export-title">{t('Export Text File')}</span>
        <section className="export-format">
          <span>{t('File format')}</span>
          <Dropdown
            isOpen={isDocDropDownOpen}
            onClose={onCloseDocDropDown}
            closeOnClickOutside={true}
            placement="bottom-left"
            preventRef={ref}
            style={{
              padding: 0,
              border: 0,
              marginLeft: '1rem',
              background: 'none',
            }}
          >
            <StyledScriptDropDown>
              <DropdownList className="sp-drawer">
                {selectDocList.map((label) => (
                  <DropdownItem
                    key={`dropdown_list_${label}`}
                    style={{ padding: '0.5rem 0.5rem', cursor: 'pointer' }}
                    label={label}
                    isSelected={label === selectedDocType}
                    onClick={() => {
                      setSelectedDocType(label);
                      onCloseDocDropDown();
                    }}
                  />
                ))}
              </DropdownList>
            </StyledScriptDropDown>
          </Dropdown>
          <span className="format-selector" ref={ref}>
            <span
              className="format-selector-value"
              onClick={() => setIsDocDropDownOpen(!isDocDropDownOpen)}
            >
              {selectedDocType}
            </span>
            <IconButton
              size="sm"
              color="transparent"
              className="btn-open-selector"
              onClick={() => setIsDocDropDownOpen(!isDocDropDownOpen)}
            >
              <BottomArrow />
            </IconButton>
          </span>
        </section>
        <section className="export-btn">
          <Button
            size="lg"
            variant="outlined"
            className="btn-export"
            color={Grey[400]}
            onClick={exportScene}
          >
            {t('Export as Document')}
          </Button>
        </section>
      </section>
    </StyledScriptTab>
  );
};
export default ScriptTab;
