import { Language } from '@/pages/screenplay/stores/data/config';
import { White } from '@/styles/Colors';
import classNames from 'classnames';
import React from 'react';

import ToggleDownIcon from '../components/assets/icons/ToggleDownIcon.svg';
import IconButton from '../components/IconButton';
import StyledZeroShotResultItem from './StyledZeroShotResultItem';
import { langLabelMap } from './ZeroShotResultPanel';

export interface GroupItem {
  id: string;
  name: string;
  lang: Language;
  isOpened: boolean;
}
export interface ZeroShotResultItemProps {
  item: GroupItem;
  toggleGroup: (id: string) => void;
}
const ZeroShotResultGroupItem: React.FC<ZeroShotResultItemProps> = ({
  item,
  toggleGroup,
}) => {
  return (
    <StyledZeroShotResultItem
      className={classNames('group', item.isOpened && 'opened')}
    >
      <section className="inner">
        <section className="sup-group">
          <span>{langLabelMap[item.lang]}</span>
        </section>
        <section className="sup-files-expand">
          <section className="item-expand">
            <IconButton
              className="expand-button"
              iconColor={White}
              onClick={(e) => {
                e.stopPropagation();
                toggleGroup(item.id);
              }}
              width="2.5rem"
              height="2.5rem"
              iconSize="1.25rem"
              icon={ToggleDownIcon}
            />
          </section>
        </section>
      </section>
    </StyledZeroShotResultItem>
  );
};
export default ZeroShotResultGroupItem;
