import { Black, Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

export const StyledScreenPlay = styled.section`
  display: flex;
  flex: auto;
  width: 100%;
  .sp-middle-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .sp-middle-top {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow: hidden;
      height: 100%;
      .scene-writer-right-panel {
        width: 20rem;
        margin: 1rem;
        position: relative;
        &-guide {
          background-color: ${Black};
          position: absolute;
          left: 1px;
          top: 1px;
          bottom: 1px;
          right: 1px;
          /* z-index: 1000; */
        }
      }
    }
  }
  .scene-editor {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 103.75rem;
    width: 100%;
    &-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      overflow: hidden;
      background-color: ${Black};
    }
  }
  .sp-top-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .tab-content {
      height: 100%;
      overflow-y: auto;
    }
  }
  .sp-project {
    background-color: ${Grey[600]};
    width: 19.9rem;
    border-right: 1px solid ${Grey[500]};
  }
  .sp-scene-writer {
    margin: 1rem;
    margin-right: 0;
    height: calc(100% - 2rem);
  }
  .sp-media {
    width: 28rem;
    background-color: ${Grey[600]};
  }
  .sp-control {
    border-left: 1px solid ${Grey[500]};
    background-color: ${Grey[600]};
    width: 19.875rem;
  }
  .sp-bottom-area {
    position: relative;
    .help {
      text-decoration-line: none;
      position: absolute;
      display: flex;
      top: -5.8rem;
      right: 1.8rem;
      width: 7.4rem;
      height: 4rem;
      background-color: ${Grey[500]};
      border: ${Grey[500]};
      border-radius: 0.4rem;
      align-items: center;
      justify-content: center;
      .text {
          font-weight: ${FontWeight['SemiBold']};
          padding-left: .2rem;
          font-size: 1.4rem;
          color: ${Grey[50]};
    }
  }
`;
export default StyledScreenPlay;
