import Loading from '@/components/Loading/Loading';
import { Black } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledFullScreenLoader = styled.div`
  position: fixed;
  top: 3.25rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${Black};
  opacity: 0.88;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    width: 10rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    .loading {
      position: relative;
    }
    p {
      font-size: ${FontSize['Md']};
      font-weight: ${FontWeight['SemiBold']};
      line-height: 1.5;
    }
  }
`;

const FullScreenLoader = () => (
  <StyledFullScreenLoader>
    <div className="wrapper">
      <Loading className="loading" />
      <p>Loading Project</p>
    </div>
  </StyledFullScreenLoader>
);

export default FullScreenLoader;
