import { BasicFontSize } from '@/styles/Typography';
import { roundTo } from '@/util/number';
import { useMemo } from 'react';

import { DragInfo } from './AudioContainer';
import { BlockInfo } from './Block';
import { BLOCK_HEIGHT, COLUMN_HEIGHT } from './const';

export interface GapBlockInfo
  extends Pick<BlockInfo, 'startX' | 'endX' | 'startY' | 'endY'> {
  seconds: number;
  isMultiple?: boolean;
}

interface GapBlockProps {
  data: GapBlockInfo;
  dragData: DragInfo;
}

const GapBlock = ({ data, dragData }: GapBlockProps) => {
  const guideTop = useMemo(() => {
    const top =
      data.startY < dragData.startY ? 0 : dragData.startY - data.startY;
    return top - (COLUMN_HEIGHT - BLOCK_HEIGHT) / 2;
  }, [dragData, data]);

  const guideHeight = useMemo(() => {
    let height =
      data.startY < dragData.startY
        ? dragData.endY - data.startY
        : data.endY - dragData.startY;
    if (data.isMultiple) {
      height = Math.max(data.endY - data.startY, height);
    }
    return height + (COLUMN_HEIGHT - BLOCK_HEIGHT) / 2;
  }, [dragData, data]);

  if (data.seconds < 0) return null;
  return (
    <div
      className="gap-block"
      style={{
        width: `${data.endX - data.startX}px`,
        height: `${BLOCK_HEIGHT}px`,
        transform: `translate(${data.startX}px, ${data.startY}px)`,
      }}
    >
      {data.seconds > 0 && (
        <span
          className="gap-block-text"
          style={{
            top: `${
              data.endX - data.startX < 1.8 * BasicFontSize
                ? `${data.endY - data.startY + 4}px`
                : '50%'
            }`,
          }}
        >
          {roundTo(data.seconds, 1)}s
        </span>
      )}
      {data.seconds > 0 ? (
        <>
          <span
            className="gap-block-guide start"
            style={{ top: guideTop, height: guideHeight }}
          ></span>
          <span
            className="gap-block-guide end"
            style={{ top: guideTop, height: guideHeight }}
          ></span>
        </>
      ) : (
        <span
          className="gap-block-snap gap-block-solid"
          style={{ top: guideTop, height: guideHeight }}
        ></span>
      )}
    </div>
  );
};

export default GapBlock;
