import { atom } from 'recoil';

import {
  AudioFileMap,
  AudioItemInfo,
  AudioPlayerState,
  Recorder,
} from '../audios';

/**
 * audio file map
 * 오디오 파일 정보 저장, web audio api를 통한 플레이에 사용된다.
 */
export const audioFileMapAtom = atom<AudioFileMap>({
  key: 'audioFileMap',
  default: {},
});

export const audioPlayerStateAtom = atom<AudioPlayerState | null>({
  key: 'audioPlayerState',
  default: null,
});

export const currentPlayingAudioInfoListAtom = atom<AudioItemInfo[]>({
  key: 'currentPlayingAudioInfoList',
  default: [],
});

export const currentPlaybackAtom = atom<number>({
  key: 'currentPlayback',
  default: 0,
});

export const recorderAtom = atom<Recorder | null>({
  key: 'recorder',
  default: null,
});
