import styled from '@emotion/styled';

const StyledAfterSignUp = styled.section`
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  .layout {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

export default StyledAfterSignUp;
