import { ReactComponent as Linkout } from '@/assets/icons/Linkout.svg';
import { ReactComponent as AddIcon } from '@/pages/screenplay/assets/icons/project/AddIcon.svg';
import { ReactComponent as Logo } from '@/pages/screenplay/assets/images/PlayLogo.svg';
import { MAIN_WEB_URL } from '@/pages/screenplay/stores/data/config';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import Button from '../../../components/Button/Button';
import { Grey, Primary } from '../../../styles/Colors';
import {
  _addProject,
  _addScene,
  _deleteProject,
  _loadLines,
  _loadProjects,
  _loadScenes,
  _updateProject,
} from '../api/project';
import { useLog } from '../hooks/useLog/useLog';
import { projectListAtom } from '../stores/atoms/project';
import { Project } from '../stores/project';
import ProjectItem from './ProjectItem';
import StyledProjects from './StyledProjects';

const ProjectList = () => {
  const { user } = useSUPAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [projectList, setProjectList] = useRecoilState(projectListAtom);
  const loadProjectList = useCallback(async () => {
    const projectList = (await _loadProjects()) as Project[];
    // scene, line 정보를 가져와서 projectList에 추가
    const scenes = await _loadScenes();
    const lines = await _loadLines();
    setProjectList(
      projectList?.map((project) => {
        const projectScenes =
          scenes?.filter((scene) => scene.projectId === project.id) || [];
        return {
          ...project,
          sceneCount: projectScenes.length,
          lineCount:
            lines?.filter((line) =>
              projectScenes.some((scene) => scene.id === line.sceneId)
            ).length || 0,
        };
      })
    );
  }, [setProjectList]);

  const { track } = useLog();

  const addProject = useCallback(async () => {
    const project = (await _addProject()) as Project;
    await _addScene(project.id);
    track('ADD_NEW_PROJECT', { projectId: project.id });
    loadProjectList();
    navigate(`/${project.id}/editor`);
  }, [loadProjectList, navigate, track]);
  const onChangeProjectName = useCallback(
    async (id: string, name: string) => {
      const project = (projectList as Project[]).find(
        (project) => project.id === id
      );
      if (!project) return;
      await _updateProject({ ...project, name });
      loadProjectList();
    },
    [projectList, loadProjectList]
  );

  const removeProject = useCallback(
    async (id: string) => {
      await _deleteProject(id);
      loadProjectList();
    },
    [loadProjectList]
  );

  const isAdmin = useMemo(() => {
    let isAdmin = false;
    if (!user?.permissions) return isAdmin;
    user.permissions.forEach((permission) => {
      // tmp: 추후 대소문자 정보 바뀔 수 있으므로 소문자로 변경 후 비교
      permission.toLowerCase().includes('/company/supertone') &&
        (isAdmin = true);
    });
    return isAdmin;
  }, [user?.permissions]);

  useEffect(() => {
    loadProjectList();
  }, [loadProjectList]);

  return (
    <StyledProjects className="projects">
      <section className="header">
        <section className="screenplay project-header">
          <h1 className="logo">
            <Logo />
          </h1>
        </section>
        {/* tmp: zeroshot */}
        {isAdmin && (
          <Button
            variant="outlined"
            className="btn-admin"
            color={Primary[400]}
            size="sm"
            onClick={() => {
              navigate('/admin/zero-shot');
            }}
          >
            Zero Shot
          </Button>
        )}
      </section>
      <section className="project-wrap">
        <section className="project-title">
          <h2>{t('Projects')}</h2>
          <Button
            variant={'contained'}
            color={Grey[500]}
            size={'xl'}
            radius={'md'}
            fullWidth={false}
            endIcon={<Linkout />}
            style={{ marginRight: '10px' }}
            onClick={() => {
              window.open(MAIN_WEB_URL, '_blank')?.focus();
            }}
          >
            Visit Website
          </Button>
        </section>
        <section className="project-contents">
          <ul className="project-list">
            <li className="project-list-item create-new" onClick={addProject}>
              <section className="btn-create-new">
                {t('Create New Project')}
              </section>
              <section className="icon-create-new">
                <AddIcon />
              </section>
            </li>
            {projectList?.map((project) => (
              <ProjectItem
                key={project.id}
                project={project}
                onDelete={removeProject}
                onChangeName={onChangeProjectName}
              />
            ))}
          </ul>
        </section>
      </section>
    </StyledProjects>
  );
};

const Projects = () => {
  return (
    <StyledProjects className="projects">
      <ProjectList />
    </StyledProjects>
  );
};
export default Projects;
