import IconButton from '@/components/Button/IconButton';
import Loading from '@/components/Loading/Loading';
import Waveform from '@/components/Waveform/Waveform';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import { ReactComponent as DeleteTake } from '../assets/icons/DeleteTake.svg';
import { ReactComponent as LinePlayIcon } from '../assets/icons/LinePlayIcon.svg';
import { ReactComponent as LineStopIcon } from '../assets/icons/LineStopIcon.svg';
import { ReactComponent as OptionSelected } from '../assets/icons/OptionSelected.svg';
import { ReactComponent as OptionUnSelected } from '../assets/icons/OptionUnSelected.svg';
import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import { ExtendTake } from '../stores/project';
import { StyledTakeItem } from './StyledTakeItem';
import useSingleTakePlay from './useSingleTakePlay';

interface TakeItemProps {
  isSelected: boolean;
  isActive: boolean;
  take: ExtendTake;
  onSelect: (id: string) => void;
  deleteTake: (id: string) => void;
  num: string;
}
const TakeItem: React.FC<TakeItemProps> = ({
  isSelected,
  isActive,
  onSelect,
  take,
  deleteTake,
  num,
}) => {
  const { takeJobIdMap } = useSceneEditorContext();
  const { track } = useLog();
  const onDeleteTake = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      deleteTake(take.id);
      track('DELETE_TAKE', {
        text: take.text,
        voiceId: take.voiceId,
        language: take.language,
        pitchShift: take.parameter?.pitch_shift,
        pitchVariance: take.parameter?.pitch_variance,
        speed: take.parameter?.speed,
        takeId: take.id,
        lineId: take.lineId,
        projectId: take.projectId,
      });
    },
    [deleteTake, take, track]
  );
  const onClickTake = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onSelect(take.id);
    },
    [take.id, onSelect]
  );

  const { handlePlayStop, isPlaying, currentPosition } = useSingleTakePlay(
    take.id
  );

  return (
    <StyledTakeItem
      key={take.id}
      className={classNames(
        'line-inner-item line-take-item',
        isActive && 'active',
        isSelected && 'selected',
        take.type === 'cvc' && 'cvc',
        (!!takeJobIdMap[take.id] || !take.audioBuffer) && 'fetching'
      )}
      onClick={onClickTake}
    >
      <section className={classNames('line-takes-label line-take-select')}>
        <IconButton
          className={classNames('btn-take-select', !isSelected && 'unSelected')}
          isFillCurrentColor={false}
          onClick={onClickTake}
        >
          {isSelected ? <OptionSelected /> : <OptionUnSelected />}
        </IconButton>
      </section>
      <section className="line-takes-content">
        <span className="line-take-number">Take {num}</span>
        {take.audioBuffer?.duration && (
          <span className="line-take-duration">
            {take.audioBuffer.duration.toFixed(1)}s
          </span>
        )}
        <span className="line-take-play">
          <IconButton
            variant="none"
            isFillCurrentColor={false}
            color="transparent"
            className={classNames({
              'btn-take-play': !isPlaying,
              'btn-take-stop': isPlaying,
            })}
            onClick={(e) => {
              e.stopPropagation();
              handlePlayStop();
              track('PLAY_TAKE', {
                text: take.text,
                voiceId: take.voiceId,
                language: take.language,
                pitchShift: take.parameter?.pitch_shift,
                pitchVariance: take.parameter?.pitch_variance,
                speed: take.parameter?.speed,
                takeId: take.id,
                lineId: take.lineId,
                projectId: take.projectId,
              });
            }}
          >
            {isPlaying ? <LineStopIcon /> : <LinePlayIcon />}
          </IconButton>
        </span>
        <span
          className="line-take-waveform"
          onClick={(e) => e.stopPropagation()}
        >
          {take.audioBuffer && (
            <Waveform
              audioBuffer={take.audioBuffer}
              playback={currentPosition}
              onPlaybackChange={handlePlayStop}
            />
          )}
        </span>
        <span className="line-take-delete">
          <IconButton className="btn-take-delete" onClick={onDeleteTake}>
            <DeleteTake />
          </IconButton>
        </span>
      </section>
      <section className="fetching-status">
        <span className="loader">
          <Loading />
        </span>
        <span className="loader-text">Generating</span>
      </section>
    </StyledTakeItem>
  );
};
export default TakeItem;
