import { Language } from '@/pages/screenplay/stores/data/config';

import FlagJP from '../assets/images/Flag_JP.svg';
import FlagKR from '../assets/images/Flag_KR.svg';
import FlagUS from '../assets/images/Flag_US.svg';

export type LanguageParam = 'ko-kr' | 'en-us' | 'ja';
interface SampleText {
  type: 'game' | 'audiobook' | 'ad' | 'news' | 'podcast';
  lang: LanguageParam;
  content: string;
}

export const sampleSentences: SampleText[] = [
  // 게임 스타일 (한국어, 영어, 일본어)
  {
    type: 'game',
    lang: 'ko-kr',
    content:
      '이 마법의 힘이 나를 이끈다. 고대의 비밀을 밝히는 여정이 이제 시작되었어.',
  },
  {
    type: 'game',
    lang: 'en-us',
    content:
      'This magic power leads me. The journey to unveil the ancient secrets has now begun.',
  },
  {
    type: 'game',
    lang: 'ja',
    content: 'この魔法の力が私を導く。古代の秘密を明らかにする旅が今始まった。',
  },
  {
    type: 'game',
    lang: 'ko-kr',
    content: '적들이 곳곳에 숨어 있어. 무기를 준비하고, 시야를 넓게 가져가자.',
  },
  {
    type: 'game',
    lang: 'en-us',
    content:
      'Enemies are hiding everywhere. Prepare your weapons and keep a wide view.',
  },
  {
    type: 'game',
    lang: 'ja',
    content: '敵は至る所に隠れている。武器を準備して、視野を広く持とう。',
  },
  {
    type: 'game',
    lang: 'ko-kr',
    content:
      '어둠 속에서 빛나는 눈을 가진 드래곤이 숨쉬고 있었다. 용사는 드래곤의 둥지로 조심스럽게 다가가며 긴장의 숨을 삼켰다.',
  },
  {
    type: 'game',
    lang: 'en-us',
    content:
      "In the darkness, a dragon with glowing eyes was breathing. The warrior cautiously approached the dragon's lair, swallowing a breath of tension.",
  },
  {
    type: 'game',
    lang: 'ja',
    content:
      '闇の中で、光る目を持つドラゴンが息をしていた。戦士は緊張の息を呑みながら、ドラゴンの巣へと慎重に近づいた。',
  },

  // 오디오북 스타일 (한국어, 영어, 일본어)
  {
    type: 'audiobook',
    lang: 'ko-kr',
    content:
      '그녀는 창가에 앉아 바깥 풍경을 바라보았다. 가을 바람이 나뭇잎을 흩날리며, 그녀의 마음속 깊은 곳에 숨겨진 추억을 깨웠다.',
  },
  {
    type: 'audiobook',
    lang: 'en-us',
    content:
      'She sat by the window, gazing at the scenery outside. The autumn wind swept the leaves, awakening the memories hidden deep in her heart.',
  },
  {
    type: 'audiobook',
    lang: 'ja',
    content:
      '彼女は窓辺に座り、外の風景を眺めていた。秋風が葉を散らかし、彼女の心の奥深くに隠された記憶を呼び覚ます。',
  },

  // 광고 스타일 (한국어, 영어, 일본어)
  {
    type: 'ad',
    lang: 'ko-kr',
    content:
      '새로운 시작을 위한 완벽한 선택 사과 스마트폰! 혁신적인 기능과 뛰어난 성능으로 당신의 일상을 변화시킬 준비가 되어 있습니다.',
  },
  {
    type: 'ad',
    lang: 'en-us',
    content:
      'The perfect choice for a new beginning: Apple Smartphone! Ready to transform your daily life with innovative features and outstanding performance.',
  },
  {
    type: 'ad',
    lang: 'ja',
    content:
      '新しいスタートに最適な選択、アップルスマートフォン！革新的な機能と卓越した性能であなたの日常を変える準備ができています。',
  },

  // 뉴스 스타일 (한국어, 영어, 일본어)
  {
    type: 'news',
    lang: 'ko-kr',
    content:
      '국회에서는 오늘 새로운 법안에 대한 투표가 진행되고 있습니다. 이 법안은 교육 시스템 개선에 중점을 두고 있으며, 많은 이들의 주목을 받고 있습니다.',
  },
  {
    type: 'news',
    lang: 'en-us',
    content:
      'Today, a new bill is being voted on in the National Assembly. The bill focuses on improving the education system and has attracted much attention.',
  },
  {
    type: 'news',
    lang: 'ja',
    content:
      '国会では今日、新しい法案に関する投票が行われています。この法案は教育システムの改善に焦点を当てており、多くの注目を集めています。',
  },

  // 팟캐스트 스타일 (한국어, 영어, 일본어)
  {
    type: 'podcast',
    lang: 'ko-kr',
    content:
      "안녕하세요, '미래 기술 탐구' 팟캐스트에 오신 것을 환영합니다. 오늘은 인공 지능의 최신 발전에 대해 흥미로운 이야기를 나눠보려고 합니다.",
  },
  {
    type: 'podcast',
    lang: 'en-us',
    content:
      "Hello, welcome to the 'Future Tech Exploration' podcast. Today, we will discuss the fascinating developments in artificial intelligence.",
  },
  {
    type: 'podcast',
    lang: 'ja',
    content:
      'こんにちは、「未来技術探求」ポッドキャストへようこそ。今日は人工知能の最新の発展について興味深い話をしようと思います。',
  },
];

export const languageList = [
  { label: 'KOR', value: 'ko-kr', thumb: FlagKR },
  { label: 'ENG', value: 'en-us', thumb: FlagUS },
  { label: 'JPN', value: 'ja', thumb: FlagJP },
];

export const languageParamMap: Record<LanguageParam, Language> = {
  'ko-kr': 'ko',
  'en-us': 'en',
  ja: 'ja',
};
