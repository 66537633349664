import { ReactComponent as LogoCursorPc } from '@/pages/screenplay/assets/images/LogoCursor.svg';
import { ReactComponent as LogoCursorMobile } from '@/pages/screenplay/assets/images/LogoCursorMobile.svg';
import { ReactComponent as LogoCursorTablet } from '@/pages/screenplay/assets/images/LogoCursorTablet.svg';
import { ReactComponent as OpenBeta } from '@/pages/screenplay/assets/images/OpenBeta.svg';
import { ReactComponent as SupertonePlayMobile } from '@/pages/screenplay/assets/images/SupertonePlayMobile.svg';
import { ReactComponent as SupertonePlayPc } from '@/pages/screenplay/assets/images/SupertonePlayPc.svg';
import { ReactComponent as SupertonePlayTablet } from '@/pages/screenplay/assets/images/SupertonePlayTablet.svg';
import {
  MAIN_WEB_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICES_URL,
} from '@/pages/screenplay/stores/data/config';

import { StyledBrandWrapper } from './styled/StyledLanding';

const BrandWrapper = () => (
  <StyledBrandWrapper>
    <section className="guide-for-mobile-users">
      <div className="text">
        FOR THE BEST EXPERIENCE,{'\n'}WE RECOMMEND SWITCHING TO DESKTOP
      </div>
    </section>
    <section className="logo-product-screenplay">
      <div className="logos">
        <SupertonePlayMobile className="logo-screenplay-mobile" />
        <SupertonePlayTablet className="logo-screenplay-tablet" />
        <SupertonePlayPc className="logo-cursor logo-screenplay-pc" />
        <LogoCursorMobile className="logo-cursor-mobile" />
        <LogoCursorTablet className="logo-cursor-tablet" />
        <LogoCursorPc className="logo-cursor-pc" />
      </div>
      <OpenBeta className="logo-open-beta" />
      <span className="text">October 31, 2024 – January 31, 2025</span>
    </section>
    <section className="web-site-back-link-section">
      <a href={MAIN_WEB_URL} rel="noreferrer" target={'_blank'}>
        Visit website
      </a>{' '}
    </section>
    <section className="terms-policy-section">
      <span className="terms-policy">
        By signing in and signing up, you agree to <br />
        Supertone’s{' '}
        <a href={TERMS_OF_SERVICES_URL} rel="noreferrer" target={'_blank'}>
          Terms of Service
        </a>{' '}
        and{' '}
        <a href={PRIVACY_POLICY_URL} rel="noreferrer" target={'_blank'}>
          Privacy Policy.
        </a>
      </span>
    </section>
  </StyledBrandWrapper>
);

export default BrandWrapper;
