import styled from '@emotion/styled';

import { Grey, White } from '../../../styles/Colors';

const StyledVolumeSlider = styled.section`
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  button {
    color: ${Grey[200]};
    &:hover {
      color: ${Grey[50]};
    }
  }
  .sup-slider {
    width: 7.5rem;
    .slider-range {
      background-color: ${Grey[300]};
      &::-webkit-slider-thumb {
        &:focus,
        &:hover {
          background: none;
          box-shadow: none;
        }
      }
    }
    .slider-thumb {
      background: ${White};
      box-shadow: none;
    }
    .slider-track {
      background: ${White};
      box-shadow: none;
    }
    .slider-range[disabled] {
      background: ${Grey[500]};
    }
    .slider-range[disabled] ~ .slider-track {
      background: ${Grey[400]};
    }
    .slider-range[disabled] ~ .slider-thumb {
      background: ${Grey[400]};
    }
  }
`;

export default StyledVolumeSlider;
