import { Black } from '@/styles/Colors';
import styled from '@emotion/styled';

import VoiceDefaultImage from '../assets/images/VoiceDefaultImage.svg';

const StyledVoiceProfile = styled.span`
  .voice-image {
    border: none;
    box-shadow: none;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${Black};
    display: inline-flex;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:focus,
    &:active {
      outline: none;
    }
    &:disabled,
    &.disabled {
      cursor: not-allowed;
    }
    img {
      border-radius: 50%;
    }
    .sup-loading-dots {
      border: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.empty {
      ::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
        background-image: url(${VoiceDefaultImage});
        background-size: cover;
        margin: auto;
      }
    }
    .icon {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  &:hover {
    .icon {
      display: block;
      background-color: rgba(22, 22, 22, 0.36);
      border-radius: 50%;
    }
  }
`;
export default StyledVoiceProfile;
