import Checkbox from '@/components/Checkbox/Checkbox';
import { Grey } from '@/styles/Colors';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as LineAddIcon } from '../assets/icons/LineAddIcon.svg';
import { ReactComponent as LinePlayIcon } from '../assets/icons/LinePlayIcon.svg';
import { ReactComponent as LineStopIcon } from '../assets/icons/LineStopIcon.svg';
import { useGenerateInfo } from '../hooks/useGenerateInfo';
import { useDataContext } from '../providers/DataContextProvider';
import {
  TakeOption,
  useSceneEditorContext,
} from '../providers/SceneEditorContextProvider';
import { activeLineSelector } from '../stores/atoms/project';
import { selectedLineIdsAtom, selectedQuickMenuAtom } from '../stores/atoms/ui';
import { projectVoiceProfileListAtom } from '../stores/atoms/voice';
import { Line } from '../stores/project';
import { getThumbnailPath } from '../VoiceLibrary/utils';
import LineEditor from './LineEditor';
import { StyledLineItem } from './StyledLineItem';
import TakeItem from './TakeItem';
import useSingleTakePlay from './useSingleTakePlay';

const LineItem: React.FC<{ num: number; line: Line }> = ({ line, num }) => {
  const projectVoiceProfileList = useRecoilValue(projectVoiceProfileListAtom);
  const selectedLineIds = useRecoilValue(selectedLineIdsAtom);
  const [text, setText] = useState<string>('');
  const { activeLineId, updateActiveLineId, updateActiveTakeId } =
    useDataContext();
  const [selectedQuickMenu, setSelectedQuickMenu] = useRecoilState(
    selectedQuickMenuAtom
  );

  const isOpen = useMemo(
    () => activeLineId === line.id,
    [activeLineId, line.id]
  );
  // draft 선택 여부를 관리
  const {
    lineList,
    extendTakeLists,
    deleteTake,
    addLine,
    updateDraft,
    toggleSelectedLineIds,
    addTakes,
  } = useSceneEditorContext();
  const takes = useMemo(() => {
    return extendTakeLists.filter((take) => take.lineId === line.id);
  }, [extendTakeLists, line]);
  const reversedTakes = useMemo(() => takes.slice().reverse(), [takes]);
  const activeLine = useRecoilValue(activeLineSelector);
  const activeTake = useMemo(() => {
    return takes.find((take) => take.id === activeLine?.selectedTakeId);
  }, [takes, activeLine]);

  // 테이크 선택시, 선택 take로 라인 정보 교체
  const onSelect = useCallback(
    async (id: string) => {
      updateActiveTakeId(id);
    },
    [updateActiveTakeId]
  );

  const selectedTake = useMemo(() => {
    return takes.find((take) => take.id === line.selectedTakeId);
  }, [takes, line.selectedTakeId]);

  // 라인 선택시
  const onSelectLine = useCallback(() => {
    if (isOpen) return;
    // 이전 라인에 종속된 activeTakeId를 초기화
    updateActiveTakeId(null);
    updateActiveLineId(line.id);
    // selectedTake(defaultTake)가 있을 경우, 해당 take를 기본 take로 선택
    if (selectedTake?.id) {
      onSelect(selectedTake.id);
    } else {
      // selectedTake가 없을 경우, takes가 있을 경우 첫번째 take를 선택
      if (takes[0]) {
        onSelect(takes[0].id);
      } else {
        // takes가 없을 경우, draft가 있을 경우 draft를 선택
        if (line.draft) {
          setText(line.draft?.text || '');
        }
      }
    }
  }, [
    takes,
    line,
    isOpen,
    updateActiveTakeId,
    onSelect,
    updateActiveLineId,
    selectedTake,
  ]);

  const onDeleteTake = useCallback(
    (id: string) => {
      deleteTake(id);
      if (!line.draft) {
        setText('');
      }
    },
    [deleteTake, line.draft]
  );

  const [prevActiveLineId, setPrevActiveLineId] = useState<string | undefined>(
    activeLineId as string
  );

  // selected take id변경시 동작
  useEffect(() => {
    // 현재 라인이 이전에 선택되어있던 라인이고, 새로 선택된 라인이 아닌경우(다른 라인선택의 경우) 라인이 닫힌 것으로 판단하고 draft를 업데이트 한다.
    if (
      (prevActiveLineId === line.id &&
        activeLineId &&
        activeLineId !== line.id &&
        line.selectedTakeId) ||
      (prevActiveLineId !== line.id && activeLineId === line.id)
    ) {
      if (prevActiveLineId !== line.id && activeLineId === line.id) {
        selectedTake?.id && updateActiveTakeId(selectedTake.id);
      }
      // 새로운 activeLine으로 업데이트
      setPrevActiveLineId(activeLineId);
    }
  }, [
    activeLine,
    activeLineId,
    prevActiveLineId,
    takes,
    line.selectedTakeId,
    updateDraft,
    updateActiveTakeId,
    line.id,
    selectedTake,
  ]);

  useEffect(() => {
    setText(selectedTake?.text || '');
  }, [selectedTake]);

  // open 상태에 따른 데이터 노출 셋업
  // line.isOpen 이 false면
  // 1. selectedTake를 먼저 노출
  // 2. selectedTake가 없으면 draft를 노출
  // 3. draft도 없으면 공백 노출, voice는 defaultVoice로 설정
  const profile = useMemo(() => {
    const defaultVoiceProfile = projectVoiceProfileList[0];
    // draft가 있으면 draft의 voice를 선택한다.
    const voiceId = isOpen
      ? line.draft?.voiceId || selectedTake?.voiceId
      : selectedTake?.voiceId || line.draft?.voiceId;
    return (
      projectVoiceProfileList.find((profile) =>
        profile.voices.some(({ id }) => id === voiceId)
      ) || defaultVoiceProfile
    );
  }, [isOpen, selectedTake, line.draft, projectVoiceProfileList]);

  const lineText = useMemo(
    () => (isOpen ? text : selectedTake?.text || line.draft?.text || ''),
    [isOpen, text, selectedTake, line.draft]
  );

  const hasItem = useMemo(() => !!takes.length, [takes]);

  const addNewLine = useCallback(() => {
    addLine(line);
  }, [addLine, line]);
  const onClickNewLine = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      addNewLine();
    },
    [addNewLine]
  );

  const isLastLine = useMemo(() => {
    return lineList[lineList.length - 1]?.id === line.id;
  }, [lineList, line.id]);

  const { language, voiceId, style, controlState } = useGenerateInfo();

  const onUpdate = useCallback(
    async (text: string) => {
      // 이게 두번발생하고 있다.
      addTakes({
        text,
        voiceId,
        language,
        // text update로 인한 generate는 tts고정
        type: 'tts',
        style: style,
        parameter: { ...controlState },
      } as TakeOption);
    },
    [voiceId, language, style, controlState, addTakes]
  );

  const profileIconPath = useMemo(
    () => profile && getThumbnailPath(profile),
    [profile]
  );
  const onClickCheckLineItem = useCallback(() => {
    toggleSelectedLineIds(line.id);
  }, [line.id, toggleSelectedLineIds]);

  const onClickVoice = useCallback(() => {
    if (selectedQuickMenu !== 'Voice') {
      setSelectedQuickMenu('Voice');
    }
  }, [selectedQuickMenu, setSelectedQuickMenu]);
  // voice 설정이 변경된 상태(현재 선택된 take와 draft정보가 다른 경우, age와 gender도 이쪽에서 처리한다.)
  const isLineDraft = useMemo(() => {
    if (
      !isOpen ||
      !line.selectedTakeId ||
      !line.draft ||
      !activeTake ||
      !activeTake.audioBuffer
    )
      return false;
    return (
      line.draft?.voiceId !== activeTake?.voiceId ||
      (line.draft?.text?.trim() !== activeTake?.text?.trim() &&
        activeTake.type !== 'cvc') ||
      line.draft?.language !== activeTake?.language ||
      activeTake.parameter?.pitch_shift !==
        line.draft?.parameter?.pitch_shift ||
      activeTake.parameter?.speed !== line.draft?.parameter?.speed ||
      activeTake.parameter?.pitch_variance !==
        line.draft?.parameter?.pitch_variance
    );
  }, [line.selectedTakeId, line.draft, activeTake, isOpen]);

  const { handlePlayStop, isPlaying } = useSingleTakePlay(line.selectedTakeId);

  return (
    <StyledLineItem
      onClick={onSelectLine}
      className={classNames(!line.selectedTakeId && 'no-takes')}
    >
      <section
        className={classNames(
          'line-editor-wrap',
          isOpen && 'selected',
          !hasItem && 'empty'
        )}
      >
        <section className="line-editor-left">
          <span className="line-checkbox" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              color={Grey[400]}
              onChange={onClickCheckLineItem}
              checked={selectedLineIds.some((id) => id === line.id)}
            />
          </span>
          <section className="line-num">{num}</section>
          <section
            className={classNames('line-voice', isLineDraft && 'voice-changed')}
            onClick={onClickVoice}
          >
            {profile?.thumbnails?.length && (
              <span className="thumbnail">
                <img src={profileIconPath} alt={profile?.name} />
              </span>
            )}
          </section>
          {(isOpen && activeTake?.type !== 'cvc') || !line.draft?.source_id ? (
            <LineEditor
              line={line}
              setText={setText}
              text={lineText}
              onUpdate={onUpdate}
              isOpen={isOpen}
              isLastLine={isLastLine}
            />
          ) : (
            <section
              className="line-editor cvc"
              onClick={() => {
                onSelectLine();
                updateDraft(line.id, {
                  text: '',
                  source_id: undefined,
                });
              }}
            >
              This take generated by audio file
            </section>
          )}
        </section>
        <section className="line-button">
          {!isOpen && hasItem && (
            <span className="line-play">
              <button
                color="transparent"
                className="btn-play"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlayStop();
                }}
              >
                {isPlaying ? <LineStopIcon /> : <LinePlayIcon />}
              </button>
            </span>
          )}
        </section>
      </section>
      {isOpen && hasItem && (
        <section className="line-takes">
          {reversedTakes.map((take, index) => (
            <TakeItem
              key={take.id}
              isSelected={take.id === line.selectedTakeId}
              onSelect={onSelect}
              isActive={take.id === activeLine?.selectedTakeId}
              take={take}
              num={`${num}-${reversedTakes.length - index}`}
              deleteTake={onDeleteTake}
            />
          ))}
        </section>
      )}
      <section className="line-add">
        <button className="add" onClick={onClickNewLine}>
          <LineAddIcon />
        </button>
      </section>
    </StyledLineItem>
  );
};
export default LineItem;
