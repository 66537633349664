import styled from '@emotion/styled';

export const StyledWaveform = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  canvas {
    width: 100%;
    height: 100%;
  }
  .wave-progress {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
