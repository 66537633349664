import IconButton from '@/components/Button/IconButton';
import { USER_INTERVIEW_URL } from '@/pages/screenplay/stores/data/config';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as DeleteIcon } from '../assets/icons/DeleteIcon.svg';
import { isShowFeedbackAtom } from '../stores/atoms/ui';

const Feedback = () => {
  const setIsShowFeedback = useSetRecoilState(isShowFeedbackAtom);
  return (
    <section className="project-feedback">
      <section className="feedback-text">
        | Supertone Play 💬 사용자 인터뷰에 참여하실 분을 찾습니다! 사용기를
        들려주시면 상품권을 드려요. 🎁
        <a target="_blank" href={USER_INTERVIEW_URL} rel="noreferrer">
          신청하러 가기 {'>'}
        </a>
      </section>
      <IconButton className="btn-exit" onClick={() => setIsShowFeedback(false)}>
        <DeleteIcon />
      </IconButton>
    </section>
  );
};
export default Feedback;
