import { atom, selector } from 'recoil';

import { Line, Project, Scene, Take } from '../project';

export const takeJobIdMapAtom = atom<Record<string, string>>({
  key: 'takeJobIdMap',
  default: {},
});

export const lineListAtom = atom<Line[]>({
  key: 'lineList',
  default: [],
});

export const takeListAtom = atom<Take[]>({
  key: 'takeList',
  default: [],
});

export const sceneListAtom = atom<Scene[]>({
  key: 'sceneList',
  default: [],
});

export const projectListAtom = atom<Project[]>({
  key: 'projectList',
  default: [],
});

export const selectedProjectIdAtom = atom<string | null>({
  key: 'selectedProjectId',
  default: null,
});

export const selectedProjectSelector = selector<Project | null>({
  key: 'selectedProjectSelector',
  get: ({ get }) => {
    const selectedProjectId = get(selectedProjectIdAtom);
    const projectList = get(projectListAtom);
    return (
      projectList.find((project) => project.id === selectedProjectId) || null
    );
  },
});

export const activeSceneIdAtom = atom<string | null>({
  key: 'activeSceneId',
  default: null,
});

export const activeSceneSelector = selector<Scene | null>({
  key: 'activeSceneSelector',
  get: ({ get }) => {
    const activeSceneId = get(activeSceneIdAtom);
    const sceneList = get(sceneListAtom);
    return sceneList.find((scene) => scene.id === activeSceneId) || null;
  },
});

export const activeLineIdAtom = atom<string | null>({
  key: 'activeLineId',
  default: null,
});

export const activeLineSelector = selector<Line | null>({
  key: 'activeLineSelector',
  get: ({ get }) => {
    const activeLineId = get(activeLineIdAtom);
    const lineList = get(lineListAtom);
    return lineList.find((line) => line.id === activeLineId) || null;
  },
});

export const activeTakeSelector = selector<Take | null>({
  key: 'activeTakeSelector',
  get: ({ get }) => {
    const activeLine = get(activeLineSelector);
    const takeList = get(takeListAtom);
    return (
      takeList.find((take) => take.id === activeLine?.selectedTakeId) || null
    );
  },
});
