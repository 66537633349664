import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';

interface DropdownAnchorProps extends HTMLAttributes<HTMLDivElement> {}

const DropdownAnchor = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DropdownAnchorProps>
>(({ children, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
});

export default DropdownAnchor;
