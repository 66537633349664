import Button from '@/components/Button/Button';
import { Primary } from '@/styles/Colors';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import useAxios from '../../screenplay/hooks/useAxios';
import { ReactComponent as PlusIcon } from './assets/icons/PlusIcon.svg';
import { useSourceResultContext } from './SourceResultProvider';
import { defaultTTSParams } from './TTSPanel/models';
import Select from './TTSPanel/Select';
import SpeechControl from './TTSPanel/SpeechControl';
import StyledTTSPanel from './TTSPanel/StyledTTSPanel';
import TextField from './TTSPanel/TextField';
import { useZeroShotContext } from './ZeroShotContextProvider';

const DEFAULT_NUM = 3;
const ZeroShotTTSPanel = () => {
  const { selectedSource, textMap, language, parameterMap } =
    useZeroShotContext();
  const { addResult } = useSourceResultContext();
  const [num, setNum] = useState(DEFAULT_NUM);
  const [isProcessing, setIsProcessing] = useState(false);
  const { createTtsZeroShot } = useAxios();

  const generate = useCallback(async () => {
    if (!selectedSource) {
      return;
    }
    setIsProcessing(true);
    const audioBuffers = await createTtsZeroShot(
      selectedSource?.file,
      textMap[selectedSource.id],
      language,
      { ...defaultTTSParams, ...parameterMap[selectedSource.id] },
      num
    );
    addResult(
      selectedSource.id,
      audioBuffers.map((buffer) => {
        return {
          name: selectedSource.file.name,
          sourceId: selectedSource.id,
          text: textMap[selectedSource.id],
          id: uuid(),
          length: buffer.duration * 1000,
          isExpanded: true,
          lang: language,
          audioBuffer: buffer,
          parameters: {
            ...defaultTTSParams,
            ...parameterMap[selectedSource.id],
          },
        };
      })
    );
    setIsProcessing(false);
  }, [
    num,
    selectedSource,
    textMap,
    language,
    parameterMap,
    addResult,
    createTtsZeroShot,
  ]);

  return (
    <StyledTTSPanel className="admin-grid-item zero-shot-tts">
      <TextField />
      <SpeechControl />
      <section className="section-create">
        <Button
          startIcon={<PlusIcon />}
          disabled={!selectedSource || isProcessing}
          onClick={generate}
        >
          {isProcessing ? 'Generating...' : 'Create'}
        </Button>
        <Select
          buttonProps={{
            variant: 'contained',
            color: Primary[400],
            disabled: !selectedSource || isProcessing,
          }}
          items={[1, 2, 3, 4].map((num) => ({
            label: num,
            value: num,
          }))}
          selectedItem={{ label: num, value: num }}
          onChange={(item) => {
            setNum(item.value as number);
          }}
        />
      </section>
    </StyledTTSPanel>
  );
};
export default ZeroShotTTSPanel;
