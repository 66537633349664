import { RefObject } from 'react';

import useDocumentEvent from './useElementEvent';

/**
 * 특정 엘리먼트 외부가 클릭되었을 때 실행되는 함수를 등록하는 훅
 *
 * @param ref 기준이 되는 엘리먼트의 ref 혹은 ref 목록
 * @param handler 엘리먼트 외부가 클릭되었을 때 실행되는 함수
 */
const useOnClickOutside = (
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: (event: Event) => void
) => {
  useDocumentEvent('pointerdown', (event) => {
    const refs = Array.isArray(ref) ? ref : [ref];
    // 클릭한 엘리먼트가 ref에 포함되어 있으면 handler를 실행하지 않음
    if (refs.some((r) => r?.current?.contains(event.target as Node))) return;
    handler(event);
  });
};

export default useOnClickOutside;
