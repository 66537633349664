import { useCallback, useEffect, useRef } from 'react';

import useElementEvent from '../../hooks/useElementEvent';
import { getScrollableParents } from '../../util/dom';
import { UseLayerPositionOptions } from './types';
import { getLayerPosition } from './utils';

export const useLayerPosition = <
  AnchorElement extends HTMLElement,
  LayerElement extends HTMLElement
>({
  placement = 'bottom-left',
  offsetY,
  isLayerVisible,
}: UseLayerPositionOptions) => {
  const anchorRef = useRef<AnchorElement>(null);
  const layerRef = useRef<LayerElement>(null);
  const scrollableParents = getScrollableParents(anchorRef.current);

  const updateLayerPosition = useCallback(() => {
    if (anchorRef.current && layerRef.current && isLayerVisible) {
      const layerPosition = getLayerPosition({
        anchorRef,
        layerRef,
        placement,
        offsetY,
      });

      if (!layerPosition) return;

      const { top, left } = layerPosition;
      layerRef.current.style.top = `${top}px`;
      layerRef.current.style.left = `${left}px`;
    }
  }, [anchorRef, layerRef, offsetY, placement, isLayerVisible]);

  useEffect(() => {
    updateLayerPosition();
  }, [updateLayerPosition]);

  useElementEvent(['resize', 'scroll'], updateLayerPosition);
  useElementEvent(['resize', 'scroll'], updateLayerPosition, scrollableParents);

  return {
    anchorRef,
    layerRef,
  };
};
