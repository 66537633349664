import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';

interface DropdownListProps extends HTMLAttributes<HTMLUListElement> {}

const DropdownList = forwardRef<
  HTMLUListElement,
  PropsWithChildren<DropdownListProps>
>(({ children, ...props }, ref) => {
  return (
    <ul className="sup-dropdown-list" ref={ref} {...props}>
      {children}
    </ul>
  );
});

export default DropdownList;
