import { FilterOption, Language } from './types';

export const DEFAULT_PARAMETER = {
  age: {
    min: 7,
    max: 70,
    step: 1,
  },
  gender: {
    min: -15,
    max: 15,
    step: 1,
  },
};

export const FILTER_OPTIONS: FilterOption[] = [
  { title: 'Native Language', key: 'language' },
  { title: 'Gender', key: 'gender' },
  { title: 'Age', key: 'age' },
  { title: 'Use Case', key: 'usecase' },
];

export const LanguageMap: Record<Language, string> = {
  en: 'English',
  ko: 'Korean',
  ja: 'Japanese',
};

export const AgeMap: Record<string, string> = {
  child: 'Child',
  'young-adult': 'Young Adult',
  'middle-aged': 'Middle-Aged',
  elder: 'Elder',
};

// tmp
export const languagePriority = {
  ko: 0,
  en: 1,
  ja: 2,
};
