import classNames from 'classnames';
import React from 'react';

import StyledLoading from './StyledLoading';

interface LoadingProps {
  className?: string;
}
const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <StyledLoading className={classNames(className)}>
      <span className="blue"></span>
      <span className="grey"></span>
    </StyledLoading>
  );
};
export default Loading;
