import { Black, Grey } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledTTSPanel = styled.section`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 3.375rem);
  /* text field */
  .section-tts {
    flex-grow: 1;
    max-height: 18rem;
    .select-wrapper {
      display: flex;
      gap: 0.6rem;
      justify-content: flex-end;
      .sup-button {
        padding: 0.7rem 0;
        text-decoration: underline;
        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
    .text-field {
      box-sizing: border-box;
      padding: 1rem;
      width: 100%;
      height: calc(100% - 4.4rem);
      min-width: 30rem;
      min-height: 11.4rem;
      resize: none;
      border: 1px solid ${Grey[200]};
      border-radius: 0.25rem;
      background-color: ${Black};
      color: ${Grey[50]};
      font-size: ${FontSize['Lg']};
      line-height: 1.5;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${Grey[500]};
      }
      &:disabled {
        opacity: 0.8;
        color: ${Grey[500]};
        cursor: not-allowed;
      }
    }
  }
  /* speech control */
  .section-control {
    margin-top: 2rem;
    & > .sup-title {
      margin-bottom: 1.5rem;
    }
    .model-parameter {
      margin-bottom: 1rem;
      .sup-stepper {
        .sup-stepper-input:disabled {
          color: ${Grey[50]};
        }
      }
      .sup-slider {
        .slider-range:disabled {
          background-color: ${Grey[500]};
          & ~ .slider-track {
            box-shadow: none;
            background-color: ${Grey[600]};
          }
          & ~ .slider-thumb {
            box-shadow: none;
            background-color: ${Grey[600]};
          }
        }
      }
    }
  }
  .sup-button-group {
    margin-top: 0.75rem;
    margin-bottom: 1.25rem;
    gap: 2px;
    align-self: flex-end;
    .sup-button {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      font-size: ${FontSize['Xl']};
      font-weight: ${FontWeight['SemiBold']};
    }
  }
  /* create buttons */
  .section-create {
    display: inline-flex;
    gap: 0.12rem;
    justify-content: flex-end;
    margin-bottom: 2rem;
    .sup-button {
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      font-size: ${FontSize['Xl']};
    }
    & > .sup-button {
      min-width: 10.4rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .sup-button-icon {
        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
    & > div .sup-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .sup-button-icon {
        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
  }
`;

export default StyledTTSPanel;
