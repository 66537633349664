import styled from '@emotion/styled';

import { Grey, Primary, White } from '../../styles/Colors';
import { FontSize, FontWeight } from '../../styles/Typography';

export const StyledSlider = styled.div`
  margin: 0;
  margin-top: 1.25rem;
  padding: 0;
  padding-bottom: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .slider {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  .slider-range {
    margin: 0.35rem 0;
    padding: 0;
    width: 100%;
    height: 1px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: ${Grey['200']};
    z-index: 1;
    &[disabled] {
      cursor: not-allowed;
    }
    &::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin-top: 0;
      width: 1.25rem;
      height: 1.25rem;
      position: relative;
      border: none;
      border-radius: 50%;
      background: transparent;
      &:focus,
      &:hover {
        background: rgba(57, 184, 255, 0.16);
        filter: blur(3px);
        box-shadow: 0px 0px 0px 3.5px rgba(57, 184, 255, 0.16);
      }
    }
  }
  .slider-thumb {
    top: 0.125rem;
    left: -0.22rem;
    box-sizing: border-box;
    position: absolute;
    width: 1.56rem;
    height: 0.56rem;
    background: ${Primary['400']};
    pointer-events: none;
    z-index: 1;
    box-shadow: 0px 0px 8px 0px rgba(0, 61, 255, 0.5),
      0px 0px 12px 0px rgba(0, 61, 255, 0.3);
  }
  .slider-track {
    position: absolute;
    width: 100%;
    height: 1px;
    background: ${Primary['400']};
    box-shadow: 0px 0px 3px #688fe4;
    mix-blend-mode: normal;
    pointer-events: none;
    z-index: 1;
  }
  .slider-range[disabled] ~ .slider-track {
    background: ${Primary['700']};
  }
  .slider-range[disabled] ~ .slider-thumb {
    background: ${Primary['700']};
  }
  .slider-marks {
    position: absolute;
    top: -0.94rem;
    width: 100%;
    .slider-mark {
      box-sizing: border-box;
      position: absolute;
      width: 1px;
      height: 0.375rem;
      background: ${Grey['450']};
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        top: -0.19rem;
        left: -0.19rem;
        width: 0.375rem;
        height: calc(100% + 0.375rem);
      }
      &:nth-of-type(odd) {
        background: ${White};
      }
      &:has(.slider-mark-label) {
        background: none;
        &:last-of-type {
          .slider-mark-label {
            margin-left: -0.94rem;
          }
        }
      }
      &-label {
        position: absolute;
        top: -0.375rem;
        font-size: ${FontSize['Sm']};
        color: ${Grey['400']};
        line-height: 150%;
        cursor: pointer;
      }
    }
  }
  .slider-label {
    position: absolute;
    margin: 0;
    bottom: -0.375rem;
    color: ${Grey['400']};
    font-size: ${FontSize['Md']};
    text-align: center;
    line-height: 150%;
    &:first-of-type {
      left: 0;
    }
    &:last-of-type {
      right: 0;
    }
  }
  &.simple {
    margin-top: 0;
    padding-bottom: 0;
    .slider {
      width: calc(100% - 4rem);
      &.hide-label {
        width: 100%;
      }
    }
    .slider-label {
      position: static;
      width: 2rem;
      text-align: center;
      color: ${Grey['50']};
      font-size: ${FontSize['Sm']};
      font-weight: ${FontWeight['SemiBold']};
      word-break: break-all;
    }
  }
`;
