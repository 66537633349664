import ConfirmModal from '@/components/Modal/ConfirmModal/ConfirmModal';
import UserInfo from '@/user/UserInfo';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../screenplay/assets/images/Logo.svg';
import { StyledZeroShotScreenPlayHeader } from './StyledZeroShotScreenPlayHeader';

const ZeroShotHeader = () => {
  const navigate = useNavigate();

  // 스크린 플레이로 이동시, 컨펌 모듈 오픈을 위한 state
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  return (
    <StyledZeroShotScreenPlayHeader>
      <section className="screenplay">
        <h1 className="logo" onClick={() => setIsOpenConfirm(true)}>
          <Logo />
        </h1>
      </section>
      <UserInfo />
      {/* {스크린 플레이로 이동 할 경우, 데이터 휘발 됨을 알리는 경고창} */}
      <ConfirmModal
        isPortal={false}
        isOpen={isOpenConfirm}
        title="ScreenPlay로 이동"
        className="confirm-modal"
        confirmText="OK"
        cancelText="Cancel"
        message="작업중이던 내용이 사라지게 됩니다. 계속하시겠습니까?"
        onConfirm={() => {
          navigate('/projects');
          setIsOpenConfirm(false);
        }}
        onCancel={() => {
          setIsOpenConfirm(false);
        }}
      />
    </StyledZeroShotScreenPlayHeader>
  );
};
export default ZeroShotHeader;
