import IconButton from '@/components/Button/IconButton';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ReactComponent as EditNameIcon } from '../assets/icons/EditNameIcon.svg';
import { useDataContext } from '../providers/DataContextProvider';
import { isShowFeedbackAtom } from '../stores/atoms/ui';
import Feedback from './Feedback';

const Header = () => {
  const { projectInfo, updateProjectInfo } = useDataContext();
  const isShowFeedback = useRecoilValue(isShowFeedbackAtom);
  const [isEditProjectName, setIsEditProjectName] = useState(false);

  const textRef = useRef<HTMLTextAreaElement>(null);
  const updateTitle = useCallback(async () => {
    if (textRef.current?.innerText.trim()) {
      await updateProjectInfo({
        ...projectInfo,
        name: textRef.current?.innerText.trim(),
      });
      setIsEditProjectName(false);
    }
  }, [textRef, updateProjectInfo, setIsEditProjectName, projectInfo]);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      e.stopPropagation();
      if (e.code === 'Enter') {
        e.preventDefault();
        updateTitle();
      }
      if (e.code === 'Escape') {
        setIsEditProjectName(false);
      }
    },
    [setIsEditProjectName, updateTitle]
  );
  const onBlur = useCallback(() => {
    updateTitle();
  }, [updateTitle]);
  useEffect(() => {
    if (isEditProjectName) {
      if (textRef?.current) {
        textRef.current.focus();
        let range = document.createRange();
        let sel = window.getSelection();
        range.selectNodeContents(textRef.current as Node);
        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    }
  }, [isEditProjectName, textRef]);

  return (
    <header className="layout-header">
      {isShowFeedback && <Feedback />}
      <h1 className="project-name">
        {isEditProjectName ? (
          <section className="title-text-edit">
            <section
              className="text-editor"
              ref={textRef}
              contentEditable={isEditProjectName}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              dangerouslySetInnerHTML={{ __html: projectInfo.name }}
            />
          </section>
        ) : (
          projectInfo.name && (
            <section className="project-name-label">
              <span>{projectInfo.name}</span>
              <IconButton
                onClick={() => setIsEditProjectName(true)}
                color="transparent"
                className="btn-edit-project-name"
              >
                <EditNameIcon />
              </IconButton>
            </section>
          )
        )}
      </h1>
    </header>
  );
};
export default Header;
