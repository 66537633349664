import { css } from '@emotion/react';

import { Grey } from './Colors';

export const ellipsis = (lines: number = 1) =>
  lines === 1
    ? css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    : css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
      `;

export const customScrollbar = css`
  &::-webkit-scrollbar {
    width: 0.35rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Grey[500]};
    border: 1px solid ${Grey[400]};
    border-radius: 0.175rem;
    width: 0.35rem;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: ${Grey[400]};
    }
  }
`;
