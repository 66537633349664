import { RefObject } from 'react';

import {
  HorizontalAlignment,
  LayerPlacement,
  VerticalPlacement,
} from './types';

export const getLayerPosition = ({
  anchorRef,
  layerRef,
  placement,
  offsetY = 0,
}: {
  anchorRef: RefObject<HTMLElement>;
  layerRef: RefObject<HTMLElement>;
  placement: LayerPlacement;
  offsetY?: number;
}) => {
  const layerElementClientRect = layerRef.current?.getBoundingClientRect();
  const anchorElementClientRect = anchorRef.current?.getBoundingClientRect();

  if (!layerElementClientRect || !anchorElementClientRect) {
    return null;
  }

  const { height: dropdownHeight, width: dropdownWidth } =
    layerElementClientRect;
  const { height: anchorElementHeight, width: anchorElementWidth } =
    anchorElementClientRect;
  const anchorElementTop = anchorElementClientRect.top + window.scrollY;
  const anchorElementLeft = anchorElementClientRect.left + window.scrollX;

  const [vertical, horizontal] = placement.split('-') as [
    VerticalPlacement,
    HorizontalAlignment
  ];

  let top = 0;
  let left = 0;

  const hasNoSpaceOnTop =
    anchorElementClientRect.top - dropdownHeight - offsetY < 0;
  const hasNoSpaceOnBottom =
    anchorElementTop + dropdownHeight + offsetY >
    window.innerHeight + window.scrollY;

  // NOTE: vertical이 top이어도, 상단에 공간이 없으면 bottom으로 강제
  switch (vertical) {
    case 'top':
      if (hasNoSpaceOnTop) {
        top = anchorElementTop + anchorElementHeight + offsetY;
      } else {
        top = anchorElementTop - dropdownHeight - offsetY;
      }
      break;
    case 'bottom':
      if (hasNoSpaceOnBottom && !hasNoSpaceOnTop) {
        top = anchorElementTop - dropdownHeight - offsetY;
      } else {
        top = anchorElementTop + anchorElementHeight + offsetY;
      }
      break;
  }

  const hasNoSpaceOnLeft =
    anchorElementLeft - (layerElementClientRect.width - anchorElementWidth) <
    window.scrollX;
  const hasNoSpaceOnRight =
    anchorElementLeft + dropdownWidth > window.innerWidth + window.scrollX;
  const isAnchorOutOfScreen =
    anchorElementLeft < window.scrollX ||
    window.innerWidth + window.scrollX < anchorElementLeft + anchorElementWidth;

  switch (horizontal) {
    case 'left':
      // 기본
      if (hasNoSpaceOnLeft || !hasNoSpaceOnRight) {
        left = anchorElementLeft;
        break;
      }

      // 기준 엘리먼트가 화면 우측 밖에 있을 때
      if (isAnchorOutOfScreen) {
        left = anchorElementLeft - (dropdownWidth - anchorElementWidth);
        break;
      }

      // 우측에 드롭다운을 표시할 공간이 충분하지 않을 때
      left = window.innerWidth + window.scrollX - dropdownWidth;
      break;

    case 'right':
      // 기본
      if (!hasNoSpaceOnLeft) {
        left = anchorElementLeft - (dropdownWidth - anchorElementWidth);
        break;
      }

      // 기준 엘리먼트가 화면 좌측 밖에 있을 때
      if (isAnchorOutOfScreen) {
        left = anchorElementLeft;
        break;
      }

      // 좌측에 드롭다운을 표시할 공간이 충분하지 않을 때
      left = window.scrollX;
      break;

    case 'center':
      const hasNoSpaceOnLeftForCenter =
        anchorElementLeft - (dropdownWidth / 2 - anchorElementWidth / 2) <
        window.scrollX;
      const hasNoSpaceOnRightForCenter =
        anchorElementLeft + (dropdownWidth / 2 + anchorElementWidth / 2) >
        window.innerWidth + window.scrollX;

      // 좌측에 드롭다운을 표시할 공간이 충분하지 않을 때
      if (hasNoSpaceOnLeftForCenter) {
        if (isAnchorOutOfScreen) {
          left = anchorElementLeft;
          break;
        }
        left = window.scrollX;
        break;
      }

      // 우측에 드롭다운을 표시할 공간이 충분하지 않을 때
      if (hasNoSpaceOnRightForCenter) {
        if (!isAnchorOutOfScreen) {
          left = window.innerWidth + window.scrollX - dropdownWidth;
          break;
        }
        left = anchorElementLeft - (dropdownWidth - anchorElementWidth);
        break;
      }

      left = anchorElementLeft - (dropdownWidth / 2 - anchorElementWidth / 2);
      break;
  }

  return { top, left };
};
