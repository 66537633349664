import { Primary } from '@/styles/Colors';
import styled from '@emotion/styled';

const StyledZeroShotResultPanel = styled.section`
  .zero-shot-result-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0.625rem;
    align-items: center;
    .attach-button-primary {
      background-color: ${Primary[400]};
      border: 1px solid ${Primary[400]};
    }
    .zero-shot-control {
      display: inline-flex;
      gap: 1rem;
    }
  }
`;
export default StyledZeroShotResultPanel;
