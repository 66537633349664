import { PropsWithChildren } from 'react';
import { ButtonHTMLAttributes } from 'react';

import { StyledIconButton } from './StyledIconButton';

export type ButtonVariant = 'contained' | 'outlined' | 'none';

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconColor?: string;
  className?: string;
  variant?: ButtonVariant;
  radius?: string;
  iconSize?: string;
  icon: string;
  width?: string;
  height?: string;
}

const IconButton = ({
  variant,
  radius,
  className = '',
  icon,
  iconColor,
  iconSize,
  ...rest
}: PropsWithChildren<IconButtonProps>) => {
  return (
    <StyledIconButton
      className={`sup-icon-button ${className}`}
      variant={variant}
      radius={radius}
      iconColor={iconColor}
      icon={icon}
      iconSize={iconSize}
      {...rest}
    />
  );
};

export default IconButton;
