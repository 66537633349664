import { resampleAudioData } from '@/components/Waveform/utils';
import { useCallback, useEffect, useRef } from 'react';

interface WaveformProps {
  audioBuffer: AudioBuffer;
  progressColor?: string;
  waveColor?: string;
  width: number;
  height: number;
  offsetX?: number;
}

const RECT_WIDTH = 3;
const RECT_MARGIN = 1;
const RECT_RADIUS = 2;

const Waveform = ({
  audioBuffer,
  waveColor = 'rgba(244, 244, 244, 0.2)',
  width,
  height,
  offsetX = 0,
}: WaveformProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const drawWave = useCallback(
    (canvas: HTMLCanvasElement, progress?: number) => {
      const ctx = canvas?.getContext('2d');
      if (!ctx) return;
      // let { width, height } = size;
      const ratio = window.devicePixelRatio || 1;
      const newWidth = width * ratio;
      const newHeight = height * ratio;

      const rectWidth = RECT_WIDTH * ratio;
      const rectMargin = RECT_MARGIN * ratio;
      const rectRadius = RECT_RADIUS * ratio;

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.fillStyle = waveColor;
      ctx.clearRect(0, 0, newWidth, newHeight);

      // TODO: 현재는 Mono만 지원, 추후 Stereo 등 다른 채널 지원
      const data = audioBuffer.getChannelData(0);
      const resampledData = resampleAudioData(
        data,
        newWidth,
        rectWidth,
        rectMargin
      );

      const length =
        typeof progress !== 'number'
          ? resampledData.length
          : (progress * ratio) / (rectWidth + rectMargin);

      for (let i = 0; i < length; i++) {
        const x = i * (rectWidth + rectMargin);
        const y = Math.abs(resampledData[i] * newHeight) / 2;

        ctx.roundRect(x, newHeight / 2 - y, rectWidth, y, [
          rectRadius,
          rectRadius,
          0,
          0,
        ]);
        ctx.roundRect(x, newHeight / 2, rectWidth, y, [
          0,
          0,
          rectRadius,
          rectRadius,
        ]);
      }
      ctx.fill();
      ctx.scale(ratio, ratio);
    },
    [audioBuffer, waveColor, width, height]
  );

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;
    wrapper.style.width = `${width}px`;
    wrapper.style.height = `${height}px`;
    const canvas = canvasRef.current;
    if (!canvas) return;
    drawWave(canvas);
  }, [width, height, drawWave]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.style.left = `${-offsetX}px`;
  }, [offsetX]);

  return (
    <div className="waveform" ref={wrapperRef}>
      <canvas className="wave" ref={canvasRef} />
    </div>
  );
};

export default Waveform;
