import CheckIcon from '@/components/assets/icons/CheckIcon.svg';
import MinusIcon from '@/components/assets/icons/MinusIcon.svg';
import { Black, Grey, White } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

interface StyledCheckboxProps {
  defaultColorSet?: {
    bg: string;
    border: string;
    icon: string;
  };
  selectColorSet?: {
    bg: string;
    border: string;
    icon: string;
  };
}
const StyledCheckbox = styled.span<StyledCheckboxProps>`
  display: inline-flex;
  height: 1.25rem;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  .screen-out {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
  }
  > input {
    visibility: hidden;
    position: absolute;
  }
  > input ~ label {
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 2px;
    border: 2px solid ${(props) => props.defaultColorSet?.border || White};
  }
  > input:checked ~ label {
    background-color: ${(props) => props.selectColorSet?.bg || White};
    border: 2px solid ${(props) => props.selectColorSet?.border || White};
  }
  > input:checked ~ label:after {
    content: ' ';
    mask-image: url(${CheckIcon});
    mask-repeat: no-repeat;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.selectColorSet?.icon || Black};
  }
  > input:disabled {
    border-color: ${Grey[200]};
  }
  > input:disabled ~ label {
    background: url(${MinusIcon}) no-repeat;
    background-color: ${Grey[200]};
    border: 2px solid ${Grey[200]};
    cursor: default;
    color: ${Grey[200]};
  }
  .checkbox-caption {
    font-size: ${FontSize['Md']};
    font-weight: ${FontWeight['SemiBold']};
    line-height: 150%;
  }
`;
export default StyledCheckbox;
