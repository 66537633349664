import styled from '@emotion/styled';

import { Grey, White } from '../../styles/Colors';
import { FontSize, FontWeight } from '../../styles/Typography';

export const StyledStepper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 1.125rem;
  .sup-stepper-button {
    padding: 0.25rem 0;
    width: 0.94rem;
    height: 0.94rem;
    border: none;
    background: none;
    color: ${Grey['500']};
    font-size: ${FontSize['Sm']};
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &[disabled] {
      cursor: not-allowed;
      &:hover {
        color: ${Grey['500']};
      }
    }
    &:first-of-type {
      margin-right: 0.25rem;
    }
    &:last-of-type {
      margin-left: 0.25rem;
    }
    & svg {
      width: 0.56rem;
      height: 0.56rem;
    }
    // Set the color of the svg to the same as the text color
    & svg * {
      fill: currentColor;
    }
    &:hover {
      color: ${White};
    }
  }
  .sup-stepper-input {
    max-width: 3.5rem;
    height: 1.125rem;
    background: none;
    box-sizing: border-box;
    border: 1px solid ${Grey['500']};
    border-radius: 0.06rem;
    color: ${White};
    font-size: ${FontSize['Md']};
    font-weight: ${FontWeight['SemiBold']};
    text-align: center;
    outline: none;
    &:focus {
      background: ${Grey['500']};
      border: 1px solid ${White};
      box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.4);
    }
    &[disabled] {
      color: ${Grey['500']};
      cursor: not-allowed;
    }
  }
`;
