import { FontSize } from '../../styles/Typography';
import { ButtonRadius, ButtonSize } from './types';

export const FONT_SIZE: {
  [key in ButtonSize]: (typeof FontSize)[keyof typeof FontSize];
} = {
  sm: FontSize.Sm,
  md: FontSize.Md,
  lg: FontSize.Lg,
  xl: FontSize.Xl,
};

export const BORDER_RADIUS: {
  [key in ButtonRadius]: string;
} = {
  none: 'unset',
  sm: '0.2rem',
  md: '0.25rem',
  lg: '0.3rem',
};
