import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { timelinePanelHeightAtom } from '../stores/atoms/ui';
import { StyledTimelinePanel } from '../styles/StyledTimelinePanel';
import { LEFT_OFFSET } from './Timeline/const';
import Timeline, { Size } from './Timeline/Timeline';

const MIN_TIMELINE_PANEL_HEIGHT = 232;
const MAX_TIMELINE_PANEL_HEIGHT = 430;

const TimelinePanel = () => {
  const panelRef = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });
  const [height, setHeight] = useRecoilState(timelinePanelHeightAtom);

  const handleMouseDown = (e: React.PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (panelRef.current) {
      const startY = e.pageY;
      const startHeight = panelRef.current.getBoundingClientRect().height;
      document.body.style.cursor = 'row-resize';

      const handlePointerMove = (e: PointerEvent) => {
        e.stopPropagation();
        dragHandleRef.current?.classList.add('dragging');

        const currentY = e.pageY;
        const newHeight = startHeight + (startY - currentY);
        if (
          newHeight < MIN_TIMELINE_PANEL_HEIGHT ||
          newHeight > MAX_TIMELINE_PANEL_HEIGHT
        )
          return;
        setHeight(newHeight);
      };

      const handlePointerUp = () => {
        document.body.style.cursor = 'default';
        dragHandleRef.current?.classList.remove('dragging');

        window.removeEventListener('pointermove', handlePointerMove);
        window.removeEventListener('pointerup', handlePointerUp);
        window.removeEventListener('pointerleave', handlePointerUp);
      };
      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
      window.addEventListener('pointerleave', handlePointerUp);
    }
  };

  // Set timeline svg element size as panel size
  useEffect(() => {
    const panelEl = panelRef.current;
    if (!panelEl) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setSize({ width: width - LEFT_OFFSET, height });
    });
    resizeObserver.observe(panelEl);
    return () => resizeObserver.unobserve(panelEl);
  }, [height]);

  return (
    <StyledTimelinePanel
      style={{
        height: `${height}px`,
      }}
      ref={panelRef}
    >
      <div
        ref={dragHandleRef}
        className="drag-handle"
        onPointerDown={handleMouseDown}
      />
      <Timeline size={size} />
    </StyledTimelinePanel>
  );
};

export default TimelinePanel;
