import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { Dropdown } from '@/components/Dropdown';
import Modal from '@/components/Modal/Modal';
import ModalBody from '@/components/Modal/ModalBody';
import ModalFooter from '@/components/Modal/ModalFooter';
import ModalHeader from '@/components/Modal/ModalHeader';
import { ReactComponent as MoreIcon } from '@/pages/screenplay/assets/icons/MoreIcon.svg';
import { ReactComponent as DeleteIcon } from '@/pages/screenplay/assets/icons/project/DeleteIcon.svg';
import { ReactComponent as EditIcon } from '@/pages/screenplay/assets/icons/project/EditIcon.svg';
import { Secondary } from '@/styles/Colors';
import { formatDateDay, formatDateTime } from '@/util/formatter';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import {
  _deleteMusic,
  _deleteMusicFile,
  _deleteProfileEditInfo,
  _deleteScene,
  _deleteTake,
  _loadMusicFiles,
  _loadMusics,
  _loadProfileEditInfoList,
  _loadScenes,
  _loadTakes,
} from '../api/project';
import { useLog } from '../hooks/useLog/useLog';
import { isLoadingProjectAtom } from '../stores/atoms/ui';
import { Music, MusicFile, Project } from '../stores/project';
import { ProfileEditInfo } from '../stores/voice';
import { StyledProjectImportDropDown } from './StyledProjects';

interface ProjectItemProps {
  project: Project;
  onDelete: (id: string) => void;
  onChangeName: (id: string, name: string) => void;
}
const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  onDelete,
  onChangeName,
}) => {
  const { t } = useTranslation();
  const setIsLoadingProject = useSetRecoilState(isLoadingProjectAtom);

  const createdAt = useMemo(() => {
    const date = new Date(project.createdAt);
    return [formatDateDay(date), formatDateTime(date)];
  }, [project.createdAt]);

  const updatedAt = useMemo(() => {
    if (project.updatedAt === undefined) return createdAt;
    const date = new Date(project.updatedAt);
    return [formatDateDay(date), formatDateTime(date)];
  }, [project.updatedAt, createdAt]);

  const { track } = useLog();
  const onClickDelete = useCallback(async () => {
    const takes = (await _loadTakes(project.id)) || [];
    const scenes = (await _loadScenes(project.id)) || [];
    // 사용자가 각 scene timeline에 추가한 문장
    const musics: Music[] = [];
    await Promise.all(
      scenes.map(async (scene) => {
        return (await _loadMusics(scene.id)) as Music[];
      })
    ).then((value: Music[][]) => {
      musics.push(...value.reduce((acc, cur) => acc.concat(cur), []));
    });
    // 사용자가 업로드한 음악 파일
    const musicFiles = (await _loadMusicFiles(project.id)) || [];
    // voice profile 수정 정보
    const profileEditInfoList =
      (await _loadProfileEditInfoList(project.id)) || [];

    await Promise.all(
      takes.map(async (take: any) => await _deleteTake(take.id))
    );
    await Promise.all(
      scenes.map(async (scene: any) => await _deleteScene(scene.id))
    );
    await Promise.all(
      musics.map(async (music: Music) => await _deleteMusic(music))
    );
    await Promise.all(
      musicFiles.map(
        async (musicFile: MusicFile) => await _deleteMusicFile(musicFile)
      )
    );
    await Promise.all(
      profileEditInfoList.map(
        async (profile: ProfileEditInfo) =>
          await _deleteProfileEditInfo(profile)
      )
    );
    track('DELETE_PROJECT', { projectId: project.id });
    onDelete(project.id);
  }, [project.id, onDelete, track]);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [isEditName, setIsEditName] = useState(false);

  const moveToProject = useCallback(() => {
    setIsLoadingProject(true);
    navigate(`/${project.id}/editor`);
  }, [project.id, navigate, setIsLoadingProject]);

  const [preview, setPreview] = useState<string>('');

  useEffect(() => {
    if (!project.id) return;
    // take의 첫번째 문장을 preview로 사용
    _loadTakes(project.id).then((takes) => {
      setPreview(takes?.[0]?.text || '');
    });
  }, [project.id]);

  useEffect(() => {
    return () => {
      setIsEditName(false);
    };
  }, []);

  const [isOpenControlLayer, setIsOpenControlLayer] = useState(false);
  const toggleControlLayer = useCallback(() => {
    setIsOpenControlLayer((prev) => !prev);
  }, []);
  const closeControlLayer = useCallback(() => {
    setIsOpenControlLayer(false);
  }, []);

  const onClickEditProject = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsEditName(true);
      closeControlLayer();
    },
    [setIsEditName, closeControlLayer]
  );

  const changeName = useCallback(
    (value: string) => {
      if (!value.trim()) return;
      onChangeName(project.id, value);
    },
    [onChangeName, project]
  );

  const onBlur = useCallback(() => {
    setIsEditName(false);
    changeName(project.name);
  }, [setIsEditName, project.name, changeName]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        changeName(e.currentTarget.value);
        setIsEditName(false);
      }
      if (e.code === 'Escape') {
        setIsEditName(false);
      }
    },
    [setIsEditName, changeName]
  );

  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (isEditName) {
      ref.current?.focus();
    }
  }, [isEditName]);

  return (
    <>
      <li
        className={classNames(
          'project-list-item',
          isOpenControlLayer && 'selected'
        )}
        onClick={moveToProject}
      >
        <section className="project-list-item-title">
          {!isEditName ? (
            <strong className="project-name-text">{project.name}</strong>
          ) : (
            <strong className="project-name-text editing">
              <textarea
                ref={ref}
                rows={1}
                className="project-name-edit"
                onBlur={onBlur}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={onKeyDown}
                placeholder={project.name}
                data-hj-allow
              />
            </strong>
          )}
          <section className="project-control">
            <Dropdown
              isOpen={isOpenControlLayer}
              anchorElement={
                <IconButton
                  className="btn-project-more"
                  color={'transparent'}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleControlLayer();
                  }}
                >
                  <MoreIcon />
                </IconButton>
              }
              placement="bottom-right"
              closeOnClickOutside={true}
              onClose={() => setIsOpenControlLayer(false)}
              style={{ padding: 0 }}
            >
              <StyledProjectImportDropDown>
                <Button
                  color="transparent"
                  className="btn-control project-control-item"
                  onClick={onClickEditProject}
                  startIcon={<EditIcon />}
                >
                  {t('Rename Project')}
                </Button>

                <Button
                  color="transparent"
                  className="btn-control project-control-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    closeControlLayer();
                    setIsOpenDeleteModal(true);
                  }}
                  startIcon={<DeleteIcon />}
                >
                  Delete Project
                </Button>
              </StyledProjectImportDropDown>
            </Dropdown>
          </section>
        </section>
        <section className="project-list-item-content">
          <section className="project-preview">
            <p>{preview}</p>
          </section>
          <ul className="project-info">
            <li className="project-item">
              <span className="label">{t('Last modified')}</span>
              <span className="value">
                {updatedAt[0]}
                <em className="time">{updatedAt[1]}</em>
              </span>
            </li>
            <li className="project-item">
              <span className="label">{t('Created')}</span>
              <span className="value">
                {createdAt[0]}
                <em className="time">{createdAt[1]}</em>
              </span>
            </li>
          </ul>
        </section>
      </li>
      <Modal
        isPortal={true}
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
      >
        <ModalHeader>{t('Delete Project')}</ModalHeader>
        <ModalBody>
          {t('If you delete a Project, All datas will be lost.')}
        </ModalBody>
        <ModalFooter>
          <Button
            color="#ffffff"
            onClick={() => setIsOpenDeleteModal(false)}
            variant="outlined"
          >
            {t('Cancel')}
          </Button>
          <Button color={Secondary[200]} onClick={onClickDelete}>
            {t('Delete Anyway')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ProjectItem;
