import { PropsWithChildren } from 'react';

import { Primary } from '../../styles/Colors';
import Tooltip from '../Tooltip/Tooltip';
import { FONT_SIZE } from './const';
import { StyledIconButton } from './StyledButton';
import { IconButtonProps } from './types';

const IconButton = ({
  variant = 'contained',
  radius = 'md',
  color = Primary['400'],
  size = 'md',
  className = '',
  children,
  isFillCurrentColor,
  tooltip,
  placement = 'top',
  ...rest
}: PropsWithChildren<IconButtonProps>) => {
  return tooltip ? (
    <Tooltip content={tooltip} placement={placement}>
      <StyledIconButton
        className={`sup-icon-button ${className}`}
        variant={variant}
        radius={radius}
        color={color}
        fontSize={FONT_SIZE[size]}
        isFillCurrentColor={isFillCurrentColor}
        {...rest}
      >
        {children}
      </StyledIconButton>
    </Tooltip>
  ) : (
    <StyledIconButton
      className={`sup-icon-button ${className}`}
      variant={variant}
      radius={radius}
      color={color}
      fontSize={FONT_SIZE[size]}
      isFillCurrentColor={isFillCurrentColor}
      {...rest}
    >
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
