export const FontWeight = {
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
} as const;

export const FontSizeRatio = 0.8;
export const BasicFontSize = 16 * FontSizeRatio;

export const FontSize = {
  Xs: '0.625rem',
  Sm: '0.75rem',
  Md: '0.875rem',
  Lg: '0.94rem',
  Xl: '1.1rem',
  '2Xl': '1.25rem',
  '3Xl': '1.5rem',
  '4Xl': '1.875rem',
  '5Xl': '2.25rem',
  '6Xl': '3rem',
  '7Xl': '3.75rem',
  '8Xl': '4.5rem',
  '9Xl': '6rem',
  '10Xl': '8rem',
} as const;

export const FontFamily = {
  Inter: '"Inter", sans-serif',
} as const;
