import { CSSProperties, ReactNode } from 'react';

import BrandWrapper from './BrandWrapper';
import { StyledLanding } from './styled/StyledLanding';

interface LandingProps {
  children?: ReactNode;
  style?: CSSProperties;
}

export const Landing = ({ children, style }: LandingProps) => {
  return (
    <StyledLanding style={{ ...style }}>
      <BrandWrapper />
      {children}
    </StyledLanding>
  );
};

export default Landing;
