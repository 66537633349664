import { FontSizeRatio } from '../../styles/Typography';
import { Placement, SimpleDOMRect } from './Tooltip';

const [ARROW_WIDTH, ARROW_HEIGHT] = [
  Math.round(13 * FontSizeRatio),
  Math.round(6 * FontSizeRatio),
];
// Dimension of the arrow.
const arrowSizeMap = {
  top: { width: ARROW_WIDTH, height: ARROW_HEIGHT },
  right: { width: ARROW_HEIGHT, height: ARROW_WIDTH },
  bottom: { width: ARROW_WIDTH, height: ARROW_HEIGHT },
  left: { width: ARROW_HEIGHT, height: ARROW_WIDTH },
};

export const calculateAdjustedPlacement = (
  placement: Placement,
  boundingRect: SimpleDOMRect,
  contentBoundingRect: DOMRect,
  elementBoundingRect: DOMRect,
  distance: number,
  arrowBuffer: number
) => {
  let adjustedPlacement: Placement | null = null;

  switch (placement) {
    case 'top': {
      adjustedPlacement = 'top';

      // If there is no space on the top, place it on the bottom.
      if (
        contentBoundingRect.height + arrowSizeMap[placement].height + distance >
        elementBoundingRect.top - boundingRect.top
      ) {
        adjustedPlacement = 'bottom';
      }

      // If there exists too small space on the left side, ignore 'top' and place it on the left.
      if (
        boundingRect.left + boundingRect.width - elementBoundingRect.left <
        arrowSizeMap[placement].width + arrowBuffer
      ) {
        adjustedPlacement = 'left';
      }

      // If there exists too small space on the right side, ignore 'top' and place it on the right.
      if (
        elementBoundingRect.right - boundingRect.left <
        arrowSizeMap[placement].width + arrowBuffer
      ) {
        adjustedPlacement = 'right';
      }

      break;
    }

    case 'right': {
      adjustedPlacement = 'right';

      // If there are no space on the right, place it on the left.
      if (
        elementBoundingRect.width +
          contentBoundingRect.left +
          contentBoundingRect.width +
          arrowSizeMap['right'].width +
          distance >
        boundingRect.left + boundingRect.width
      ) {
        adjustedPlacement = 'left';
      }

      // If there exists too small space on the top side, ignore 'right' and place it on the top.
      if (
        boundingRect.top + boundingRect.height - elementBoundingRect.top <
        arrowSizeMap[placement].height + arrowBuffer
      ) {
        adjustedPlacement = 'top';
      }

      // If there exists too small space on the bottom side, ignore 'right' and place it on the bottom.
      if (
        elementBoundingRect.bottom - boundingRect.top <
        arrowSizeMap[placement].height + arrowBuffer
      ) {
        adjustedPlacement = 'bottom';
      }

      break;
    }

    case 'bottom': {
      adjustedPlacement = 'bottom';

      // If there is no space on the bottom, place it on the top.
      if (
        contentBoundingRect.height + arrowSizeMap[placement].height + distance >
        boundingRect.top +
          boundingRect.height -
          elementBoundingRect.top -
          elementBoundingRect.height
      ) {
        adjustedPlacement = 'top';
      }

      // If there exists too small space on the left side, ignore 'bottom' and place it on the left.
      if (
        boundingRect.left + boundingRect.width - elementBoundingRect.left <
        arrowSizeMap[placement].width + arrowBuffer
      ) {
        adjustedPlacement = 'left';
      }

      // If there exists too small space on the right side, ignore 'bottom' and place it on the right.
      if (
        elementBoundingRect.right - boundingRect.left <
        arrowSizeMap[placement].width + arrowBuffer
      ) {
        adjustedPlacement = 'right';
      }

      break;
    }

    case 'left': {
      adjustedPlacement = 'left';

      // If there are no space on the left, place it on the right.
      if (
        contentBoundingRect.width + arrowSizeMap['left'].width + distance >
        elementBoundingRect.left - boundingRect.left
      ) {
        adjustedPlacement = 'right';
      }

      // If there exists too small space on the top side, ignore 'left' and place it on the top.
      if (
        boundingRect.top + boundingRect.height - elementBoundingRect.top <
        arrowSizeMap[placement].height + arrowBuffer
      ) {
        adjustedPlacement = 'top';
      }

      // If there exists too small space on the bottom side, ignore 'left' and place it on the bottom.
      if (
        elementBoundingRect.bottom - boundingRect.top <
        arrowSizeMap[placement].height + arrowBuffer
      ) {
        adjustedPlacement = 'bottom';
      }

      break;
    }
  }

  return adjustedPlacement;
};

export const calculatePositions = (
  placement: Placement,
  boundingRect: SimpleDOMRect,
  contentBoundingRect: DOMRect,
  elementBoundingRect: DOMRect,
  distance: number,
  arrowBuffer: number
) => {
  const r = {
    arrowLeft: 0,
    arrowTop: 0,
    tooltipLeft: 0,
    tooltipTop: 0,
  };

  switch (placement) {
    case 'top': {
      const { width: arrowWidth, height: arrowHeight } = arrowSizeMap['top'];
      const arrowTop = elementBoundingRect.top - distance - arrowHeight;
      const arrowLeft =
        elementBoundingRect.left +
        elementBoundingRect.width / 2 -
        arrowWidth / 2;

      let adjustedArrowLeft = arrowLeft;

      // If there's no space on the left, slide the arrow to the right.
      if (arrowLeft - arrowBuffer < boundingRect.left) {
        adjustedArrowLeft = boundingRect.left + arrowBuffer;
      }

      // If there's no space on the right, slide the arrow to the left.
      if (
        arrowLeft + arrowWidth + arrowBuffer >
        boundingRect.left + boundingRect.width
      ) {
        adjustedArrowLeft =
          boundingRect.left + boundingRect.width - arrowWidth - arrowBuffer;
      }

      r.arrowLeft = adjustedArrowLeft;
      r.arrowTop = arrowTop;

      const tooltipTop = arrowTop - contentBoundingRect.height;
      const tooltipLeft =
        arrowLeft + arrowWidth / 2 - contentBoundingRect.width / 2;

      let adjustedTooltipLeft = tooltipLeft;

      // If there's no space on the left, slide the tooltip to the right.
      if (tooltipLeft < boundingRect.left)
        adjustedTooltipLeft = boundingRect.left;

      // If there's no space on the right, slide the tooltip to the left.
      if (
        tooltipLeft + contentBoundingRect.width >
        boundingRect.left + boundingRect.width
      )
        adjustedTooltipLeft =
          boundingRect.left + boundingRect.width - contentBoundingRect.width;

      r.tooltipLeft = adjustedTooltipLeft;
      r.tooltipTop = tooltipTop;

      break;
    }

    case 'right': {
      const { width: arrowWidth, height: arrowHeight } = arrowSizeMap['right'];
      const arrowTop =
        elementBoundingRect.top +
        elementBoundingRect.height / 2 -
        arrowHeight / 2;
      const arrowLeft =
        elementBoundingRect.left + distance + elementBoundingRect.width;

      let adjustedArrowTop = arrowTop;

      // If there's no space on the top, slide the arrow to the bottom.
      if (arrowTop - arrowBuffer < boundingRect.top) {
        adjustedArrowTop = boundingRect.top + arrowBuffer;
      }

      // If there's no space on the bottom, slide the arrow to the top.
      if (
        arrowTop + arrowHeight + arrowBuffer >
        boundingRect.top + boundingRect.height
      ) {
        adjustedArrowTop =
          boundingRect.top + boundingRect.height - arrowHeight - arrowBuffer;
      }

      r.arrowLeft = arrowLeft;
      r.arrowTop = adjustedArrowTop;

      const tooltipTop =
        arrowTop + arrowHeight / 2 - contentBoundingRect.height / 2;

      const tooltipLeft = arrowLeft + arrowWidth;

      let adjustedTooltipTop = tooltipTop;

      // If there's no space on the top, slide the tooltip to the bottom.
      if (tooltipTop < boundingRect.top) {
        adjustedTooltipTop = boundingRect.top;
      }

      // If there's no space on the bottom, slide the tooltip to the top.
      if (
        tooltipTop + contentBoundingRect.height >
        boundingRect.top + boundingRect.height
      ) {
        adjustedTooltipTop =
          boundingRect.top + boundingRect.height - contentBoundingRect.height;
      }

      r.tooltipLeft = tooltipLeft;
      r.tooltipTop = adjustedTooltipTop;

      break;
    }

    case 'bottom': {
      const { width: arrowWidth, height: arrowHeight } = arrowSizeMap['bottom'];
      const arrowTop =
        elementBoundingRect.top + distance + elementBoundingRect.height;
      const arrowLeft =
        elementBoundingRect.left +
        elementBoundingRect.width / 2 -
        arrowWidth / 2;

      let adjustedArrowLeft = arrowLeft;

      // If there's no space on the left, slide the arrow to the right.
      if (arrowLeft < boundingRect.left) {
        adjustedArrowLeft = boundingRect.left + arrowBuffer;
      }

      // If there's no space on the right, slide the arrow to the left.
      if (
        arrowLeft + arrowWidth + arrowBuffer >
        boundingRect.left + boundingRect.width
      ) {
        adjustedArrowLeft =
          boundingRect.left + boundingRect.width - arrowWidth - arrowBuffer;
      }

      r.arrowLeft = adjustedArrowLeft;
      r.arrowTop = arrowTop;

      const tooltipTop = arrowTop + arrowHeight;
      const tooltipLeft =
        arrowLeft + arrowWidth / 2 - contentBoundingRect.width / 2;

      let adjustedTooltipLeft = tooltipLeft;

      // If there's no space on the left, slide the tooltip to the right.
      if (tooltipLeft < boundingRect.left)
        adjustedTooltipLeft = boundingRect.left;

      // If there's no space on the right, slide the tooltip to the left.
      if (
        tooltipLeft + contentBoundingRect.width >
        boundingRect.left + boundingRect.width
      )
        adjustedTooltipLeft =
          boundingRect.left + boundingRect.width - contentBoundingRect.width;

      r.tooltipLeft = adjustedTooltipLeft;
      r.tooltipTop = tooltipTop;

      break;
    }

    case 'left': {
      const { width: arrowWidth, height: arrowHeight } = arrowSizeMap['left'];
      const arrowTop =
        elementBoundingRect.top +
        elementBoundingRect.height / 2 -
        arrowHeight / 2;
      const arrowLeft = elementBoundingRect.left - distance - arrowWidth;

      let adjustedArrowTop = arrowTop;

      // If there's no space on the top, slide the arrow to the bottom.
      if (arrowTop - arrowBuffer < boundingRect.top) {
        adjustedArrowTop = boundingRect.top + arrowBuffer;
      }

      // If there's no space on the bottom, slide the arrow to the top.
      if (
        arrowTop + arrowHeight + arrowBuffer >
        boundingRect.top + boundingRect.height
      ) {
        adjustedArrowTop =
          boundingRect.top + boundingRect.height - arrowHeight - arrowBuffer;
      }

      r.arrowLeft = arrowLeft;
      r.arrowTop = adjustedArrowTop;

      const tooltipTop =
        arrowTop + arrowHeight / 2 - contentBoundingRect.height / 2;

      const tooltipLeft = arrowLeft - contentBoundingRect.width;

      let adjustedTooltipTop = tooltipTop;

      // If there's no space on the top, slide the tooltip to the bottom.
      if (tooltipTop < boundingRect.top) adjustedTooltipTop = boundingRect.top;

      // If there's no space on the bottom, slide the tooltip to the top.
      if (
        tooltipTop + contentBoundingRect.height >
        boundingRect.top + boundingRect.height
      )
        adjustedTooltipTop =
          boundingRect.top + boundingRect.height - contentBoundingRect.height;

      r.tooltipLeft = tooltipLeft;
      r.tooltipTop = adjustedTooltipTop;

      break;
    }
  }

  return r;
};
