import { normalizeWheel } from '@/util/event';
import { roundTo } from '@/util/number';
import { ScaleLinear } from 'd3-scale';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useDataContext } from '../../providers/DataContextProvider';
import { useTimelineContext } from '../../providers/TimelineContextProvider';
import { audioPlayerStateAtom } from '../../stores/atoms/audio';
import { timelinePlaybackAtom } from '../../stores/atoms/ui';
import { useMusic } from '../../stores/recoilHooks/useMusic';
import { TimelineListItem } from '../../stores/timeline';
import Block, { BlockInfo } from './Block';
import {
  BLOCK_COLOR_LIST,
  BLOCK_HEIGHT,
  COLUMN_HEIGHT,
  LEFT_OFFSET,
  MIN_RESIZE_WIDTH,
  SNAP_TIME,
} from './const';
import GapBlock, { GapBlockInfo } from './GapBlock';

interface AudioContainerProps {
  xRange?: [number, number];
  timeRange?: [number, number];
  updateXPosition?: (p: number) => void;
  data: TimelineListItem[];
  xScale: ScaleLinear<number, number>;
  updateXScale?: (scale: number, centerP?: number) => void;
  isSnap?: boolean;
}
export interface DragInfo extends BlockInfo {
  isDragging: boolean;
}

type DragType = 'drag' | 'resize-start' | 'resize-end' | 'delete' | null;

const AudioContainer = ({
  data,
  xScale,
  xRange,
  timeRange,
  updateXPosition,
  updateXScale,
  isSnap,
}: AudioContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [draggingItem, setDraggingItem] = useState<DragInfo | null>();
  const prevItemRef = useRef<GapBlockInfo | null>(null);
  const nextItemRef = useRef<GapBlockInfo | null>(null);

  const audioPlayerState = useRecoilValue(audioPlayerStateAtom);
  const { updateActiveLineId } = useDataContext();

  const { musicList, updateMusic, deleteMusic } = useMusic();

  const { activeTakeList, updateLinePosition } = useTimelineContext();
  const setTimelinePlayback = useSetRecoilState(timelinePlaybackAtom);

  const isAudioPlaying = useMemo(() => {
    if (audioPlayerState?.type === 'timeline' && audioPlayerState?.isPlaying) {
      return true;
    }
    return false;
  }, [audioPlayerState]);

  const items = useMemo(() => {
    if (!xScale || !xRange) return [];
    const dataList = data.reduce((acc, cur, index) => {
      return acc.concat(
        cur.items
          // 좌우 스크롤 시 리렌더링 방지를 위해 일단 filtering하지 않는다
          .map((item) => ({
            id: item.id,
            lineId: item.lineId,
            resource_id: item.resource_id,
            content: item.content,
            type: item.type,
            startX: roundTo(
              xScale(item.position + (item?.startOffset ? item.startOffset : 0))
            ),
            endX: roundTo(
              xScale(
                item.position +
                  ((item.duration || 0) -
                    (item?.endOffset ? item.endOffset : 0))
              )
            ),
            origStartX: roundTo(xScale(item.position)),
            origEndX: roundTo(xScale(item.position + (item.duration || 0))),
            startY: roundTo(
              index * COLUMN_HEIGHT + (COLUMN_HEIGHT - BLOCK_HEIGHT) / 2
            ),
            endY: roundTo(
              index * COLUMN_HEIGHT + (COLUMN_HEIGHT + BLOCK_HEIGHT) / 2
            ),
            disabled: cur.voice.disabled,
            ...(item.type !== 'music' && {
              color: BLOCK_COLOR_LIST[index % BLOCK_COLOR_LIST.length],
            }),
          }))
      );
    }, [] as BlockInfo[]);
    return dataList;
  }, [data, xScale, xRange]);

  // prev item
  const prevItem = useMemo(() => {
    if (!draggingItem) return null;
    const prevList = items
      .reduce((acc, item) => {
        if (item.id === draggingItem.id) return acc;
        if (
          item.startX <= draggingItem.startX ||
          item.endX <= draggingItem.startX
        ) {
          const minDist =
            item.endX <= draggingItem.startX
              ? Math.abs(item.endX - draggingItem.startX)
              : Math.abs(item.startX - draggingItem.startX);
          if (acc.length === 0) {
            acc = [item];
          } else {
            const accDistToStart =
              acc[0].endX <= draggingItem.startX
                ? Math.abs(acc[0].endX - draggingItem.startX)
                : Math.abs(acc[0].startX - draggingItem.startX);
            const accDistToEnd = Math.abs(draggingItem.startX - acc[0].endX);
            const accMinDist = Math.min(accDistToStart, accDistToEnd);
            if (minDist < accMinDist) {
              acc = [item];
            } else if (minDist === accMinDist && item.endX === acc[0].endX) {
              acc.push(item);
            }
          }
        }
        return acc;
      }, [] as BlockInfo[])
      // 드래그 아이템 기준으로 가장 가까운 블록부터 정렬
      .sort(
        (a, b) =>
          Math.abs(a.startY - draggingItem.startY) -
          Math.abs(b.startY - draggingItem.startY)
      );
    // prev가 없는 경우 0을 기준으로 설정
    if (!prevList.length) {
      return {
        ...draggingItem,
        startX: roundTo(xScale(0)),
        endX: draggingItem.startX,
        seconds: roundTo(
          xScale.invert(draggingItem.startX) - xScale.invert(xScale(0)),
          1
        ),
      };
    }

    // 소수점 첫째자리까지만 표시
    let seconds =
      xScale.invert(draggingItem.startX) - xScale.invert(prevList[0].endX);

    // 길이가 긴 블록의 사이에 위치한 경우
    if (seconds < 0) {
      seconds =
        xScale.invert(draggingItem.startX) - xScale.invert(prevList[0].startX);
      return {
        ...prevList[0],
        startX: prevList[0].startX,
        endX: draggingItem.startX,
        startY: draggingItem.startY,
        endY: draggingItem.endY,
        seconds,
        isMultiple: !!(prevList.length > 1),
      };
    }

    return {
      ...prevList[0],
      startX: prevList[0].endX,
      endX: draggingItem.startX,
      startY: prevList[0].startY,
      endY: prevList[prevList.length - 1].endY,
      seconds,
      isMultiple: !!(prevList.length > 1),
    };
  }, [items, draggingItem, xScale]);

  // next item
  const nextItem = useMemo(() => {
    if (!draggingItem) return null;
    const nextList = items
      .reduce((acc, item) => {
        if (item.id === draggingItem.id) return acc;
        if (
          item.startX >= draggingItem.endX ||
          item.endX >= draggingItem.endX
        ) {
          const minDist =
            item.startX >= draggingItem.endX
              ? Math.abs(item.startX - draggingItem.endX)
              : Math.abs(item.endX - draggingItem.endX);
          if (acc.length === 0) {
            acc = [item];
          } else {
            const accMinDist =
              acc[0].startX >= draggingItem.endX
                ? Math.abs(acc[0].startX - draggingItem.endX)
                : Math.abs(acc[0].endX - draggingItem.endX);
            if (minDist < accMinDist) {
              acc = [item];
            } else if (
              minDist === accMinDist &&
              item.startX === acc[0].startX
            ) {
              acc.push(item);
            }
          }
        }
        return acc;
      }, [] as BlockInfo[])
      .sort(
        (a, b) =>
          Math.abs(a.startY - draggingItem.startY) -
          Math.abs(b.startY - draggingItem.startY)
      );

    if (!nextList.length) return null;

    let seconds =
      xScale.invert(nextList[0].startX) - xScale.invert(draggingItem.endX);

    // 길이가 긴 블록의 사이에 위치한 경우
    if (seconds < 0) {
      seconds =
        xScale.invert(nextList[0].endX) - xScale.invert(draggingItem.endX);
      return {
        ...nextList[0],
        startX: draggingItem.endX,
        endX: nextList[0].endX,
        startY: draggingItem.startY,
        endY: draggingItem.endY,
        seconds,
        isMultiple: !!(nextList.length > 1),
      };
    }

    return {
      ...nextList[0],
      startX: draggingItem.endX,
      endX: nextList[0].startX,
      startY: nextList[0].startY,
      endY: nextList[nextList.length - 1].endY,
      seconds,
      isMultiple: !!(nextList.length > 1),
    };
  }, [items, draggingItem, xScale]);

  useEffect(() => {
    prevItemRef.current = prevItem;
    nextItemRef.current = nextItem;
  }, [prevItem, nextItem]);

  const getSnappedDx = (item: BlockInfo, dx: number, snapSize: number) => {
    const prev = prevItemRef.current;
    const next = nextItemRef.current;
    let snappedDx = Math.round(dx / snapSize) * snapSize;

    const isCloseToPrevStart =
      prev && Math.abs(item.startX + snappedDx - prev.startX) < snapSize;
    const isCloseToPrevEnd =
      prev && Math.abs(prev.endX - (item.startX + snappedDx)) < snapSize;
    const isCloseToNextStart =
      next && Math.abs(next.startX - (item.endX + snappedDx)) < snapSize;
    const isCloseToNextEnd =
      next && Math.abs(next.endX - (item.endX + snappedDx)) < snapSize;

    if (
      (isCloseToPrevStart || isCloseToPrevEnd) &&
      (isCloseToNextStart || isCloseToNextEnd)
    ) {
      // prev와 next 양쪽에 가까울 때 snappedDx를 가까운 쪽으로 설정
      const prevDistance = isCloseToPrevEnd
        ? Math.abs(prev.endX - item.startX)
        : Math.abs(prev.startX - item.startX);
      const nextDistance = isCloseToNextStart
        ? Math.abs(next.startX - item.endX)
        : Math.abs(next.endX - item.endX);
      snappedDx =
        prevDistance < nextDistance
          ? isCloseToPrevEnd
            ? prev.endX - item.startX
            : prev.startX - item.startX
          : isCloseToNextStart
          ? next.startX - item.endX
          : next.endX - item.endX;
    } else if (isCloseToPrevStart) {
      // prev의 시작점에 닿았을 때
      snappedDx = prev.startX - item.startX;
    } else if (isCloseToPrevEnd) {
      // prev의 끝점에 닿았을 때
      snappedDx = prev.endX - item.startX;
    } else if (isCloseToNextStart) {
      // next의 시작점에 닿았을 때
      snappedDx = next.startX - item.endX;
    } else if (isCloseToNextEnd) {
      // next의 끝점에 닿았을 때
      snappedDx = next.endX - item.endX;
    }

    return roundTo(snappedDx);
  };

  const calculatePosition = useCallback(
    (item: BlockInfo, dx: number, dragType: DragType) => {
      let { startX, endX, origStartX, origEndX } = item;

      if (dragType === 'resize-start') {
        startX = roundTo(startX + dx);
        if (endX - startX <= MIN_RESIZE_WIDTH) {
          startX = roundTo(endX - MIN_RESIZE_WIDTH);
        } else if (startX < origStartX) {
          startX = origStartX;
        }
      } else if (dragType === 'resize-end') {
        endX = roundTo(endX + dx);
        if (endX - startX <= MIN_RESIZE_WIDTH) {
          endX = roundTo(startX + MIN_RESIZE_WIDTH);
        } else if (endX > origEndX) {
          endX = origEndX;
        }
      } else {
        // 시작점이 0보다 작은 경우 이동하지 않음
        if (startX + dx < 0) {
          startX = Math.max(startX + dx, 0);
          endX = startX + (endX - item.startX);
          origStartX = origStartX + (startX - item.startX);
          origEndX = origStartX + (origEndX - item.origStartX);
        } else {
          startX = roundTo(startX + dx);
          endX = roundTo(endX + dx);
          origStartX = roundTo(origStartX + dx);
          origEndX = roundTo(origEndX + dx);
        }
      }
      return {
        startX,
        endX,
        origStartX,
        origEndX,
      };
    },
    []
  );

  const handlePointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (isAudioPlaying) return;
      let dragType: DragType = null;

      if ((e.target as HTMLElement).closest('.block-delete')) {
        dragType = 'delete';
      } else if ((e.target as HTMLElement).closest('.block-resize-start')) {
        dragType = 'resize-start';
      } else if ((e.target as HTMLElement).closest('.block-resize-end')) {
        dragType = 'resize-end';
      } else if ((e.target as HTMLElement).closest('.block')) {
        dragType = 'drag';
      }

      const target = (e.target as HTMLElement).closest('.block');
      if (!dragType || !target) return;

      const id = target.id;
      const idx = items.findIndex((item) => item.id === id);
      if (idx === -1) return;

      if (dragType === 'delete') {
        const music = musicList.find((music) => music.id === id);
        if (!music) return;
        deleteMusic(music);
        return;
      }

      const startClientX = e.clientX;
      setDraggingItem({
        ...items[idx],
        isDragging: true,
      });

      const handlePointerMove = (e: PointerEvent) => {
        e.preventDefault();

        let dx = roundTo(e.clientX - startClientX);
        if (isSnap && (prevItemRef.current || nextItemRef.current)) {
          dx = getSnappedDx(items[idx], dx, xScale(SNAP_TIME));
        }

        const { startX, endX, origStartX, origEndX } = calculatePosition(
          items[idx],
          dx,
          dragType
        );

        setDraggingItem({
          ...items[idx],
          isDragging: true,
          startX,
          endX,
          origStartX,
          origEndX,
        });
      };

      const handlePointerUp = (e: PointerEvent) => {
        let dx = roundTo(e.clientX - startClientX);
        if (isSnap && (prevItemRef.current || nextItemRef.current)) {
          dx = getSnappedDx(items[idx], dx, xScale(SNAP_TIME));
        }

        const dragItem = items[idx];
        const { startX, endX, origStartX, origEndX } = calculatePosition(
          dragItem,
          dx,
          dragType
        );

        // tmp: music 블록에 대해 겹치는 부분이 있는지 확인
        const isOverlap =
          dragItem.type === 'music' &&
          items
            .filter((i) => i.type === 'music')
            .some(
              (item) =>
                item.id !== dragItem.id &&
                ((startX >= item.startX && startX <= item.endX) ||
                  (endX >= item.startX && endX <= item.endX) ||
                  (startX <= item.startX && endX >= item.endX))
            );
        if (isOverlap) {
          setDraggingItem(null);
          window.removeEventListener('pointermove', handlePointerMove);
          window.removeEventListener('pointerup', handlePointerUp);
          return;
        }

        let newStartP = xScale.invert(startX);
        // 만약 최대 시간보다 newStartX+duration이 크다면, newStartX를 최대 시간으로 설정
        const maxTimeP = timeRange ? xScale(timeRange[1]) - LEFT_OFFSET : 0;

        if (timeRange && endX > maxTimeP) {
          newStartP = xScale.invert(startX - (endX - maxTimeP));
        }

        if (dragItem.type === 'music') {
          const musicIdx = musicList.findIndex(
            (music) => music.id === dragItem.id
          );
          if (musicIdx !== -1) {
            const startOffset =
              xScale.invert(startX) - xScale.invert(origStartX);
            const endOffset = xScale.invert(origEndX) - xScale.invert(endX);
            const newMusic = {
              ...musicList[musicIdx],
              position: newStartP - startOffset,
              startOffset,
              endOffset,
            };
            updateMusic(newMusic);
          }
        } else {
          updateLinePosition(dragItem.lineId, newStartP);
          // reset selected line id
          updateActiveLineId(dragItem.lineId);
          // timeline playback position update
          setTimelinePlayback(newStartP);
        }

        // tmp: data sync 이슈로 useEffect에서 처리하도록 임시 변경
        // setDraggingItem(null);

        window.removeEventListener('pointermove', handlePointerMove);
        window.removeEventListener('pointerup', handlePointerUp);
      };

      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
    },
    [
      items,
      isAudioPlaying,
      xScale,
      timeRange,
      setTimelinePlayback,
      updateActiveLineId,
      isSnap,
      updateLinePosition,
      musicList,
      updateMusic,
      calculatePosition,
      deleteMusic,
    ]
  );

  // tmp: indexdedDB 업데이트 전 null 처리 시 아이템이 남아있는 문제가 있어서 useEffect로 처리
  useEffect(() => {
    setDraggingItem(null);
  }, [activeTakeList, musicList]);

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      // passive: false 처리를 해야 preventDefault가 동작함
      e.preventDefault();
      if (!xRange || !timeRange) return;
      const [dx, dy] = normalizeWheel(e);

      if (e.ctrlKey) {
        const WHEEL_SCALE_SPEEDUP = 1.1;
        const scale =
          dy <= 0
            ? 1 / (1 - (dy * WHEEL_SCALE_SPEEDUP) / 100)
            : 1 + (dy * WHEEL_SCALE_SPEEDUP) / 100;
        updateXScale?.(scale, e.clientX - LEFT_OFFSET);
      } else {
        if (dy) {
          const container = containerRef.current?.parentElement;
          if (!container) return;
          // 위아래 드래그
          container.scrollTop += dy;
        }
        const [start, end] = xRange;
        const newCenter = xScale.invert(xScale((start + end) / 2) + dx);
        const newPosition = (newCenter / timeRange[1]) * 100;
        // 좌우 드래그
        updateXPosition?.(newPosition);
      }
    },
    [xScale, xRange, timeRange, updateXPosition, updateXScale]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const rafHandleWheel = (e: WheelEvent) => {
      e.preventDefault();
      requestAnimationFrame(() => handleWheel(e));
    };

    container.addEventListener('wheel', rafHandleWheel, { passive: false });
    return () => {
      container.removeEventListener('wheel', rafHandleWheel);
    };
  }, [handleWheel]);

  return (
    <div
      className="timeline-block-container"
      ref={containerRef}
      onPointerDown={handlePointerDown}
    >
      <div className="grids">
        {data.length &&
          data.map((_, i) => <div key={i} className="grid"></div>)}
      </div>
      <div className="blocks">
        {items.length
          ? items.map((item) => {
              if (item.id === draggingItem?.id) return null;
              return (
                <Block
                  key={item.id}
                  data={item}
                  isDraggable={!isAudioPlaying}
                  dimmed={draggingItem?.id === item.id}
                  isEditable={item.type === 'music'}
                />
              );
            })
          : null}
        {draggingItem && (
          <>
            <Block
              data={{
                ...draggingItem,
              }}
              dragging={draggingItem?.isDragging}
              isDraggable={!isAudioPlaying}
              hide={!draggingItem?.isDragging}
              isEditable={draggingItem.type === 'music'}
            />
            {prevItem && <GapBlock data={prevItem} dragData={draggingItem} />}
            {nextItem && <GapBlock data={nextItem} dragData={draggingItem} />}
          </>
        )}
      </div>
    </div>
  );
};

export default AudioContainer;
