import styled from '@emotion/styled';

import { CardUIProps } from './CardUI';

export const StyledCardUI = styled.div(
  ({
    padding,
    backgroundColor,
    justifyContent,
    alignItems,
    alignContent,
    flexDirection,
    gap,
  }: CardUIProps) => {
    return {
      display: 'flex',
      overflow: 'hidden',
      padding,
      backgroundColor,
      flexDirection,
      justifyContent,
      alignItems,
      alignContent,
      gap,
    };
  }
);

export const StyledCardUIContent = styled(StyledCardUI)({
  boxSizing: 'border-box',
  width: '100%',
});
