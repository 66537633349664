import { PurchaseBtnHover } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledEndTrialUser = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  .purchase-now-button {
    text-decoration-line: underline;
    &:hover {
      color: ${PurchaseBtnHover};
    }
  }
`;

export default StyledEndTrialUser;
