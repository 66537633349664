import { ReactComponent as ArrowUpRightIcon } from '@/assets/icons/ArrowUpRightIcon.svg';
import Button from '@/components/Button/Button';
import { Grey } from '@/styles/Colors';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';

import Landing from '../Landing/Landing';
import StyledLogin from './StyledLogin';

const Login = () => {
  const { loginWithRedirect } = useSUPAuth();
  const redirectUri = window.location.origin + '/after-signup';

  return (
    <StyledLogin>
      <Landing>
        <div className="btn-group">
          <Button
            size="lg"
            className="btn-login sign-up"
            color={Grey['50']}
            endIcon={<ArrowUpRightIcon />}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: 'signup',
                  redirect_uri: redirectUri,
                },
              })
            }
            iconWidth={60}
            iconHeight={62}
          >
            Sign up
          </Button>
          <Button
            size="lg"
            variant="outlined"
            color={Grey['50']}
            className="btn-login"
            endIcon={<ArrowUpRightIcon />}
            iconWidth={60}
            iconHeight={62}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  redirect_uri: redirectUri,
                },
              })
            }
          >
            Sign in
          </Button>
        </div>
      </Landing>
    </StyledLogin>
  );
};

export default Login;
