import styled from '@emotion/styled';

const StyledDeleteConfirmModal = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  .modal {
    width: 23.5rem;
    .modal-footer {
      display: flex;
    }
  }
`;

export default StyledDeleteConfirmModal;
