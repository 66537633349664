import { HTMLAttributes, ReactNode } from 'react';

interface ModalFooterProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const ModalFooter = ({ children, ...rest }: ModalFooterProps) => {
  return (
    <div className="modal-footer" {...rest}>
      {children}
    </div>
  );
};

export default ModalFooter;
