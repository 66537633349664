import { ReactNode } from 'react';

interface MessagePageProps {
  message: ReactNode;
}

const MessagePage = ({ message }: MessagePageProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      {message}
    </div>
  );
};

export default MessagePage;
