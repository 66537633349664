import { White, WhiteGray } from '@/styles/Colors';
import { FontSize } from '@/styles/Typography';
import styled from '@emotion/styled';

const getRandom = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};
const getRandomLanding = () => {
  const random = getRandom(1, 5);
  return `url(${require(`@/assets/images/obt_Landing_${random}.png`)})`;
};
export const StyledLanding = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: ${getRandomLanding()};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledBrandWrapper = styled.div`
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  // mobile
  @media (max-width: 475px) {
    .guide-for-mobile-users {
      top: 10%;
      width: 80%;
    }
    .logo-product-screenplay {
      top: 35%;
      gap: 2rem;
      .logos {
        .logo-screenplay-mobile {
          display: inline;
          height: auto;
        }
        .logo-screenplay-tablet {
          display: none;
        }
        .logo-screenplay-pc {
          display: none;
        }
        .logo-cursor-mobile {
          display: inline;
          margin-left: 1.8rem;
          animation: blink 1s infinite; /* 1초 간격으로 무한 반복 */
        }
        .logo-cursor-tablet {
          display: none;
        }
        .logo-cursor-pc {
          display: none;
        }
      }
      .logo-open-beta {
        height: 2.5rem;
      }
      .text {
        font-size: ${FontSize['3Xl']};
      }
    }
    .web-site-back-link-section {
      top: 74%;
      a {
        font-size: ${FontSize['Xl']};
        line-height: ${FontSize['2Xl']};
      }
    }
    .terms-policy-section {
      top: 82%;
      .terms-policy {
        font-size: ${FontSize['Md']};
        line-height: ${FontSize['2Xl']};
      }
    }
  }

  // ipad or tablet
  @media (min-width: 476px) and (max-width: 1024px) {
    .guide-for-mobile-users {
      top: 10%;
      width: 34%;
    }
    .logo-product-screenplay {
      top: 40%;
      gap: 2rem;
      .logos {
        .logo-screenplay-mobile {
          display: none;
        }
        .logo-screenplay-tablet {
          display: inline;
          height: auto;
        }
        .logo-screenplay-pc {
          display: none;
        }
        .logo-cursor-mobile {
          display: none;
        }
        .logo-cursor-tablet {
          display: inline;
          margin-left: 1.8rem;
          animation: blink 1s infinite; /* 1초 간격으로 무한 반복 */
        }
        .logo-cursor-pc {
          display: none;
        }
      }
      .logo-open-beta {
        height: 3.2rem;
      }
      .text {
        font-size: ${FontSize['6Xl']};
      }
    }
    .web-site-back-link-section {
      top: 72%;
      a {
        font-size: ${FontSize['2Xl']};
        line-height: ${FontSize['3Xl']};
      }
    }
    .terms-policy-section {
      top: 76%;
      .terms-policy {
        font-size: ${FontSize['Xl']};
        line-height: ${FontSize['2Xl']};
      }
    }
  }

  // pc and all
  @media (min-width: 1025px) {
    .guide-for-mobile-users {
      visibility: hidden;
    }
    .logo-product-screenplay {
      top: 40%;
      gap: 2rem;
      .logos {
        .logo-screenplay-mobile {
          display: none;
        }
        .logo-screenplay-tablet {
          display: none;
        }
        .logo-screenplay-pc {
          display: inline;
          height: auto;
        }
        .logo-cursor-mobile {
          display: none;
        }
        .logo-cursor-tablet {
          display: none;
        }
        .logo-cursor-pc {
          display: inline;
          margin-left: 1.8rem;
          animation: blink 1s infinite; /* 1초 간격으로 무한 반복 */
        }
      }
      .logo-open-beta {
        height: auto;
      }
      .text {
        font-size: ${FontSize['6Xl']};
      }
    }
    .web-site-back-link-section {
      top: 84%;
      a {
        font-size: ${FontSize['2Xl']};
        line-height: ${FontSize['3Xl']};
      }
    }
    .terms-policy-section {
      top: 90%;
      .terms-policy {
        font-size: ${FontSize['Xl']};
        line-height: ${FontSize['2Xl']};
      }
    }
  }

  // default css
  .guide-for-mobile-users {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    height: auto;
    .text {
      text-align: center;
      white-space: pre-wrap;
      line-height: 1.4rem;
      font-size: 1rem;
      background-color: black;
      color: #96ff00;
      padding: 0.8rem 0rem;
      height: auto;
    }
  }

  .logo-product-screenplay {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    gap: 3rem;
    display: flex;
    align-items: center;
    width: 100%;
    .logos {
      display: flex;
      align-items: center;
    }
    .logo-open-beta {
      width: 12.8rem;
    }
    .text {
      font-weight: 400;
    }
  }
  .web-site-back-link-section {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    width: 100%;
    a {
      color: ${White};
      text-decoration: underline;
    }
  }
  .terms-policy-section {
    position: absolute;
    left: 50%;
    min-width: 20rem;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    width: 80%;
    .terms-policy {
      text-align: center;
      color: ${WhiteGray};
      a {
        color: ${WhiteGray};
      }
    }
  }
`;
