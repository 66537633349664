import { Grey, Primary } from '@/styles/Colors';
import styled from '@emotion/styled';

const StyledLoading = styled.section`
  display: flex;
  flex: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 100%;
  > span {
    position: absolute;
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.625rem;
    top: calc(50% - 0.3125rem);
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    @keyframes changeGrey {
      from {
        left: calc(50% - 0.625rem);
      }
      to {
        left: calc(50% + 0.625rem);
      }
    }
    @keyframes changeBlue {
      from {
        left: calc(50% + 0.625rem);
      }
      to {
        left: calc(50% - 0.625rem);
      }
    }
    &.blue {
      background-color: ${Primary[200]};
      animation-name: changeBlue;
    }
    &.grey {
      background-color: ${Grey[200]};
      animation-name: changeGrey;
    }
  }
`;
export default StyledLoading;
