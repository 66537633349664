import classNames from 'classnames';
import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FontSizeRatio } from '../../styles/Typography';
import { calculateAdjustedPlacement, calculatePositions } from './helpers';
import { StyledTooltipContainer } from './StyledTooltip';
import TooltipArrow from './TooltipArrow';
import TooltipContent from './TooltipContent';

interface TooltipProps {
  // Tooltip content.
  content: ReactNode;
  // Where to place the tooltip initially.
  placement?: Placement;
  // Max width of the tooltip. (in pixels, 0 means no max width)
  maxWidth?: number;
  // Custom style for the tooltip.
  style?: CSSProperties;
  // Distance between the tooltip and the element.
  distance?: number;
  // Distance between the arrow and the corner of the tooltip.
  // This should be larger than the tooltip border radius.
  arrowBuffer?: number;
}

export type SimpleDOMRect = Pick<DOMRect, 'top' | 'left' | 'width' | 'height'>;
export type Placement = 'top' | 'bottom' | 'left' | 'right';

const Tooltip = ({
  content,
  placement = 'top',
  maxWidth = 0,
  style,
  distance = Math.round(8 * FontSizeRatio),
  arrowBuffer = Math.round(5 * FontSizeRatio),
  children,
}: PropsWithChildren<TooltipProps>) => {
  // Ref to the element that triggers tooltip.
  const elementRef = useRef<HTMLDivElement>(null);

  // Ref to the tooltip arrow.
  const arrowRef = useRef<HTMLDivElement>(null);

  // Ref to the tooltip content.
  const contentRef = useRef<HTMLDivElement>(null);

  // Bounding rect of the window.
  const [boundingRect, setBoundingRect] = useState<SimpleDOMRect | null>(null);

  // Bounding rect of the whole element.
  const [elementBoundingRect, setElementBoundingRect] =
    useState<DOMRect | null>(null);

  // Bounding rect of the tooltip content.
  const [contentBoundingRect, setContentBoundingRect] =
    useState<DOMRect | null>(null);

  // Adjusted placement of the tooltip.
  // (e.g. if the tooltip cannot be placed within the bounding rect,
  // it will be adjusted to the opposite side.)
  const [adjustedPlacement, setAdjustedPlacement] = useState<Placement | null>(
    null
  );

  // Whether the tooltip is active.
  const [isActive, setIsActive] = useState(false);

  // Sets the bounding rect of the tooltip content.
  useEffect(() => {
    if (!contentRef.current) return;

    setContentBoundingRect(contentRef.current.getBoundingClientRect());
  }, [content, maxWidth]);

  // Determine the actual placement of the tooltip.
  useEffect(() => {
    if (!boundingRect || !contentBoundingRect || !elementBoundingRect) return;

    // Calculates the adjusted placement.
    setAdjustedPlacement(
      calculateAdjustedPlacement(
        placement,
        boundingRect,
        contentBoundingRect,
        elementBoundingRect,

        distance,
        arrowBuffer
      )
    );
  }, [
    boundingRect,
    contentBoundingRect,
    elementBoundingRect,
    placement,
    distance,
    arrowBuffer,
  ]);

  // Sets the position of the tooltip.
  useEffect(() => {
    if (
      !adjustedPlacement ||
      !contentRef.current ||
      !arrowRef.current ||
      !boundingRect ||
      !elementBoundingRect ||
      !contentBoundingRect
    )
      return;

    // Calculates the positions of the tooltip and the arrow.
    const { arrowLeft, arrowTop, tooltipLeft, tooltipTop } = calculatePositions(
      adjustedPlacement,
      boundingRect,
      contentBoundingRect,
      elementBoundingRect,
      distance,
      arrowBuffer
    );

    switch (adjustedPlacement) {
      case 'top': {
        arrowRef.current.style.transform = `translate(${arrowLeft}px, ${Math.floor(
          arrowTop
        )}px)`;
        contentRef.current.style.transform = `translate(${tooltipLeft}px, ${Math.floor(
          tooltipTop
        )}px)`;

        break;
      }

      case 'right': {
        arrowRef.current.style.transform = `translate(${Math.floor(
          arrowLeft
        )}px, ${arrowTop}px)`;
        contentRef.current.style.transform = `translate(${Math.floor(
          tooltipLeft
        )}px, ${tooltipTop}px)`;

        break;
      }

      case 'bottom': {
        arrowRef.current.style.transform = `translate(${arrowLeft}px, ${Math.floor(
          arrowTop
        )}px)`;
        contentRef.current.style.transform = `translate(${tooltipLeft}px, ${Math.floor(
          tooltipTop
        )}px)`;

        break;
      }

      case 'left': {
        arrowRef.current.style.transform = `translate(${Math.floor(
          arrowLeft
        )}px, ${arrowTop}px)`;
        contentRef.current.style.transform = `translate(${Math.floor(
          tooltipLeft
        )}px, ${tooltipTop}px)`;

        break;
      }
    }
  }, [
    boundingRect,
    elementBoundingRect,
    contentBoundingRect,
    adjustedPlacement,
    distance,
    arrowBuffer,
  ]);

  // Shows the tooltip.
  const showTooltip = useCallback(() => {
    // Sets the bounding rect of the window.
    setBoundingRect({
      top: 0,
      left: 0,
      width: window.innerWidth,
      height: window.innerHeight,
    });

    if (!elementRef.current) return;

    const element = elementRef.current as HTMLElement;
    const child = element.firstChild;

    if (!child) throw new Error('Tooltip must have a child element.');

    const childRect = (child as HTMLElement).getBoundingClientRect();

    // Sets the bounding rect of the child element.
    setElementBoundingRect(childRect);

    setIsActive(true);
  }, []);

  // Hides the tooltip.
  const hideTooltip = useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <>
      <StyledTooltipContainer
        ref={elementRef}
        className={classNames(
          'sup-tooltip-container',
          `sup-tooltip-${placement}`
        )}
        onPointerEnter={showTooltip}
        onPointerLeave={hideTooltip}
        style={style}
      >
        {children}
      </StyledTooltipContainer>
      <TooltipContent
        ref={contentRef}
        isActive={isActive}
        placement={adjustedPlacement}
        maxWidth={maxWidth}
      >
        {content}
      </TooltipContent>
      <TooltipArrow
        ref={arrowRef}
        isActive={isActive}
        placement={adjustedPlacement}
      />
    </>
  );
};

export default Tooltip;
