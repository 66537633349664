import classNames from 'classnames';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';

import { FontSizeRatio } from '../../styles/Typography';
import { clamp } from '../../util/number';
import { StyledScrollbar } from './StyledScrollbar';

export interface ScrollbarProps {
  // Orientation of the scrollbar.
  orientation: 'horizontal' | 'vertical';
  // Size of the viewport to display the content. (in pixels)
  viewportSize: number;
  // Size of the content to scroll. (in pixels)
  contentSize: number;
  // Scroll position of the thumb. (in percentage)
  position?: number;
  // Padding around the thumb. (in pixels)
  padding?: number;
  // Indent at the end of the track. (in pixels)
  indentEnd?: number;
  // Thickness of the thumb. (in pixels)
  thickness?: number;
  // Minimum size of the thumb. (in pixels)
  minThumbSize?: number;
  // Position change callback. (in percentage)
  onChange: (position: number) => void;
  // Whether to hide the scrollbar when not scrolling.
  autoHide?: boolean;
  // Custom styles for the thumb.
  thumbStyles?: CSSProperties;
  // Custom styles for the track.
  trackStyles?: CSSProperties;
}

interface OrientationFields {
  mainDimension: keyof DOMRect;
  subDimension: keyof DOMRect;
  translateAxis: 'translateX' | 'translateY';
  startCoord: keyof DOMRect;
  endCoord: keyof DOMRect;
  clientAxis: keyof React.MouseEvent;
}

const Scrollbar = ({
  orientation,
  viewportSize,
  contentSize,
  position = 0,
  padding = 1,
  indentEnd = 0,
  thickness = Math.round(10 * FontSizeRatio),
  minThumbSize = 20,
  onChange,
  autoHide = true,
  thumbStyles,
  trackStyles,
}: ScrollbarProps) => {
  // Check for required props.
  if (!orientation) throw new Error('Scrollbar: `orientation` is required.');
  if (typeof viewportSize !== 'number')
    throw new Error('Scrollbar: `viewportSize` is required.');
  if (typeof contentSize !== 'number')
    throw new Error('Scrollbar: `contentSize` is required.');
  if (!onChange) throw new Error('Scrollbar: `onChange` is required.');

  const trackRef = useRef<HTMLDivElement>(null);
  const [trackSize, setTrackSize] = useState(0);

  // Get field names based on orientation.
  const {
    mainDimension,
    subDimension,
    translateAxis,
    startCoord,
    endCoord,
    clientAxis,
  }: OrientationFields =
    orientation === 'horizontal'
      ? {
          mainDimension: 'width',
          subDimension: 'height',
          translateAxis: 'translateX',
          startCoord: 'left',
          endCoord: 'right',
          clientAxis: 'clientX',
        }
      : {
          mainDimension: 'height',
          subDimension: 'width',
          translateAxis: 'translateY',
          startCoord: 'top',
          endCoord: 'bottom',
          clientAxis: 'clientY',
        };

  // Set the default opacity based on autoHide.
  useEffect(() => {
    if (!trackRef.current) return;

    trackRef.current.style.opacity = autoHide ? '0' : '1';
  }, [autoHide]);

  // Update track size on resize.
  useEffect(() => {
    const resize = () => {
      if (!trackRef.current) return;

      const size = trackRef.current.getBoundingClientRect()[
        mainDimension
      ] as number;
      setTrackSize(size + padding * 2);
    };

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [mainDimension, padding, contentSize]);

  // Calculate ratio of content size to viewport size.
  const ratio = useMemo(() => {
    return contentSize / viewportSize;
  }, [contentSize, viewportSize]);

  // Calculate thumb size in pixels.
  const thumbSizePx = useMemo(() => {
    const adjustedTrackSize = trackSize - padding * 2;
    return Math.min(
      Math.max(trackSize / ratio, minThumbSize),
      adjustedTrackSize
    );
  }, [trackSize, ratio, minThumbSize, padding]);

  // Drag handler for thumb.
  const onThumbDragStart = (e: React.PointerEvent) => {
    e.preventDefault();

    const target = e.currentTarget;

    // Capture pointer for thumb.
    target.setPointerCapture(e.pointerId);

    const cursorPosition = e[clientAxis];

    const onThumbDrag = (e: PointerEvent) => {
      const nextCursorPosition = e[clientAxis];

      // Calculate delta.
      const delta = nextCursorPosition - cursorPosition;

      // Calculate next position. (in percentage)
      const nextPosition = clamp(position + (delta / trackSize) * 100, 0, 100);

      if (nextPosition !== position) onChange(nextPosition);
    };

    const onThumbDragEnd = (e: PointerEvent) => {
      e.stopPropagation();

      // Capture click event in the capture phase to suppress click during drag.
      const captureClick = (e: Event) => {
        e.stopPropagation();

        window.removeEventListener('click', captureClick, true);
      };

      window.addEventListener('click', captureClick, true);

      // Release pointer for thumb.
      target.releasePointerCapture(e.pointerId);

      document.removeEventListener('pointermove', onThumbDrag);
      document.removeEventListener('pointerup', onThumbDragEnd);
    };

    document.addEventListener('pointermove', onThumbDrag);
    document.addEventListener('pointerup', onThumbDragEnd);
  };

  // Click handler for track.
  const onTrackClick = (e: React.MouseEvent) => {
    if (!trackRef.current) return;

    const cursorPosition = e[clientAxis];
    const trackRect = trackRef.current.getBoundingClientRect();
    const trackStart = trackRect[startCoord] as number;
    const trackEnd = trackRect[endCoord] as number;

    // Calculate next position. (in percentage)
    const nextPosition = clamp(
      ((cursorPosition - trackStart) / (trackEnd - trackStart)) * 100,
      0,
      100
    );

    if (nextPosition !== position) onChange(nextPosition);
  };

  // Show scrollbar on pointer enter.
  const onTrackEnter = () => {
    if (!trackRef.current) return;

    if (autoHide) {
      trackRef.current.style.opacity = '1';
    }
  };

  // Hide scrollbar on pointer leave.
  const onTrackLeave = () => {
    if (!trackRef.current) return;

    if (autoHide) {
      trackRef.current.style.opacity = '0';
    }
  };

  // Calculate position in pixels.
  const positionPx = useMemo(() => {
    const p = clamp(position, 0, 100);
    return clamp(
      (p / 100) * (trackSize - padding * 2) - thumbSizePx / 2,
      0,
      trackSize
    );
  }, [position, trackSize, thumbSizePx, padding]);

  return (
    <StyledScrollbar
      className={classNames('sup-scrollbar', `sup-scrollbar-${orientation}`)}
      style={{
        [mainDimension]: `calc(100% - ${indentEnd}px)`,
        [subDimension]: `${thickness + padding * 2}px`,
        padding,
      }}
    >
      <div
        ref={trackRef}
        className="sup-scrollbar-track"
        onClick={onTrackClick}
        onPointerEnter={onTrackEnter}
        onPointerLeave={onTrackLeave}
        style={{
          [mainDimension]: '100%',
          [subDimension]: `${thickness}px`,
          display: ratio <= 1 ? 'none' : 'block',
          ...trackStyles,
        }}
      >
        <div
          className="sup-scrollbar-thumb"
          onPointerDown={onThumbDragStart}
          style={{
            [mainDimension]: `${thumbSizePx}px`,
            [subDimension]: '100%',
            transform: `${translateAxis}(${positionPx}px)`,
            borderRadius: `${thickness / 2}px`,
            ...thumbStyles,
          }}
        />
      </div>
    </StyledScrollbar>
  );
};

export default Scrollbar;
