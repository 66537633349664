import classNames from 'classnames';
import { forwardRef } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as ArrowBottom } from './arrow/arrow-bottom.svg';
import { ReactComponent as ArrowLeft } from './arrow/arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow/arrow-right.svg';
import { ReactComponent as ArrowTop } from './arrow/arrow-top.svg';
import { StyledTooltipArrow } from './StyledTooltip';
import { Placement } from './Tooltip';

interface TooltipArrowProps {
  isActive: boolean;
  placement: Placement | null;
}

const TooltipArrow = forwardRef<HTMLDivElement, TooltipArrowProps>(
  ({ isActive, placement }, ref) => {
    return createPortal(
      <StyledTooltipArrow
        className={classNames(
          'sup-tooltip-arrow',
          isActive && 'sup-tooltip-arrow-active',
          !placement && 'sup-tooltip-arrow-hidden'
        )}
        ref={ref}
      >
        {placement === 'top' && <ArrowBottom />}
        {placement === 'right' && <ArrowLeft />}
        {placement === 'bottom' && <ArrowTop />}
        {placement === 'left' && <ArrowRight />}
      </StyledTooltipArrow>,
      document.getElementById('tooltip-portal') as HTMLElement
    );
  }
);

export default TooltipArrow;
