import PermissionError from '@/error/PermissionError';
import { WS_EVENTS, useWebSocketContext } from '@/providers/WebSocketProvider';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import {
  _addLine,
  _addTake,
  _deleteLines,
  _deleteTake,
  _loadLines,
  _loadProjects,
  _loadScenes,
  _loadTakes,
  _updateLines,
  _updateScene,
  _updateTake,
} from '../api/project';
import useAxios from '../hooks/useAxios';
import { useLog } from '../hooks/useLog/useLog';
import { VideoItem } from '../MediaPanel/MediaPanel';
import { audioFileMapAtom } from '../stores/atoms/audio';
import {
  lineListAtom,
  projectListAtom,
  sceneListAtom,
  selectedProjectIdAtom,
  takeListAtom,
} from '../stores/atoms/project';
import { selectedLineIdsAtom, showPayModal } from '../stores/atoms/ui';
import { projectVoiceProfileListAtom } from '../stores/atoms/voice';
import { DEFAULT_PARAMETER, Language } from '../stores/data/config';
import {
  Draft,
  ExtendParameter,
  ExtendTake,
  Line,
  Parameter,
  Project,
  Scene,
  Take,
} from '../stores/project';
import { useDataContext } from './DataContextProvider';

export interface TakeOption {
  type: 'cvc' | 'tts';
  text: string;
  voiceId: string;
  language: Language;
  parameter: {
    pitch_shift: number;
    pitch_variance: number;
    speed: number;
    age: number;
    gender: number;
  };
  style?: string;
  source_id?: string;
  resource_id?: string;
}
interface SceneEditorContextProps {
  projectId: string;
  projectInfo: Project;
  setProjectInfo: (projectId: string) => void;
  lineList: Line[];
  takeList: Take[];
  takeMap: Record<string, Take[]>;
  extendTakeLists: ExtendTake[];
  addLine: (line?: Line, notChangeActiveLine?: boolean) => Promise<Line>;
  addTakes: (options: TakeOption, num?: number) => void;
  regenerateTakes: (take: ExtendTake) => void;
  updateTake: (take: Take) => void;
  deleteTake: (id: string) => void;
  changeDefaultTake: (id: string) => void;
  updateDraft: (lineId: string, draft: Draft) => void;
  changeDraft: (lineId: string, draft: Draft) => void;
  loadTakeList: (id?: string) => void;
  loadLineList: (sceneId: string) => Promise<Line[]>;
  loadScene: (projectId: string) => void;
  addScene: (projectId?: string) => void;
  sceneList: Scene[];
  updateScene: (value: Scene) => void;
  getDefaultScene: (projectId?: string) => Promise<Scene>;
  toggleSelectedLineIds: (lineId: string) => void;
  resetSelectedLineIds: () => void;
  toggleAllLine: () => void;
  takeJobIdMap: Record<string, string>;
  deleteLines: () => void;
  usedProfileIdList: string[];
  selectedVideoId: string | null;
  setSelectedVideoId: (id: string) => void;
  videoList: VideoItem[];
  setVideoList: Dispatch<SetStateAction<VideoItem[]>>;
  controlState: Parameter;
  setControlState: Dispatch<SetStateAction<Parameter>>;
}
const SceneEditorContext = createContext<SceneEditorContextProps>(
  {} as SceneEditorContextProps
);

export const useSceneEditorContext = () => {
  const context = useContext(SceneEditorContext);
  if (!context) {
    throw new Error('SceneEditorContext not supported');
  }
  return context;
};

const SceneEditorContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    projectInfo,
    addScene,
    activeSceneId,
    activeLineId,
    updateActiveSceneId,
    updateActiveLineId,
    takeJobIdMap,
    updateTakeJobIdMap,
    updateLineIndex,
    updateActiveTakeId,
    updateLine,
  } = useDataContext();
  const [audioFileMap, setAudioFileMap] = useRecoilState(audioFileMapAtom);
  const { track } = useLog();
  const { getResourceInfo, createCvc, createTts } = useAxios();
  const [lineList, setLineList] = useRecoilState(lineListAtom);
  const [takeList, setTakeList] = useRecoilState(takeListAtom);
  const [sceneList, setSceneList] = useRecoilState(sceneListAtom);
  const [controlState, setControlState] =
    useState<Parameter>(DEFAULT_PARAMETER);
  const setProjectList = useSetRecoilState(projectListAtom);
  const setShowPayModal = useSetRecoilState(showPayModal);
  const setSelectedProjectId = useSetRecoilState(selectedProjectIdAtom);
  const setProjectInfo = useCallback(
    async (projectId: string) => {
      const list = (await _loadProjects()) as Project[];
      setSelectedProjectId(projectId);
      setProjectList(list);
    },
    [setProjectList, setSelectedProjectId]
  );

  const loadScene = useCallback(
    async (projectId: string) => {
      const result = (await _loadScenes(projectId)) as Scene[];
      if (!result.length) {
        addScene();
      } else {
        setSceneList(result);
        if (!activeSceneId && result[0].id) {
          updateActiveSceneId(result[0].id);
        }
      }
    },
    [addScene, activeSceneId, updateActiveSceneId, setSceneList]
  );

  const getDefaultScene = useCallback(
    async (projectId?: string) => {
      const result = (await _loadScenes(
        projectId ?? projectInfo.id
      )) as Scene[];
      return result[0] as Scene;
    },
    [projectInfo.id]
  );

  const updateScene = useCallback(
    async (value: Scene) => {
      const result = (await _updateScene(value as Scene)) as Scene;
      setSceneList((prev) => {
        return prev.map((scene) => {
          if (scene.id === value.id) {
            return result;
          }
          return scene;
        });
      });
    },
    [setSceneList]
  );

  const [selectedLineIds, setSelectedLineIds] =
    useRecoilState(selectedLineIdsAtom);

  const toggleSelectedLineIds = useCallback(
    (lineId: string) => {
      setSelectedLineIds((prev) => {
        if (prev.includes(lineId)) {
          return prev.filter((id) => id !== lineId);
        }
        return [...prev, lineId];
      });
    },
    [setSelectedLineIds]
  );
  const resetSelectedLineIds = useCallback(() => {
    setSelectedLineIds([]);
  }, [setSelectedLineIds]);

  const toggleAllLine = useCallback(() => {
    if (selectedLineIds.length === lineList.length) {
      setSelectedLineIds([]);
    } else {
      setSelectedLineIds(lineList.map((line) => line.id));
    }
  }, [selectedLineIds, lineList, setSelectedLineIds]);

  const takeMap = useMemo(() => {
    const map: Record<string, Take[]> = {};
    takeList.forEach((take) => {
      if (map[take.lineId]) {
        map[take.lineId].push(take);
      } else {
        map[take.lineId] = [take];
      }
    });
    return map;
  }, [takeList]);

  const changeDefaultTake = useCallback(
    async (id: string) => {
      const line = lineList.find((line) => line.id === activeLineId);
      if (line) {
        await updateLine({
          ...line,
          selectedTakeId: id,
        });
      }
    },
    [lineList, activeLineId, updateLine]
  );

  const projectVoiceProfileList = useRecoilValue(projectVoiceProfileListAtom);
  const defaultProfile = useMemo(() => {
    return projectVoiceProfileList?.find(
      (profile) => !!profile.default_voice_id
    );
  }, [projectVoiceProfileList]);
  const defaultVoice = useMemo(() => {
    return defaultProfile?.default_voice_id;
  }, [defaultProfile]);

  const createEmptyLine = useCallback(() => {
    const id = uuid();
    const newLine = {
      id: id,
      sceneId: activeSceneId as string,
      projectId: projectInfo.id,
      draft: {
        text: '',
        voiceId: defaultVoice,
        language: defaultProfile?.language,
        parameter: DEFAULT_PARAMETER,
        lineId: id,
      } as Draft,
    };
    return newLine;
  }, [activeSceneId, projectInfo.id, defaultVoice, defaultProfile]);

  const createLine = useCallback(
    async (line?: Line) => {
      // 기본값으로 비어있는 새라인 생성
      let newLine = createEmptyLine();
      if (line) {
        // 라인이 있다면 그 라인 정보를 바탕으로 기본 값을 바꿔준다.
        const lineInfo =
          line.draft ||
          takeList.find((take) => take.id === line?.selectedTakeId);
        newLine = {
          ...newLine,
          draft: {
            ...newLine.draft,
            ...{
              language: lineInfo?.language || defaultProfile?.language,
              voiceId: lineInfo?.voiceId || defaultVoice,
              parameter: lineInfo?.parameter || DEFAULT_PARAMETER,
            },
          },
        };
      }
      // indexed db에 라인을 넣어준다. (scene에 함꼐 추가된다)
      const addedLine = (await _addLine(newLine, line?.id)) as Line;
      setLineList((prev) => {
        const index = prev.findIndex((l) => l.id === line?.id);
        // 중심이 되는 라인 정보가 있다면, 중간에 추가한다.
        if (line && index > -1) {
          return [
            ...prev.slice(0, index + 1),
            addedLine,
            ...prev.slice(index + 1),
          ];
        }
        return [...prev, addedLine];
      });
      return newLine;
    },
    [defaultVoice, defaultProfile, setLineList, takeList, createEmptyLine]
  );

  const addLine = useCallback(
    async (line?: Line, notChangeActiveLine?: boolean) => {
      // 새로생성 standard가있는지
      const newLine = await createLine(line);
      if (!notChangeActiveLine) {
        track('ADD_NEW_LINE', {
          projectId: projectInfo.id,
          sceneId: activeSceneId,
          lineId: newLine.id,
        });
        updateActiveLineId(newLine.id);
      }
      return newLine;
    },
    [activeSceneId, projectInfo.id, updateActiveLineId, track, createLine]
  );

  const loadLineList = useCallback(
    async (sceneId: string) => {
      const result = (await _loadLines(sceneId)) || [];
      setLineList(result);
      return result as Line[];
    },
    [setLineList]
  );

  const changeDraft = useCallback(
    async (lineId: string, draft: Draft) => {
      const line = lineList.find((line) => line.id === lineId);
      const d = {
        ...line?.draft,
        ...draft,
      };
      await updateLine({ ...(line as Line), draft: d });
    },
    [lineList, updateLine]
  );

  const updateDraft = useCallback(
    async (lineId: string, draft: Draft) => {
      if (activeSceneId) {
        const lineList = (await loadLineList(
          activeSceneId as string
        )) as Line[];
        const line = lineList.find((line) => line.id === lineId);
        const d = {
          ...(line?.draft || {}),
          ...draft,
        };
        await updateLine({ ...(line as Line), draft: d });
      }
    },
    [loadLineList, activeSceneId, updateLine]
  );

  const { on, off, sessionId } = useWebSocketContext();

  const addTakes = useCallback(
    async (options: TakeOption, num?: number) => {
      // add takes by num
      const takes: Take[] = [];
      for (let i = 0; i < (num ?? 1); i++) {
        takes.push({
          id: uuid(),
          lineId: activeLineId as string,
          text: options.text as string,
          voiceId: options.voiceId as string,
          language: options.language,
          projectId: projectInfo.id,
          type: options.type,
          parameter: options.parameter,
          createdAt: new Date(),
          source_id: options.resource_id,
        });
      }
      // 나중에 Generate된 파일이 위로 올라간다.
      // fetching 을 시작하면서, takeList에 추가한다.
      let jobId = '';
      try {
        const res =
          options.type === 'tts'
            ? await createTts(
                {
                  text: options.text,
                  language: options.language,
                  voice_id: options.voiceId,
                  parameters: options.parameter,
                  take_count: num || 1,
                },
                sessionId
              )
            : await createCvc(
                {
                  voice_id: options.voiceId,
                  parameters: options.parameter,
                  source_resource_id: options.resource_id as string,
                },
                sessionId
              );
        const {
          data: {
            data: { job_id },
          },
        } = res as any;
        jobId = job_id;
      } catch (error) {
        if (error instanceof PermissionError) {
          setShowPayModal(true);
          return;
        }
      }
      // 추가된 테이크 값 설정
      setTakeList((prev) => [...prev, ...takes]);
      // 테이크 추가
      await _addTake(takes);
      // 테이크 추가 상태 제어 map 업데이트
      updateTakeJobIdMap(
        takes.reduce((acc, take) => ({ ...acc, [take.id]: jobId }), {})
      );
      const line = lineList.find((line) => line.id === activeLineId);
      updateLine({
        ...(line as Line),
        selectedTakeId: takes[0]?.id,
      });
    },
    [
      setTakeList,
      updateTakeJobIdMap,
      lineList,
      updateLine,
      activeLineId,
      projectInfo.id,
      createTts,
      sessionId,
      createCvc,
      setShowPayModal,
    ]
  );

  const updateTake = useCallback(
    async (take: Take) => {
      await _updateTake(take);
      setTakeList((prev) => {
        return prev.map((t) => {
          if (t.id === take.id) {
            return take;
          }
          return t;
        });
      });
      // position이 숫자 값이 들어오면 LINE의 INDEX를 업데이트한다.
      if (take.position !== undefined) {
        await updateLineIndex();
      }
    },
    [updateLineIndex, setTakeList]
  );

  const regenerateTakes = useCallback(
    async (take: ExtendTake) => {
      const res =
        take.type === 'cvc'
          ? await createCvc(
              {
                voice_id: take.voiceId,
                parameters: take.parameter as ExtendParameter,
                source_resource_id: take.resource_id as string,
              },
              sessionId
            )
          : await createTts(
              {
                text: take.text,
                language: take.language,
                voice_id: take.voiceId,
                parameters: take.parameter as Parameter,
                take_count: 1,
              },
              sessionId
            );
      const {
        data: {
          data: { job_id },
        },
      } = res as any;
      updateTakeJobIdMap({ [take.id]: job_id });
    },
    [sessionId, updateTakeJobIdMap, createCvc, createTts]
  );

  const extendTakeLists = useMemo(() => {
    return takeList.map((take) => {
      return {
        ...take,
        audioBuffer: audioFileMap[take.id]?.audioBuffer,
      };
    });
  }, [audioFileMap, takeList]);

  // update를 촉발시킨 take의 아이디가 있는지 확인한다.
  const updateLinePositions = useCallback(
    async (triggerTakeId?: string, triggerAudio?: AudioBuffer) => {
      // set Take positions by line arranges
      const lineList = (await _loadLines(activeSceneId)) as Line[];
      const takeList = (await _loadTakes(projectInfo.id)) as Take[];
      const triggerTake = takeList.find(({ id }) => id === triggerTakeId);
      const usedLines = lineList.filter((line) => !!line.selectedTakeId);
      const triggerIndex = usedLines.findIndex(
        (line) => line?.id === triggerTake?.lineId
      );
      const result = usedLines.reduce((lines, line, index) => {
        if (index < triggerIndex) {
          // trigger 인덱스가 있고, index 보다 작은 경우에는 업데이트 필요 없음
          lines.push(line as Line);
        } else {
          // trigger 인덱스가 있고, index 보다 크거나 같은 경우에는 업데이트 필요
          const prevLine = lines[index - 1];
          const prevTake = takeList.find(
            ({ id }) => prevLine?.selectedTakeId === id
          ) as Take;
          const duration =
            (prevTake?.id === triggerTakeId
              ? triggerAudio?.duration
              : (audioFileMap[prevTake?.id]?.audioBuffer as AudioBuffer)
                  ?.duration) || 0;
          const position = (prevLine?.position || 0) + duration;
          lines.push({
            ...line,
            position,
          } as Line);
        }
        return lines;
      }, [] as Line[]);
      const updateLineResult = await _updateLines(result);
      setLineList(updateLineResult as Line[]);
    },
    [audioFileMap, activeSceneId, projectInfo.id, setLineList]
  );

  const deleteLines = useCallback(async () => {
    if (selectedLineIds.includes(activeLineId as string)) {
      updateActiveLineId('');
    }
    resetSelectedLineIds();
    const result = await _deleteLines(selectedLineIds, activeSceneId);
    setLineList(result as Line[]);
    track('DELETE_LINES', {
      projectId: projectInfo.id,
      sceneId: activeSceneId,
      lineIds: selectedLineIds,
    });
  }, [
    selectedLineIds,
    activeSceneId,
    activeLineId,
    updateActiveLineId,
    resetSelectedLineIds,
    projectInfo.id,
    track,
    setLineList,
  ]);

  useEffect(() => {
    const complete = ({ job_id, resource_id, resource_ids }: any) => {
      // job_id로 들어온 값을 찾아서 해당 take를 업데이트한다.
      const ids = resource_ids ? resource_ids : [resource_id];
      // job_id에 속한 take들을 필터링 한다.
      const takes = takeList.filter((take) => takeJobIdMap[take.id] === job_id);
      if (takes.length) {
        // resource_id로 들어온 값을 찾아서 해당 resource를 가져와 audioBuffer를 만든다.
        let audio_length = 0;
        takes.forEach(async (take, index) => {
          getResourceInfo(ids[index]).then(async (res) => {
            const {
              data: {
                data: { transcoded },
              },
            } = res as any;
            const { arrayBuffer } = await fetchAudio(transcoded[0].url);
            const audioBuffer = await getAudioBuffer(arrayBuffer);
            if (!audioBuffer) return console.error('Failed to load resource');
            audio_length += audioBuffer.duration;
            setAudioFileMap((prev) => {
              return {
                ...prev,
                [take.id]: { audioBuffer, fileName: take.text },
              };
            });
            await updateTake({
              ...take,
              resource_id: ids[index],
            });
            updateTakeJobIdMap({ [take.id]: '' });
            // 새로운 오디오 audioBuffer가 들어오면, updateTakePosition 에선 이전 buffer들을 들고 있어서 position을 업데이트하기 위해 새 buffer를 넣어준다.
            await updateLinePositions(take.id, audioBuffer);
            if (index === takes.length - 1) {
              const profile = projectVoiceProfileList.find((profile) =>
                profile.voices.find((voice) => voice.id === take.voiceId)
              );
              track('ADD_NEW_TAKE', {
                lineId: take.lineId,
                sceneId: activeSceneId,
                projectId: take.projectId,
                text: take.text,
                voiceId: take.voiceId,
                language: take.language,
                type: take.type,
                voice_name: profile?.name,
                pitch_shift: take.parameter?.pitch_shift,
                pitch_variance: take.parameter?.pitch_variance,
                speed: take.parameter?.speed,
                audio_length,
                takeCount: takes.length,
                style: profile?.voices.find(
                  (voice) => voice.id === take.voiceId
                )?.style,
              });
            }
          });
        });
      }
    };
    on(WS_EVENTS.JOB_DONE, complete);
    return () => {
      off(WS_EVENTS.JOB_DONE, complete);
    };
  }, [
    activeSceneId,
    takeList,
    takeJobIdMap,
    updateTake,
    updateTakeJobIdMap,
    updateLinePositions,
    off,
    on,
    setAudioFileMap,
    getResourceInfo,
    projectVoiceProfileList,
    track,
    changeDraft,
  ]);

  const deleteTake = useCallback(
    (id: string) => {
      // 해당 테이크가 선택된 라인이 있다면 제거한다.
      const line = lineList.find((line) => line.id === activeLineId);
      // 잔여 테이크
      const takes = takeList.filter(
        (take) => take.lineId === activeLineId && take.id !== id
      );
      // take 필터링으로 테이크 제거, audio도 삭제해야함
      _deleteTake(id);
      setTakeList((prev) => prev.filter((take) => take.id !== id));
      if (line) {
        if (!takes.length) {
          // 만약 잔여 테이크가 없다면, selectedTakeId와 activeTakeId를 제거한다.
          const updateData = {
            ...line,
            selectedTakeId: '',
          };
          updateLine(updateData);
          updateActiveTakeId('');
        } else {
          // 만약 잔여 테이크가 있다면, 해당 Take가 라인의 기본 테이크 인지 판단 후 처리한다.
          if (line.selectedTakeId === id) {
            const updateData = {
              ...line,
              selectedTakeId: takes[0].id,
              draft: {
                text: takes[0].text,
                parameter: takes[0].parameter,
                language: takes[0].language,
                voiceId: takes[0].voiceId,
                source_id: takes[0].source_id,
              },
            };
            updateLine(updateData);
          }
        }
      }
    },
    [
      lineList,
      activeLineId,
      updateActiveTakeId,
      updateLine,
      takeList,
      setTakeList,
    ]
  );

  const loadTakeList = useCallback(
    async (id?: string) => {
      const result = (await _loadTakes(id || projectInfo.id)) || [];
      // result 중에 isFetching 이 true인 녀석이 있으면 tts or csc 타입에 따른 재생성 로직을 태운다.
      setTakeList(result);
    },
    [setTakeList, projectInfo.id]
  );

  const usedProfileIdList = useMemo(() => {
    if (!lineList?.length) return [];
    const takeIdToVoiceIdMap = new Map(
      takeList.map((take) => [take.id, take.voiceId])
    );
    const voiceIdToProfileIdMap = new Map();
    projectVoiceProfileList.forEach((profile) => {
      profile.voices.forEach((voice) => {
        voiceIdToProfileIdMap.set(voice.id, profile.id);
      });
    });
    const voiceIds = new Set<string>();
    lineList.forEach((line) => {
      if (line.selectedTakeId) {
        const voiceId = takeIdToVoiceIdMap.get(line.selectedTakeId);
        if (voiceId) {
          voiceIds.add(voiceId);
        }
      }
      if (line.draft?.voiceId) {
        voiceIds.add(line.draft.voiceId);
      }
    });
    return Array.from(voiceIds).reduce<string[]>((profileIds, voiceId) => {
      const profileId = voiceIdToProfileIdMap.get(voiceId);
      if (profileId) {
        profileIds.push(profileId);
      }
      return profileIds;
    }, []);
  }, [lineList, takeList, projectVoiceProfileList]);

  useEffect(() => {
    // todo 추후 한번에 데이터를 불러오는 api를 만든
    if (!!projectInfo.id && !!activeSceneId && !!projectInfo.updatedAt) {
      loadScene(projectInfo.id).then(() => {
        loadTakeList(projectInfo.id);
        loadLineList(activeSceneId);
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [projectInfo.id, activeSceneId, projectInfo.updatedAt]);

  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [videoList, setVideoList] = useState<VideoItem[]>([]);

  return (
    <SceneEditorContext.Provider
      value={{
        projectId: projectInfo.id,
        addLine,
        addTakes,
        regenerateTakes,
        deleteTake,
        projectInfo,
        setProjectInfo,
        lineList,
        takeList,
        extendTakeLists,
        takeMap,
        changeDefaultTake,
        updateTake,
        updateDraft,
        changeDraft,
        loadTakeList,
        loadLineList,
        loadScene,
        addScene,
        sceneList,
        updateScene,
        getDefaultScene,
        toggleSelectedLineIds,
        resetSelectedLineIds,
        toggleAllLine,
        takeJobIdMap,
        deleteLines,
        usedProfileIdList,
        selectedVideoId,
        setSelectedVideoId,
        videoList,
        setVideoList,
        controlState,
        setControlState,
      }}
    >
      {children}
    </SceneEditorContext.Provider>
  );
};
export default SceneEditorContextProvider;
