import { Dropdown, DropdownItem, DropdownList } from '@/components/Dropdown';
import { Black } from '@/styles/Colors';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LineToggleIconOver } from '../assets/icons/LineToggleIcon_over.svg';
import { Language, languageList } from '../stores/data/config';
import { StyledDropDownList } from './StyledTakeGeneratePanel';

const LanguageLabelMap: Record<Language, string> = {
  en: 'English',
  ko: 'Korean',
  ja: 'Japanese',
};
interface LanguageDropDownProps {
  value: Language;
  update: (lang: Language) => void;
}

const LanguageDropDown: React.FC<LanguageDropDownProps> = ({
  value,
  update,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  return (
    <section className="take-voice-setting-item language-setting">
      <span className="setting-title">{t('Language')}</span>
      <section className="dropdown-area">
        <Dropdown
          style={{
            width: '10.5rem',
            marginLeft: '0.25rem',
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: Black,
            borderColor: Black,
          }}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          preventRef={ref}
          closeOnClickOutside={true}
          placement="bottom-left"
          anchorElement={
            <section
              className="take-voice-setting-dropdown"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="drop-down-text">{LanguageLabelMap[value]}</span>
              <span className="toggle-icon">
                <LineToggleIconOver />
              </span>
            </section>
          }
        >
          <StyledDropDownList>
            <DropdownList className="sp-drawer">
              {languageList.map((v) => (
                <DropdownItem
                  key={`dropdown_list_${v}`}
                  label={LanguageLabelMap[v]}
                  isSelected={v === value}
                  onClick={() => {
                    update(v);
                    setIsOpen(false);
                  }}
                />
              ))}
            </DropdownList>
          </StyledDropDownList>
        </Dropdown>
      </section>
    </section>
  );
};
export default LanguageDropDown;
