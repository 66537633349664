import styled from '@emotion/styled';

import { Black, Grey, Primary } from '../../../styles/Colors';
import { FontSize, FontWeight } from '../../../styles/Typography';

export const StyledMediaPanel = styled.section`
  display: flex;
  flex-direction: column;
  .sp-media-player,
  .sp-media-library {
    padding: 1rem;
    h3 {
      margin-bottom: 1.5rem;
    }
  }
  .sp-media-player {
    border-bottom: 1px solid ${Grey[500]};
    display: flex;
    flex-direction: column;
    .video {
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      position: relative;
      &:has(p) {
        background: ${Primary[400]};
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25) inset;
      }
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: ${FontWeight.SemiBold};
        font-size: ${FontSize.Md};
        line-height: 1.5;
      }
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 2px solid ${Grey[700]};
      }
      &-progress {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: ${Primary[400]};
        z-index: 1;
      }
    }
    .controls {
      box-sizing: border-box;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      width: 22rem;
      height: 2.5rem;
      align-self: center;
      background-color: ${Black};
      border-radius: 0.75rem;
      border: 1px solid ${Grey[500]};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1.5rem;
      .input-file {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        color: ${Grey[50]};
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          opacity: 0.8;
        }
        &:has(input:disabled) {
          cursor: not-allowed;
          opacity: 0.5;
          svg {
            stroke: ${Grey[500]};
          }
        }
      }
      .sup-button-group {
        gap: 1rem;
        &:last-of-type {
          gap: 0.75rem;
        }
      }
      button {
        width: 1.5rem;
        height: 1.5rem;
        color: ${Grey[50]};
        &.btn-forward,
        &.btn-backward {
          svg {
            stroke: ${Grey[50]};
            path {
              stroke: ${Grey[50]};
            }
          }
        }
        &:disabled {
          cursor: not-allowed;
          color: ${Grey[500]};
          &.btn-forward,
          &.btn-backward {
            svg {
              stroke: ${Grey[500]};
              path {
                stroke: ${Grey[500]};
              }
            }
          }
        }
      }
    }
  }
  .sp-media-library {
    flex: 1;
    .file-list {
      background-color: ${Grey[800]};
      width: 100%;
      height: calc(100% - 3.2rem);
      &-header {
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid ${Grey[50]};
        span {
          color: ${Grey[400]};
          text-transform: capitalize;
        }
      }
      &-items {
        li {
          padding: 0.5rem 1rem;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid ${Grey[500]};
          color: ${Grey[600]};
          &.active {
            button {
              color: ${Grey[100]};
            }
          }
          button {
            background: none;
            border: none;
            outline: none;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: ${Grey[600]};
            cursor: pointer;
            .item-format {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
`;
