import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { useLayerPosition } from '../../hooks/useLayerPosition';
import { LayerPlacement } from '../../hooks/useLayerPosition/types';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { DEFAULT_DROPDOWN_PROPS } from './const';
import DropdownAnchor from './DropdownAnchor';
import DropdownLayer from './DropdownLayer';

interface DropdownProps
  extends Pick<CSSProperties, 'width' | 'minWidth' | 'maxHeight' | 'zIndex'> {
  isOpen?: boolean;
  onClose?: () => void;
  anchorElement?: ReactNode;
  offsetY?: number;
  placement?: LayerPlacement;
  closeOnClickOutside?: boolean;
  className?: string;
  style?: CSSProperties;
  preventRef?: React.RefObject<HTMLElement>;
}

const Dropdown = ({
  isOpen,
  onClose,
  anchorElement,
  width,
  minWidth,
  maxHeight,
  zIndex,
  offsetY = DEFAULT_DROPDOWN_PROPS.offsetY,
  placement = DEFAULT_DROPDOWN_PROPS.placement,
  closeOnClickOutside = DEFAULT_DROPDOWN_PROPS.closeOnClickOutside,
  children,
  className,
  style = {},
  preventRef,
}: PropsWithChildren<DropdownProps>) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const showDropdown = isOpen && isDropdownVisible;
  const { anchorRef, layerRef } = useLayerPosition<
    HTMLDivElement,
    HTMLDivElement
  >({
    placement,
    offsetY,
    isLayerVisible: showDropdown,
  });

  useEffect(() => {
    if (!anchorRef.current) return;

    const io = new IntersectionObserver(
      (entries) => {
        const isVisible = entries.every((entry) => entry.isIntersecting);
        setIsDropdownVisible(isVisible);
      },
      {
        threshold: 1,
      }
    );

    io.observe(anchorRef.current);
    return () => io.disconnect();
  }, [anchorRef]);

  useOnClickOutside(
    preventRef ? [preventRef, anchorRef, layerRef] : [anchorRef, layerRef],
    () => closeOnClickOutside && onClose?.()
  );

  return (
    <>
      <DropdownAnchor ref={anchorRef}>{anchorElement}</DropdownAnchor>
      <DropdownLayer
        ref={layerRef}
        style={{
          maxHeight,
          width: width === '100%' ? anchorRef?.current?.clientWidth : width,
          minWidth,
          zIndex,
          ...style,
        }}
        isOpen={showDropdown}
        className={className}
      >
        {children}
      </DropdownLayer>
    </>
  );
};

export default Dropdown;
