import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  _addLine,
  _addScene,
  _loadLines,
  _loadProjects,
  _loadScene,
  _loadScenes,
  _updateLine,
  _updateProject,
  _updateScene,
  initIndexedDB,
} from '../api/project';
import { useLog } from '../hooks/useLog/useLog';
import {
  activeLineIdAtom,
  activeSceneIdAtom,
  lineListAtom,
  projectListAtom,
  sceneListAtom,
  selectedProjectIdAtom,
  takeJobIdMapAtom,
  takeListAtom,
} from '../stores/atoms/project';
import {
  profileEditInfoListAtom,
  projectVoiceProfileListAtom,
  voiceProfileListAtom,
} from '../stores/atoms/voice';
import { DEFAULT_PARAMETER } from '../stores/data/config';
import { Draft, Line, Project, Scene } from '../stores/project';
import { Profile, ProfileEditInfo } from '../stores/voice';

interface DataContextProps {
  // globally shared state
  takeJobIdMap: Record<string, string>;
  updateTakeJobIdMap: (value: Record<string, string>) => void;
  projectInfo: Project;
  activeSceneId: string | null;
  updateActiveSceneId: (id: string | null) => void;
  activeLineId: string | null;
  updateActiveLineId: (id: string | null) => void;
  updateActiveTakeId: (id: string | null) => void;
  resetActiveInfo: () => void;
  addScene: (id?: string) => void;
  updateLineIndex: () => void;
  updateProjectInfo: (project: Project) => void;
  updateLine: (line: Line) => void;
}

const DataContext = createContext<DataContextProps>({} as DataContextProps);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('DataContextProvider not supported');
  }
  return context;
};

const DataContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isLoading, isAuthenticated } = useSUPAuth();

  const [isReady, setIsReady] = useState<boolean>(false);
  const [activeSceneId, setActiveSceneId] = useRecoilState(activeSceneIdAtom);
  const [projectList, setProjectList] = useRecoilState(projectListAtom);
  const [activeLineId, setActiveLineId] = useRecoilState(activeLineIdAtom);
  const selectedProjectId = useRecoilValue(selectedProjectIdAtom);
  const [takeJobIdMap, setTakeJobIdMap] = useRecoilState(takeJobIdMapAtom);
  const profileEditInfoList = useRecoilValue(profileEditInfoListAtom);
  const voiceProfileList = useRecoilValue(voiceProfileListAtom);
  const setProjectVoiceProfileList = useSetRecoilState(
    projectVoiceProfileListAtom
  );
  const takeList = useRecoilValue(takeListAtom);
  const lineList = useRecoilValue(lineListAtom);

  const { track } = useLog();

  const updateProjectInfo = useCallback(
    async (project: Project) => {
      // project info 업데이트
      await _updateProject(project);
      // update적용된 projectList를 다시 불러온다.
      const projectList = (await _loadProjects()) as Project[];
      setProjectList(projectList);
    },
    [setProjectList]
  );

  const updateActiveSceneId = useCallback(
    (id: string | null) => {
      setActiveSceneId(id);
    },
    [setActiveSceneId]
  );

  const updateActiveLineId = useCallback(
    (id: string | null) => {
      setActiveLineId(id);
    },
    [setActiveLineId]
  );

  const projectInfo = useMemo(() => {
    return (
      projectList.find((project) => project.id === selectedProjectId) ||
      ({} as Project)
    );
  }, [projectList, selectedProjectId]);

  const setLineList = useSetRecoilState(lineListAtom);

  const updateLine = useCallback(
    async (line: Line) => {
      const result = await _updateLine(line);
      setLineList((prev) => {
        return prev.map((l) => {
          if (l.id === line.id) {
            return result;
          }
          return l;
        }) as Line[];
      });
    },
    [setLineList]
  );

  const updateActiveTakeId = useCallback(
    async (id: string | null) => {
      if (id) {
        // 선택된 take
        const take = takeList.find((take) => take.id === id);
        if (take) {
          const draft = {
            text: take.type === 'tts' ? take.text : undefined,
            language: take.language,
            voiceId: take.voiceId,
            parameter: take.parameter,
            source_id: take.type === 'cvc' ? take.source_id : undefined,
          } as Draft;
          // 라인의 선택정보를 업데이트 한다.
          updateLine({
            ...(lineList.find((line) => line.id === take.lineId) as Line),
            draft,
            selectedTakeId: id,
          });
        }
      }
    },
    [takeList, lineList, updateLine]
  );

  const resetActiveInfo = useCallback(() => {
    setActiveSceneId('');
    setActiveLineId('');
  }, [setActiveSceneId, setActiveLineId]);

  const updateTakeJobIdMap = useCallback(
    (value: Record<string, string>) => {
      setTakeJobIdMap((prev) => {
        return {
          ...prev,
          ...value,
        };
      });
    },
    [setTakeJobIdMap]
  );

  const setSceneList = useSetRecoilState(sceneListAtom);
  const updateLineIndex = useCallback(async () => {
    const lineList = (await _loadLines(activeSceneId)) as Line[];
    const shallowLines = lineList.map((line) =>
      line.selectedTakeId ? undefined : line
    );
    const activatedLines = lineList.filter((line) => line.selectedTakeId);
    const sortedLines = activatedLines.sort((a, b) => {
      return (a?.position || 0) - (b?.position || 0);
    });
    let sortedLinesPointer = 0;
    const sortedLineList = shallowLines.map((line) => {
      if (line) {
        return line;
      } else {
        return sortedLines[sortedLinesPointer++];
      }
    });
    const scene = (await _loadScene(lineList[0].sceneId)) as Scene;
    // scene의 local lineIds와 sortedLineList의 id가 다르다면 scene을 업데이트한다.
    if (
      scene &&
      !scene.lineIds.every((id, index) => id === sortedLineList[index].id)
    ) {
      const updatedScene = await _updateScene({
        ...scene,
        lineIds: sortedLineList.map((line) => line.id),
      });
      if (updatedScene) {
        setSceneList((prev) => {
          return prev.map((scene) => {
            return scene.id === updatedScene.id ? updatedScene : scene;
          });
        });
      }
    }
  }, [activeSceneId, setSceneList]);

  const addScene = useCallback(
    async (projectId?: string) => {
      const result = await _addScene(projectId ?? selectedProjectId);
      const newLine = {
        sceneId: result?.id,
        projectId: projectId ?? selectedProjectId,
        draft: {
          text: '',
          parameter: DEFAULT_PARAMETER,
        } as Draft,
      };
      const line = (await _addLine(newLine)) as Line;
      const scenes = (await _loadScenes(
        projectId ?? selectedProjectId
      )) as Scene[];
      setSceneList(scenes);
      setLineList([line]);
      track('ADD_NEW_SCENE', {
        sceneId: result?.id,
        projectId: projectId ?? selectedProjectId,
      });
      if (result?.id && activeSceneId !== result.id) {
        updateActiveSceneId(result.id);
      }
    },
    [
      selectedProjectId,
      activeSceneId,
      updateActiveSceneId,
      track,
      setSceneList,
      setLineList,
    ]
  );

  useEffect(() => {
    if (!voiceProfileList.length || !profileEditInfoList.length) return;
    // profileEditInfoList에 있는 profile만 projectVoiceProfileList에 추가
    const editInfoMap = new Map<string, ProfileEditInfo>();
    for (const editInfo of profileEditInfoList) {
      editInfoMap.set(editInfo.profileId, editInfo);
    }

    const profileList: Profile[] = voiceProfileList
      .filter((profile) => editInfoMap.has(profile.id))
      .sort((a, b) => {
        const editTimeA = editInfoMap.get(a.id)?.createdAt || '';
        const editTimeB = editInfoMap.get(b.id)?.createdAt || '';
        return editTimeA > editTimeB ? 1 : -1;
      });
    setProjectVoiceProfileList(profileList);
  }, [voiceProfileList, profileEditInfoList, setProjectVoiceProfileList]);

  useEffect(() => {
    if (isLoading && !isAuthenticated) {
      setIsReady(true);
      return;
    }
    if (user?.email) {
      initIndexedDB(user.email).then(() => {
        setIsReady(true);
      });
    }
  }, [user?.email, isLoading, isAuthenticated]);

  if (!isReady) {
    return <></>;
  }

  return (
    <DataContext.Provider
      value={{
        // global state
        takeJobIdMap,
        updateTakeJobIdMap,
        projectInfo,
        activeSceneId,
        activeLineId,
        updateActiveSceneId,
        updateActiveLineId,
        updateActiveTakeId,
        resetActiveInfo,
        addScene,
        updateLineIndex,
        updateProjectInfo,
        updateLine,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataContextProvider;
