import Loading from '@/components/Loading/Loading';
import StyledAfterSignUp from '@/layout/Auth/StyledAfterSignUp';
import useAxios from '@/pages/screenplay/hooks/useAxios';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const AfterSignUp = () => {
  const { checkTrial } = useAxios();
  const { track } = useLog();
  const { isLoading, isAuthenticated } = useSUPAuth();
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      checkTrial()
        .then((result) => {
          if (result.data.isTrialStarted) {
            track('REGISTER_USER', {});
          }
          setIsRedirect(true);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);
  return isRedirect ? (
    <Navigate to="/" />
  ) : (
    <StyledAfterSignUp>
      <div className="layout">
        <Loading />
      </div>
    </StyledAfterSignUp>
  );
};

export default AfterSignUp;
