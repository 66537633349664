import classNames from 'classnames';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { clamp } from '../../util/number';
import { isNumberString } from '../../util/string';
import { ReactComponent as AddIcon } from '../assets/icons/AddIcon.svg';
import { ReactComponent as RemoveIcon } from '../assets/icons/RemoveIcon.svg';
import { STEPPER_MIN } from './const';
import { STEPPER_MAX } from './const';
import { StyledStepper } from './StyledStepper';

interface StepperProps {
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  disabled?: boolean;
  className?: string;
  onChange?: (value: number) => void;
}

const Stepper = ({
  min = STEPPER_MIN,
  max = STEPPER_MAX,
  value = 0,
  step = 1,
  disabled = false,
  className = '',
  onChange,
}: StepperProps) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());

  const updateInputValue = useCallback(
    (val: number | string, step = 0) => {
      let newValue = (typeof val === 'string' ? parseFloat(val) : val) + step;
      if (isNaN(newValue)) {
        setInputValue(value.toString());
        return;
      }
      // step의 소수점 자리수에 따라서 소수점 자리수를 맞춰준다.
      const stepDecimalLength = step.toString().split('.')[1]?.length || 0;
      newValue = parseFloat(
        clamp(newValue, min, max).toFixed(stepDecimalLength)
      );
      setInputValue(newValue.toString());
      onChange?.(newValue);
    },
    [min, max, value, onChange]
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (!isNumberString(val)) return;
    setInputValue(val);
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        updateInputValue(inputValue);
      }
    },
    [inputValue, updateInputValue]
  );

  const handleBlur = useCallback(() => {
    updateInputValue(inputValue);
  }, [inputValue, updateInputValue]);

  useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  return (
    <StyledStepper className={classNames('sup-stepper', className)}>
      <button
        className="sup-stepper-button"
        onPointerDown={() => updateInputValue(inputValue, -step)}
        disabled={disabled}
      >
        <RemoveIcon />
      </button>
      <input
        className="sup-stepper-input"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <button
        className="sup-stepper-button"
        onPointerDown={() => updateInputValue(inputValue, step)}
        disabled={disabled}
      >
        <AddIcon />
      </button>
    </StyledStepper>
  );
};

export default Stepper;
