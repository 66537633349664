import { ReactComponent as RecordIcon } from '@/components/assets/icons/RecordIcon.svg';
import { ReactComponent as StopIcon } from '@/components/assets/icons/RecordStopIcon.svg';
import Button from '@/components/Button/Button';
import { Grey, Secondary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import { audioBufferToWav, getAudioBuffer } from '@/util/audio';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const recordStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: Secondary[300],
  borderColor: Secondary[300],
  borderRadius: '0.25rem',
  fontSize: FontSize['Lg'],
  lineHeight: '150%',
  padding: '0.25rem 0.5rem',
  cursor: 'pointer',
  color: Grey[50],
  fontWeight: FontWeight['SemiBold'],
  minWidth: '5rem',
  justifyContent: 'space-around',
};
const simpleRecordStyle = {
  ...recordStyle,
  padding: '0.175rem 0.25rem',
  minWidth: '1.5rem',
};

export interface Recorder {
  start: () => Promise<void>;
  stop: () => Promise<Blob>;
}
export const recordAudio = () =>
  new Promise<Recorder>((resolve, reject) => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            autoGainControl: false,
            noiseSuppression: false,
          },
        });

        if (!MediaRecorder || typeof MediaRecorder !== 'function') {
          reject(new Error('MediaRecorder is not supported in this browser.'));
          return;
        }

        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: BlobPart[] = [];

        mediaRecorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data);
        });

        const start = () =>
          new Promise<void>((resolve) => {
            mediaRecorder.addEventListener('start', () => {
              resolve();
            });
            mediaRecorder.start();
          });

        const stop = () =>
          new Promise<Blob>((resolve) => {
            mediaRecorder.addEventListener('stop', async () => {
              resolve(new Blob(audioChunks));
            });

            mediaRecorder.stop();
          });

        resolve({ start, stop });
      } catch (error) {
        reject(
          new Error(
            'Error accessing the microphone: ' + (error as Error).message
          )
        );
      }
    })();
  });

const getDigitNumber = (num: number) => {
  return num >= 10 ? num : '0' + num;
};

const getFileDate = () => {
  let now = new Date();
  let year = now.getFullYear();
  let month = getDigitNumber(now.getMonth() + 1);
  let date = getDigitNumber(now.getDate());
  let hour = getDigitNumber(now.getHours());
  let minute = getDigitNumber(now.getMinutes());
  let second = getDigitNumber(now.getSeconds());
  let apm = now.getHours() >= 12 ? 'PM' : 'AM';
  return `${year}-${month}-${date} ${hour}:${minute}:${second} ${apm}`;
};

interface RecordProps {
  addFiles: (files: File[]) => void;
  captions?: string[];
  defaultName?: string;
  className?: string;
  useCountDown?: boolean;
  countDownNumber?: number;
  usePrompt?: boolean;
  onStartRecording?: () => void;
  onStopRecording?: () => void;
}

const DEFAULT_COUNT_DOWN = 3;
const Record: React.FC<RecordProps> = ({
  addFiles,
  captions,
  className,
  defaultName = 'Recorded',
  useCountDown = false,
  countDownNumber = DEFAULT_COUNT_DOWN,
  usePrompt = true,
  onStartRecording,
  onStopRecording,
}) => {
  const [isRecording, setRecording] = useState<boolean | undefined>();
  const [recorder, setRecorder] = useState<Recorder | undefined>();
  const [countDown, setCountDown] = useState<number | null>(null);
  const onClick = useCallback(() => {
    let start = countDownNumber;
    if (useCountDown && !isRecording) {
      setCountDown(start);
      const timer = setInterval(() => {
        setCountDown(--start);
        if (start === 0) clearInterval(timer);
      }, 1000);
    } else {
      setRecording((pre) => !pre);
    }
  }, [isRecording, useCountDown, countDownNumber, setRecording, setCountDown]);

  useEffect(() => {
    if (useCountDown && countDown === 0) {
      setRecording(true);
      setCountDown(null);
    }
  }, [useCountDown, countDown, setRecording]);

  const isCountingDown = useMemo(
    () => useCountDown && countDown !== null,
    [useCountDown, countDown]
  );

  useEffect(() => {
    if (isRecording && !recorder) {
      recordAudio().then((recorder) => {
        setRecorder(recorder);
        recorder.start();
        onStartRecording?.();
      });
    } else if (isRecording === false) {
      recorder?.stop().then(async (blob) => {
        // todo 나중에 Modal 팝업으로 변경
        const recordFileName = `${defaultName}_${getFileDate()}`;
        let name;
        if (usePrompt) {
          name = prompt('Enter file name', recordFileName);
        } else {
          name = recordFileName;
        }
        const audioBuffer = await getAudioBuffer(
          await new Response(blob).arrayBuffer()
        );
        addFiles([
          new File(
            [audioBufferToWav(audioBuffer as AudioBuffer)],
            `${name}.wav`,
            { type: 'audio/wav' }
          ),
        ]);
        setRecorder(undefined);
        onStopRecording?.();
      });
    }
  }, [
    isRecording,
    addFiles,
    recorder,
    defaultName,
    usePrompt,
    onStartRecording,
    onStopRecording,
  ]);

  return (
    <Button
      style={captions ? recordStyle : simpleRecordStyle}
      onClick={onClick}
      startIcon={
        captions &&
        !isCountingDown &&
        (isRecording ? <StopIcon /> : <RecordIcon />)
      }
      className={classNames(
        'file-record-button',
        isCountingDown && 'counting-down' && isRecording && 'recording',
        className
      )}
    >
      {isCountingDown ? (
        countDown
      ) : captions ? (
        isRecording ? (
          captions[1]
        ) : (
          captions[0]
        )
      ) : (
        <RecordIcon />
      )}
    </Button>
  );
};
export default Record;
