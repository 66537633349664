import styled from '@emotion/styled';

import { Grey, White } from '../../styles/Colors';
import { FontSize } from '../../styles/Typography';

export const StyledTooltipContainer = styled.span``;

export const StyledTooltipContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms;

  &.sup-tooltip-active {
    opacity: 1;
  }

  &.sup-tooltip-hidden {
    visibility: hidden;
  }

  .sup-tooltip-content {
    position: relative;
    background-color: ${White};
    color: ${Grey[800]};
    padding: 0.26rem 0.75rem;
    border-radius: 0.25rem;
    font-size: ${FontSize.Md};
    line-height: 150%;
    white-space: nowrap;
    box-sizing: border-box;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.64);
  }
`;

export const StyledTooltipArrow = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 11;
  opacity: 0;
  transition: opacity 200ms;

  &.sup-tooltip-arrow-active {
    opacity: 1;
  }

  &.sup-tooltip-arrow-hidden {
    visibility: hidden;
  }

  > svg {
    display: block;
  }
`;
