const Error = () => {
  // fixme error report page
  return (
    <div className="error">
      <h1>Something went wrong.</h1>
      <p>Please try again later.</p>
    </div>
  );
};
export default Error;
