import classNames from 'classnames';
import { CSSProperties, HTMLAttributes, PropsWithChildren } from 'react';

import { Black } from '../../styles/Colors';
import { StyledCardUI } from './StyledCardUI';

export interface CardUIProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<
      CSSProperties,
      | 'padding'
      | 'backgroundColor'
      | 'justifyContent'
      | 'alignItems'
      | 'alignContent'
      | 'flexDirection'
      | 'gap'
    > {}

const CardUI = ({
  padding = '0.5rem',
  backgroundColor = Black,
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  alignContent = 'flex-start',
  flexDirection = 'column',
  gap = '0',
  className = '',
  children,
  ...rest
}: PropsWithChildren<CardUIProps>) => {
  return (
    <StyledCardUI
      className={classNames('sup-card', className)}
      padding={padding}
      backgroundColor={backgroundColor}
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignContent={alignContent}
      flexDirection={flexDirection}
      gap={gap}
      {...rest}
    >
      {children}
    </StyledCardUI>
  );
};

export default CardUI;
