import { Grey, Primary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledZeroShotSourcePanel = styled.section`
  position: relative;
  .zero-shot-source-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0.625rem;
    align-items: center;
    .attach-button-primary {
      background-color: ${Primary[400]};
      border: 1px solid ${Primary[400]};
    }
  }
  .zero-shot-source-toast {
    position: absolute;
    bottom: 4.4rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.25rem;
    border: 1px solid ${Grey[200]};
    background: rgba(27, 27, 27, 0.8);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    padding: 1.5rem 1.75rem;
    min-width: 24rem;
    color: ${Grey[200]};
    opacity: 1;
    &.hide {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;
    }
    p {
      line-height: 1.5;
      font-size: ${FontSize['Md']};
      font-weight: ${FontWeight['Regular']};
    }
  }
`;
export default StyledZeroShotSourcePanel;
