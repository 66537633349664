import { Black, Grey, Primary, Secondary } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import LineIcon from '../assets/icons/LineIcon_hover.svg';
import TakeRecordIcon from '../assets/icons/TakeRecordIcon.svg';
import TakeSelectedIcon from '../assets/icons/TakeSelectedIcon.svg';
import TakeTreeIcon from '../assets/icons/TakeTreeIcon.svg';
import { HEADER_HEIGHT } from '../stores/atoms/ui';

const StyledSceneWriterPanel = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: calc(100% - 42rem);
  background-color: ${Black};
  &.media-active {
    width: calc(100% - 48rem);
  }
  .sp-scene-writer {
    width: 100%;
  }
  &.line-active {
    width: calc(100% - 42rem);
    &.media-active {
      width: calc(100% - 68rem);
    }
    .sp-scene-writer {
      width: 72rem;
    }
  }
  .button-reset {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
  }
  .sp-scene-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - ${HEADER_HEIGHT}rem);
  }
  .sp-scene-content-list {
    height: calc(100%);
    overflow-y: auto;
    padding-bottom: 12rem;
  }
  .sp-scene-title {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    .sp-scene-control {
      display: flex;
      gap: 1rem;
      .btn-control {
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;
        padding: 0.5rem 0.75rem;
        border-radius: 2px;
        line-height: 1.25rem;
        font-size: 1rem;
        color: ${Grey[450]};
        svg {
          width: 1.25rem;
          height: 1.25rem;
          path {
            fill: currentColor;
          }
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      .btn-select-all {
        padding: 0;
        gap: 0.5rem;
        &.active {
          color: ${Grey[200]};
          svg {
            color: ${Grey[800]};
            background-color: ${Grey[200]};
          }
        }
        &:hover {
          color: ${Grey[200]};
        }
      }
      .space {
        flex: 1;
      }
      .btn-download {
        color: ${Grey[50]};
        background-color: ${Primary[400]};
        &:hover {
          color: ${Grey[200]};
          background-color: ${Primary[500]};
        }
        &:disabled {
          color: ${Grey[450]};
          background-color: ${Grey[700]};
        }
      }
      .btn-delete {
        color: ${Grey[800]};
        background-color: ${Grey[150]};
        &:hover {
          color: ${Grey[700]};
          background-color: ${Grey[300]};
        }
        &:disabled {
          color: ${Grey[450]};
          background-color: ${Grey[700]};
        }
      }
    }
    .line-add {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      margin-left: -0.875rem;
      bottom: -1rem;
      .add {
        cursor: pointer;
        padding: 0;
        border: 0;
        outline: 0;
        width: 1.75rem;
        height: 1.75rem;
        background-color: transparent;
        color: ${Grey[500]};
        &:hover {
          color: ${Grey[50]};
        }
      }
    }
  }
  .scene-line-item {
    .fetching-status {
      display: none;
      .fetch-take {
        background-color: ${Grey[600]};
        border-radius: 1.875rem;
        height: 1rem;
        &.take-skull {
          width: 5.3125rem;
        }
        &.voice-skull {
          width: 2rem;
          height: 2rem;
        }
        &.locale-skull {
          width: 5.6875rem;
        }
        &.content-skull {
          flex: 1;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .loader {
            position: relative;
          }
          .loader-text {
            margin-left: 3rem;
          }
        }
        &.control-skull {
          width: 2.1875rem;
        }
      }
    }
    .fetching {
      position: relative;
      .line-item.take {
        &.selected {
          background-color: transparent;
        }
        * {
          visibility: hidden;
        }
      }
      .fetching-status {
        visibility: visible !important;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-bottom: 1px solid ${Grey[600]};
      }
    }
    &.single-take {
      .selected-option {
        visibility: hidden;
      }
    }
    &.playing {
      .line-item {
        .voice-image {
          width: calc(2rem - 4px) !important;
          height: calc(2rem - 4px) !important;
          border: 2px solid ${Primary[300]};
        }
        .line-text {
          .content-editor {
            color: ${Primary[300]};
          }
        }
      }
    }
    .line-item {
      display: flex;
      width: 100%;
      height: auto;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 2rem;
      justify-content: space-between;
      border-bottom: 1px solid ${Grey[600]};
      &:hover {
        background-color: ${Grey[800]};
        .line-control {
          visibility: visible;
        }
        .line-controlled {
          display: none;
        }
      }
      &.selected {
        background-color: ${Grey[600]};
      }
      &.line {
        align-items: flex-start;
        height: auto;
        .line-content {
          align-items: flex-start;
          width: 100%;
          .line-number {
            height: 2rem;
          }
        }
        .line-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 2.5rem;
          .content-editor {
            &:empty::before {
              content: attr(data-placeholder);
              color: ${Grey[400]};
            }
            border: 0;
            outline: 0;
            overflow: visible;
          }
        }
        &.selected {
          .line-control {
            visibility: visible;
          }
        }
        .content-editor {
          margin-right: 2rem;
          &.empty {
            color: ${Grey[400]};
          }
        }
        .line-add {
          display: none;
          position: absolute;
          left: 50%;
          margin-left: -0.875rem;
          bottom: -1rem;
          .add {
            cursor: pointer;
            padding: 0;
            border: 0;
            outline: 0;
            width: 1.75rem;
            height: 1.75rem;
            background-color: transparent;
            color: ${Grey[500]};
            &:hover {
              color: ${Grey[50]};
            }
          }
        }
        &:hover {
          position: relative;
          .line-add {
            display: inline-block;
          }
        }
      }
      .line-content {
        display: flex;
        align-items: center;
        .line-left-content {
          display: inline-flex;
          align-items: center;
          height: 2.5rem;
          gap: 0.65rem;
          margin-right: 1rem;
        }
      }
      .line-right-content {
        display: inline-flex;
        align-items: center;
      }
      .line-number {
        display: inline-flex;
        width: 5.125rem;
        align-items: center;
        font-size: 1rem;
        font-weight: ${FontWeight['SemiBold']};
        ::before {
          content: '';
          display: inline-block;
          width: 1rem;
          height: 2.5rem;
          margin-right: 0.5rem;
          background-image: url(${LineIcon});
          background-size: cover;
        }
        .btn-dropdown {
          margin-left: 0.5rem;
          width: 1.25rem;
          height: 2.5rem;
        }
      }
      .line-voice {
        width: 4.25rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .voice-profile {
          width: 2rem;
          height: 2rem;
          font-size: 0;
          .voice-image {
            width: 2rem;
            height: 2rem;
          }
          img {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      .line-lang {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-decoration: underline;
        font-weight: ${FontWeight['SemiBold']};
      }
      .line-text {
        flex: 1;
      }
      .wave-form {
        flex: 1;
        height: 2.5rem;
      }
      .line-control {
        width: 6.5rem;
        display: flex;
        visibility: hidden;
        align-items: center;
        &.line {
          &.play-hidden {
            .btn-line-play {
              visibility: hidden;
            }
          }
        }
      }
      .line-controlled {
        width: 8.25rem;
        display: inline-flex;
        align-items: center;
        &.voice-direct {
          white-space: nowrap;
          font-size: 1rem;
        }
        .icon-checked {
          width: 2.5rem;
          height: 2.5rem;
          background-image: url(${TakeSelectedIcon});
          background-size: cover;
        }
        .txt-selected {
          color: ${Primary[400]};
          font-weight: ${FontWeight['SemiBold']};
          font-size: 1.125rem;
        }
        .icon-record {
          width: 1.25rem;
          height: 1.25rem;
          background-image: url(${TakeRecordIcon});
          background-size: cover;
        }
        .txt-recorded {
          color: ${Secondary[200]};
          font-weight: ${FontWeight['SemiBold']};
          font-size: 1.125rem;
          margin-right: 0.5rem;
        }
      }
      .btn-line-control-icon {
        background-color: transparent;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        border: none;
        > svg {
          overflow: visible;
        }
      }
      .btn-play {
        &.disabled {
          visibility: hidden;
        }
      }
      .btn-direct-with-audio {
        &.disabled {
          visibility: hidden;
        }
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        & > svg {
          width: 2rem;
          height: 2rem;
        }
        margin-left: 0.5rem;
        color: ${Primary[400]};
        &:hover {
          color: ${Primary[200]};
        }
      }
      .btn-line-play,
      .btn-line-stop {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.75rem;
        height: 2.5rem;
        box-sizing: border-box;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
      .btn-line-play {
        color: transparent;
        &:hover {
          color: ${Grey[200]};
        }
      }
      .btn-line-dropdown {
        width: 2.75rem;
        height: 2.5rem;
      }
      .btn-none-fill {
        padding: 0 !important;
        svg * {
          fill: none;
        }
      }
      &.take {
        padding-top: 0;
        padding-bottom: 0;
        .line-content {
          width: calc(100% - 15rem);
          ::before {
            content: '';
            display: inline-block;
            width: 0.75rem;
            height: 2.5rem;
            overflow: hidden;
            flex-shrink: 0;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            background-image: url(${TakeTreeIcon});
            background-size: cover;
          }
        }
        .line-number {
          width: 7rem;
          color: ${Grey[200]};
          ::before {
            display: none;
          }
        }
        .selected-option {
          width: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
          .btn-option {
            width: 4rem;
            height: 4rem;
            border: 0;
            border-radius: 0;
            background-color: ${Primary[800]};
            &.selected {
              background-color: ${Primary[500]};
            }
          }
        }
        .line-control,
        .line-controlled {
          padding-left: 1.95rem;
          padding-right: 1.95rem;
        }
        .line-control {
          gap: 1rem;
          .btn-line-control-icon {
            width: 1.75rem;
            height: 1.75rem;
          }
        }
        .item-right-content {
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
          width: 15rem;
        }

        &.selected {
          .line-control {
            visibility: visible;
          }
          .line-controlled {
            display: none;
          }
        }
        .btn-line-control-icon {
          border: 0;
          outline: 0;
          padding: 0;
          width: 1.75rem;
          height: 1.75rem;
          cursor: pointer;
          > svg {
            width: 1.75rem;
            height: 1.75rem;
          }
        }
        .btn-regenerate {
          color: ${Grey[50]};
          background-color: transparent;
          border: 1px solid ${Grey[50]};
          overflow: hidden;
          border-radius: 50%;
          > svg {
            width: calc(1.75rem - 2px);
            height: calc(1.75rem - 2px);
          }
          &:hover {
            background-color: ${Grey[50]};
            border: 1px solid ${Black};
            color: ${Black};
          }
        }
        .btn-delete {
          color: ${Grey[50]};
          background-color: transparent;
          border: 1px solid transparent;
          overflow: hidden;
          border-radius: 50%;
          > svg {
            width: calc(1.75rem - 2px);
            height: calc(1.75rem - 2px);
          }
          &:hover {
            border: 1px solid transparent;
            color: ${Black};
          }
          &.disabled {
            visibility: hidden;
          }
        }
      }
    }
  }
`;
export default StyledSceneWriterPanel;
