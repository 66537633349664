import FileUploadIcon from '@/components/assets/icons/FileUploadIcon.svg';
import { Grey, Primary } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledDragZone = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  position: relative;
  .dropzone-text {
    display: none;
    color: ${Primary[400]};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-weight: ${FontWeight['SemiBold']};
    margin: 0;
    ::before {
      content: ' ';
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.38rem;
      background: url(${FileUploadIcon}) no-repeat;
    }
  }
  &.selected .dropzone-text {
    display: inline-block;
  }
  &.selected::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${Grey[100]};
    border: 2px dotted ${Primary[400]};
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }
`;
export default StyledDragZone;
