import { Grey } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface StyledInputLabelProps {
  isSimple?: boolean;
}

const StyledInputLabel = styled.label(
  ({ isSimple }: StyledInputLabelProps) => css`
    display: inline-flex;
    align-items: center;
    background-color: ${Grey[500]};
    border: 1px solid ${Grey[500]};
    border-radius: 0.25rem;
    font-size: ${FontSize['Lg']};
    line-height: 150%;
    padding: ${isSimple ? '0.25rem 0.5rem' : '0.25rem'};
    cursor: pointer;
    color: ${Grey[50]};
    gap: 0.5rem;
    font-weight: ${FontWeight['SemiBold']};
    ~ input {
      visibility: hidden;
      position: absolute;
      text-indent: -1000rem;
    }
  `
);
export { StyledInputLabel };
