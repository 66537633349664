import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { useDataContext } from '../providers/DataContextProvider';
import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import { projectVoiceProfileListAtom } from '../stores/atoms/voice';
import { Language } from '../VoiceLibrary/types';

export const useGenerateInfo = () => {
  const { activeLineId, projectInfo } = useDataContext();
  const { lineList, takeList, controlState } = useSceneEditorContext();

  const selectedLine = useMemo(
    () => lineList.find((line) => line.id === activeLineId),
    [lineList, activeLineId]
  );

  // 현재 전역으로 Active된 Take
  // 기존에 Active와 Selected가 구분되었으나 기능이 합병됨
  const activeTake = useMemo(() => {
    if (selectedLine?.selectedTakeId) {
      return takeList.find((take) => take.id === selectedLine?.selectedTakeId);
    }
  }, [takeList, selectedLine]);

  // todo 필요정보만 상위로 이동 필요
  const projectVoiceProfileList = useRecoilValue(projectVoiceProfileListAtom);

  // 기본 보이스 default_voice_id 필드는 필수로 내려오는 값으로 안내려 올 경우 서버에 문의
  const defaultVoice = useMemo(() => {
    return projectVoiceProfileList[0].default_voice_id;
  }, [projectVoiceProfileList]);

  // draft 상태에 따른 voiceId, voice가 없는경우 기본 voice가 들어간다.
  const voiceId = useMemo(() => {
    return selectedLine?.draft?.voiceId || activeTake?.voiceId || defaultVoice; // fixme 임시로 들어간 voice 들, voice연결시 수정필요
  }, [selectedLine, activeTake, defaultVoice]);

  const profile = useMemo(() => {
    return projectVoiceProfileList.find((profile) =>
      profile.voices.some((voice) => voice.id === voiceId)
    );
  }, [projectVoiceProfileList, voiceId]);

  // profile의 voices를 가져온다.
  const voices = useMemo(() => {
    return profile?.voices;
  }, [profile]);

  // draft 상태에 따른 style, 선택된 Styled이 없으면 neutral(기본)으로 설정
  const style = useMemo(() => {
    return voices?.find((voice) => voice.id === voiceId)?.style;
  }, [voices, voiceId]);

  // draft 상태에 따른 language, 선택된 언어가 없으면 영문(기본)으로 설정
  const language = useMemo(() => {
    return (
      selectedLine?.draft?.language ||
      activeTake?.language ||
      profile?.language ||
      (projectInfo.language as Language)
    );
  }, [selectedLine, activeTake, profile?.language, projectInfo.language]);

  return {
    defaultVoice,
    voiceId,
    profile,
    language,
    activeTake,
    style,
    controlState,
  };
};
