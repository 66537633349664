export function formatDateDay(date: Date): string {
  const yyyy: string = date.getFullYear().toString();
  const mm: string = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero based
  const dd: string = ('0' + date.getDate()).slice(-2);
  return `${yyyy}-${mm}-${dd}`;
}

export const formatDateTime = (date: Date) => {
  const hh: string = ('0' + date.getHours()).slice(-2);
  const min: string = ('0' + date.getMinutes()).slice(-2);
  return `${hh}:${min}`;
};

export const formatSToMMSSMS = (time: number) => {
  if (typeof time !== 'number') return '';
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time % 1) * 1000);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(3, '0');

  return `${formattedM}:${formattedS}:${formattedMS}`;
};

export const formatSToMMSS = (time: number) => {
  if (typeof time !== 'number') return '';
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');

  return `${formattedM}:${formattedS}`;
};

export const formatSToSSMSMS = (time: number) => {
  if (typeof time !== 'number') return '';
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time % 1) * 1000);

  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(3, '0');

  return `${formattedS}:${formattedMS}`;
};

// xAxis축의 시간을 표시하기 위해 사용
// MM:SS:MS 형식으로 표시
// TODO: 피드백 이후 형식 변경의 여지가 있음
export const formatXAxisTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor(((time % 1) * 1000) / 100);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(1, '0');

  return `${formattedM}:${formattedS}:${formattedMS}`;
};

export const formatFullDate = (date: Date | string) => {
  let d = date;
  if (typeof date === 'string') {
    d = new Date(date);
  }
  return d.toLocaleString?.('en-Us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
  });
};
