import { Black, Grey, Primary, Secondary, White } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import {
  COLUMN_HEIGHT,
  LEFT_OFFSET,
  X_AXIS_HEIGHT,
} from '../TimelinePanel/Timeline/const';

export const StyledTimelinePanel = styled.section`
  background-color: ${Black};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  .drag-handle {
    position: absolute;
    top: calc(-2px - 4.5rem);
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    cursor: row-resize;
    z-index: 1;
    &:hover,
    &.dragging {
      background-color: ${Grey[300]};
    }
  }
  .sup-timeline {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    .timeline-axis-x {
      box-sizing: border-box;
      text {
        color: ${Grey[500]};
        font-size: ${FontSize['Md']};
        font-weight: ${FontWeight['SemiBold']};
        user-select: none;
      }
    }
    .timeline-body {
      position: relative;
      width: 100%;
      /* height: calc(16.25rem - 3rem); */
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      &::-webkit-scrollbar {
        border-right: 0.75rem;
        width: 1.4rem;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 0.4rem;
        background-clip: padding-box;
        border: 0.5rem solid rgba(0, 0, 0, 0);
        border-radius: 4.5rem;
        background-color: rgba(217, 217, 217, 0.2);
        box-shadow: inset 0 0 0 0.75px ${Grey[400]};
      }
    }
    .timeline-loading {
      width: 100%;
      /* height: calc(16.25rem - 3rem); */
      background: ${Grey[700]};
      position: absolute;
      top: 3rem;
      left: 0;
      z-index: 1;
      section {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .timeline-axis-y {
      width: ${LEFT_OFFSET - 1}px;
      border-right: 1px solid ${Grey[700]};
      z-index: 1;
      .axis-y-item {
        position: relative;
        height: ${COLUMN_HEIGHT}px;
        background-color: ${Grey[800]};
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        /* 짝수번째 하위 rect에 background 적용 */
        &:nth-of-type(even) {
          background-color: ${Black};
        }
        img {
          display: block;
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
        }
        &-music {
          position: relative;
          width: 2rem;
          height: 2rem;
          .sup-icon-button {
            width: 2rem;
            height: 2rem;
            svg {
              width: 100%;
              height: 100%;
            }
            &:hover {
              opacity: 0.5;
            }
            &.mute {
              color: ${Grey[300]};
              &:hover {
                color: ${Grey[50]};
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .timeline-block-container {
      width: calc(100% - ${LEFT_OFFSET}px);
      position: relative;
      .grids {
        width: 100%;
        position: relative;
        .grid {
          width: 100%;
          height: ${COLUMN_HEIGHT}px;
          background-color: ${Grey[800]};
          /* 짝수번째 하위 rect에 background 적용 */
          &:nth-of-type(even) {
            background-color: ${Black};
          }
        }
      }
      .block {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 0;
        border-radius: 0.25rem;
        border: 1px solid transparent;
        background-color: ${Grey[700]};
        cursor: grab;
        &.dragging {
          border-color: ${Grey[50]};
          cursor: grabbing;
        }
        &.not-draggable {
          cursor: not-allowed;
        }
        &.hide {
          display: none;
        }
        &.active {
          border-color: ${Grey[50]};
          background-color: ${Primary[300]};
          .audio-text {
            font-weight: ${FontWeight['SemiBold']};
          }
        }
        &.audio {
          border: 1px solid transparent;
          background-color: #4aab90;
          &.active {
            /* background-color: ${Primary[500]}; */
          }
          &.dragging {
            border: 1px solid ${Grey[50]};
          }
          &.disabled {
            opacity: 0.5;
            /* background-color: ${Primary[800]}; */
            .block-text {
              color: ${Grey[500]};
              svg {
                fill: ${Grey[500]};
              }
            }
          }
          .block-text {
            text-align: center;
            padding: calc(0.62rem - 1px) 0.5rem;
            width: calc(100% - 1rem);
          }
        }
      }
      .block-waveform {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        .waveform {
          position: absolute;
          top: 0;
          left: 0;
          background: none;
          border: none;
          canvas {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      .block-text {
        font-size: ${FontSize['Sm']};
        font-weight: ${FontWeight['Regular']};
        display: block;
        width: 100%;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;
      }
      .block-resize {
        position: absolute;
        top: 0;
        height: 100%;
        cursor: ew-resize;
        svg {
          width: 0.5rem;
          height: 100%;
          fill: ${Grey[50]};
        }
        &.block-resize-start {
          padding-left: 0.5rem;
          left: 0;
        }
        &.block-resize-end {
          padding-right: 0.5rem;
          right: 0;
        }
      }
      .block-delete {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 1px solid ${Grey[50]};
        background: #b35964;
      }
    }
    .gap-block {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      padding: calc(0.64rem - 1px) 0;
      border-radius: 0.25rem;
      border: 1px solid ${Primary[400]};
      .gap-block-text {
        font-size: ${FontSize['Sm']};
        display: block;
        color: ${Primary[400]};
        text-align: center;
        user-select: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
      }
      .gap-block-guide {
        position: absolute;
        top: 0;
        width: 0;
        border: 0.5px dashed ${Primary[400]};
        &.start {
          left: -0.5px;
        }
        &.end {
          right: -0.5px;
        }
      }
      .gap-block-snap {
        position: absolute;
        top: 0;
        width: 0;
        left: -1px;
        border: 1px solid ${Grey[50]};
      }
    }
    .timeline-indicator {
      position: absolute;
      top: ${X_AXIS_HEIGHT}px;
      left: 0;
      z-index: 1;
      cursor: pointer;
      svg {
        position: absolute;
        top: -6px;
        left: -5.5px;
      }
      .line {
        position: absolute;
        top: 0;
        left: -2.5px;
        width: 1px;
        /* height: calc(16.25rem - 3rem); */
        background-color: ${Secondary[200]};
      }
    }
    .timeline-scroll {
      margin-top: -0.8rem;
      margin-left: ${LEFT_OFFSET}px;
      .sup-scrollbar-thumb {
        background-color: rgba(217, 217, 217, 0.2);
        box-shadow: inset 0 0 0 0.75px ${Grey[400]};
        cursor: pointer;
      }
    }
    .timeline-btn {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      display: flex;
      gap: 0.63rem;
      z-index: 1;
      button {
        padding: 0.22rem;
        width: 2rem;
        height: 2rem;
        border-radius: 0.13rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${Grey[50]};
        color: ${Grey[50]};
        svg {
          width: 1.75rem;
          height: 1.75rem;
        }
        &.snap {
          border-color: ${Grey[400]};
          path {
            fill: ${Grey[400]};
          }
          path:first-of-type {
            fill: ${Primary[400]};
          }
          &.active {
            border-color: ${Grey[50]};
            background-color: ${Grey[300]};
            path {
              fill: ${Grey[50]};
            }
            path:first-of-type {
              fill: ${Primary[400]};
            }
          }
        }
        &[disabled] {
          color: #707379;
          border-color: #707379;
        }
      }
    }
  }
`;

export const StyledMusicVolumeContext = styled.div`
  box-sizing: border-box;
  position: absolute;
  background-color: yellow;
  z-index: 1000;
  width: 10rem;
  height: 5.25rem;
  border-radius: 0.25rem;
  border: 1px solid ${Grey[500]};
  background: rgba(18, 18, 18, 0.8);
  backdrop-filter: blur(2px);
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  .label {
    font-size: ${FontSize['Md']};
    font-weight: ${FontWeight['SemiBold']};
    color: ${White};
    margin-right: 1rem;
    width: 3.5rem;
    height: 1.25rem;
  }
  .value {
    width: calc(3.5rem - 2px);
    height: 1.25rem;
    border: 1px solid ${Grey[500]};
    border-radius: 0.125rem;
    background: ${Grey[600]};
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .control-volume {
    padding: 0;
  }
`;
