import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { ReactComponent as Meme } from '@/pages/screenplay/assets/images/meme.svg';
import { Grey } from '@/styles/Colors';
import { isShowMemeIcon } from '@/util/voiceLabel';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import { ReactComponent as PlayIcon } from '../assets/icons/PlayIcon.svg';
import { ReactComponent as SimpleStopIcon } from '../assets/icons/SimpleStopIcon.svg';
import { ReactComponent as UpArrow } from '../assets/icons/UpArrow.svg';
import { useLog } from '../hooks/useLog/useLog';
import useVoiceProfile from '../hooks/useVoiceProfile';
import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import {
  profileEditInfoListAtom,
  projectVoiceProfileListAtom,
} from '../stores/atoms/voice';
import { Profile, ProfileEditInfo, Voice } from '../stores/voice';
import StyledVoiceProfile from '../styles/StyledVoiceProfile';
import { AgeMap, LanguageMap } from './const';
import LoadingDots from './Loading';
import { getThumbnailPath } from './utils';

interface VoiceLibraryItemProps {
  profile: Profile;
  isOpen: boolean;
  onComplete: (profile: Profile, currentEditInfo: ProfileEditInfo) => void;
  onDelete: (currentEditInfo: ProfileEditInfo) => void;
  toggleItem: (voiceId: string) => void;
  isPlaying: boolean;
  playAudio: (audioBuffer: AudioBuffer) => void;
  stopAudio: () => void;
  playingId: string | null;
  updatePlayingId: (id: string | null) => void;
  isLoading: boolean;
  updateIsLoading: (isLoading: boolean) => void;
}
const VoiceLibraryItem: React.FC<VoiceLibraryItemProps> = ({
  profile,
  isOpen,
  onComplete,
  onDelete,
  toggleItem,
  playAudio,
  isPlaying,
  stopAudio,
  playingId,
  updatePlayingId,
  isLoading,
  updateIsLoading,
}) => {
  const { t } = useTranslation();
  const projectVoiceProfileList = useRecoilValue(projectVoiceProfileListAtom);
  const profileEditInfoList = useRecoilValue(profileEditInfoListAtom);
  const { loadVoiceFile } = useVoiceProfile();
  const { projectId } = useParams();
  const ref = useRef<HTMLLIElement>(null);
  const { usedProfileIdList } = useSceneEditorContext();

  useEffect(() => {
    isOpen &&
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
  }, [isOpen, ref]);

  const editInfo = useMemo(() => {
    return profileEditInfoList.find((info) => info.profileId === profile.id);
  }, [profileEditInfoList, profile.id]);

  const currentEditInfo = useMemo(() => {
    if (!editInfo)
      return {
        id: uuid(),
        profileId: profile.id,
        projectId: projectId as string,
        selectedVoiceId: profile.default_voice_id,
      };
    return editInfo;
  }, [profile, projectId, editInfo]);

  const isAdded = useMemo(
    () => projectVoiceProfileList.some((v) => v.id === profile.id),
    [profile.id, projectVoiceProfileList]
  );

  const isProfileUsed = useMemo(() => {
    if (!usedProfileIdList) return false;
    return usedProfileIdList.includes(profile.id);
  }, [usedProfileIdList, profile.id]);

  const disabledButton = useMemo(() => {
    return (projectVoiceProfileList.length <= 1 && isAdded) || isProfileUsed;
  }, [projectVoiceProfileList, isAdded, isProfileUsed]);

  const sortedVoices = useMemo(() => {
    return profile.voices.reduce((sortedVoices, voice) => {
      if (voice.id === profile.default_voice_id) {
        sortedVoices.unshift(voice);
      } else {
        sortedVoices.push(voice);
      }
      return sortedVoices;
    }, [] as Voice[]);
  }, [profile.voices, profile.default_voice_id]);

  // project에 voice가 있으면 추가, 없으면 삭제
  const updateModel = useCallback(() => {
    if (isAdded) {
      editInfo && onDelete(editInfo);
    } else {
      onComplete(profile, {
        ...currentEditInfo,
      });
    }
  }, [onComplete, onDelete, profile, currentEditInfo, editInfo, isAdded]);

  // age or gender가 변경되면 voice file을 다시 가져온다.
  const getVoiceFile = useCallback(
    async (url: string) => {
      updateIsLoading(true);
      const { audioBuffer } = await loadVoiceFile(url);
      updateIsLoading(false);
      return audioBuffer;
    },
    [loadVoiceFile, updateIsLoading]
  );

  const { track } = useLog();

  const playVoice = useCallback(
    async (e: React.MouseEvent, voiceId: string) => {
      e.stopPropagation();
      const voice = profile.voices.find((v) => v.id === voiceId);
      if (!voice) return;
      updatePlayingId(voice.id);
      if (isPlaying && playingId === voice.id) {
        stopAudio();
        return;
      }
      const buffer = await getVoiceFile(voice.sample);
      playAudio(buffer);
      track('PREVIEW_PLAY', {
        profileId: profile.id,
        voice_name: profile.name,
        voiceId: voice.id,
      });
    },
    [
      getVoiceFile,
      playAudio,
      profile,
      isPlaying,
      stopAudio,
      updatePlayingId,
      playingId,
      track,
    ]
  );

  const profileIconPath = useMemo(() => getThumbnailPath(profile), [profile]);

  const profileImagePath = useMemo(
    () => getThumbnailPath(profile, 'profile'),
    [profile]
  );

  const hasDefaultVoice = useMemo(
    () => profile.voices.some((voice) => voice.id === profile.default_voice_id),
    [profile]
  );

  return (
    <li
      className={classNames('voice-library-item', {
        open: isOpen,
        disabled: !hasDefaultVoice,
      })}
      ref={ref}
    >
      <section className="item-summary" onClick={() => toggleItem(profile.id)}>
        <StyledVoiceProfile className="voice-profile">
          <button
            className={classNames('voice-image', {
              empty: !profileIconPath,
            })}
            style={
              profileIconPath
                ? { backgroundImage: `url(${profileIconPath})` }
                : {}
            }
          ></button>
        </StyledVoiceProfile>
        <span className="voice-name cell">
          {profile.displayName}
          {isShowMemeIcon(profile) && (
            <span className={'meme-icon'}>
              <Meme />
            </span>
          )}
        </span>
        <span className="voice-locale cell">
          {LanguageMap[profile.language]}
        </span>
        <span className="voice-gender cell">{profile.gender}</span>
        <span
          className="voice-age cell"
          // tmp: 서버에서 key, label 도입 시 제거 예정
          style={{ textTransform: 'initial' }}
        >
          {AgeMap[profile.age]}
        </span>
        <span className="voice-case cell">{profile.usecase}</span>
        <Button
          className={classNames('btn-voice-action', isAdded && 'complete')}
          onClick={(e) => {
            e.stopPropagation();
            updateModel();
          }}
          disabled={disabledButton || (!hasDefaultVoice && !isAdded)}
        >
          {isAdded ? t('Added') : t('Add')}
        </Button>
        <IconButton
          className={`arrow-down ${isOpen ? 'open' : ''}`}
          variant="none"
          color={Grey[200]}
          isFillCurrentColor={false}
        >
          <UpArrow />
        </IconButton>
      </section>
      {isOpen && (
        <section className="item-details">
          {profileImagePath ? (
            <span
              className="profile-img"
              style={{ backgroundImage: `url(${profileImagePath})` }}
            />
          ) : (
            <span className="voice-img empty" />
          )}
          <section className="voice-styles">
            <section className="btn-group">
              {sortedVoices.map((voice) => (
                <button
                  className={classNames('btn-style', {
                    active: isPlaying && playingId === voice.id,
                  })}
                  key={voice.id}
                  onClick={(e) => playVoice(e, voice.id)}
                >
                  {isLoading && playingId === voice.id ? (
                    <LoadingDots />
                  ) : (
                    <>
                      <i
                        className={classNames(
                          'icon',
                          isPlaying && playingId === voice.id && 'stop'
                        )}
                      >
                        {isPlaying && playingId === voice.id ? (
                          <SimpleStopIcon />
                        ) : (
                          <PlayIcon />
                        )}
                      </i>
                      {voice.style}
                    </>
                  )}
                </button>
              ))}
            </section>
          </section>
        </section>
      )}
    </li>
  );
};
export default VoiceLibraryItem;
