import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  _addProfileEditInfo,
  _deleteProfileEditInfo,
  _loadProfileEditInfoList,
  _updateProfileEditInfo,
} from '../../api/project';
import { profileEditInfoListAtom } from '../atoms/voice';
import { ProfileEditInfo } from '../voice';

export const useVoice = () => {
  const setProfileEditInfoList = useSetRecoilState(profileEditInfoListAtom);
  const getProfileEditInfoList = useCallback(
    async (projectId: string) => {
      const result = (await _loadProfileEditInfoList(
        projectId
      )) as ProfileEditInfo[];
      setProfileEditInfoList(result);
      return result;
    },
    [setProfileEditInfoList]
  );

  const addProfileEditInfo = useCallback(
    async (value: ProfileEditInfo) => {
      const result = (await _addProfileEditInfo(value)) as ProfileEditInfo[];
      setProfileEditInfoList(result);
    },
    [setProfileEditInfoList]
  );

  const updateProfileEditInfo = useCallback(
    async (value: ProfileEditInfo) => {
      const result = (await _updateProfileEditInfo(value)) as ProfileEditInfo[];
      setProfileEditInfoList(result);
    },
    [setProfileEditInfoList]
  );

  const deleteProfileEditInfo = useCallback(
    async (editInfo: ProfileEditInfo) => {
      const result = (await _deleteProfileEditInfo(
        editInfo
      )) as ProfileEditInfo[];
      setProfileEditInfoList(result);
    },
    [setProfileEditInfoList]
  );

  return {
    getProfileEditInfoList,
    addProfileEditInfo,
    updateProfileEditInfo,
    deleteProfileEditInfo,
  };
};
