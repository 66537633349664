import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { CardUIProps } from './CardUI';
import { StyledCardUIContent } from './StyledCardUI';

const CardUIContent = ({
  padding = '0.5rem',
  justifyContent = 'space-between',
  alignItems = 'flex-start',
  alignContent = 'flex-start',
  flexDirection = 'row',
  gap = '0',
  className = '',
  children,
  ...rest
}: PropsWithChildren<Omit<CardUIProps, 'backgroundColor'>>) => {
  return (
    <StyledCardUIContent
      padding={padding}
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignContent={alignContent}
      flexDirection={flexDirection}
      gap={gap}
      className={classNames('sup-card-content', className)}
      {...rest}
    >
      {children}
    </StyledCardUIContent>
  );
};

export default CardUIContent;
