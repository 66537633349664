import { HTMLAttributes, PropsWithChildren } from 'react';

import { StyledButtonGroup } from './StyledButton';
import { ButtonRadius } from './types';

export interface ButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
  radius?: ButtonRadius;
  className?: string;
}

const ButtonGroup = ({
  radius = 'md',
  className = '',
  children,
  ...rest
}: PropsWithChildren<ButtonGroupProps>) => {
  return (
    <StyledButtonGroup
      className={`sup-button-group ${className}`}
      radius={radius}
      {...rest}
    >
      {children}
    </StyledButtonGroup>
  );
};

export default ButtonGroup;
