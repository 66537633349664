import IconButton from '@/components/Button/IconButton';
import { BasicFontSize } from '@/styles/Typography';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ReactComponent as DeleteBlockIcon } from '../../assets/icons/DeleteBlockIcon.svg';
import { ReactComponent as ResizeHandleIcon } from '../../assets/icons/ResizeHandleIcon.svg';
import {
  audioFileMapAtom,
  currentPlayingAudioInfoListAtom,
} from '../../stores/atoms/audio';
import { AudioType } from '../../stores/audios';
import { MIN_RESIZE_WIDTH } from './const';
import Waveform from './Waveform';

export interface BlockInfo {
  id: string;
  type: AudioType;
  content?: string;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  disabled?: boolean;
  color?: string;
  origStartX: number;
  origEndX: number;
  resource_id?: string;
  lineId: string;
}

interface BlockProps {
  data: BlockInfo;
  isDraggable?: boolean;
  isEditable?: boolean;
  dragging?: boolean;
  dimmed?: boolean;
  hide?: boolean;
}

const Block = ({
  data,
  isDraggable = true,
  isEditable = false,
  dragging,
  dimmed,
  hide,
}: BlockProps) => {
  const currentPlayingAudioInfoList = useRecoilValue(
    currentPlayingAudioInfoListAtom
  );
  const audioFileMap = useRecoilValue(audioFileMapAtom);

  const [isHover, setIsHover] = useState(false);

  const showResizeHandle = useMemo(() => {
    return isHover && isEditable && data.endX - data.startX > MIN_RESIZE_WIDTH;
  }, [isHover, isEditable, data.endX, data.startX]);

  const isPlaying = useMemo(() => {
    return currentPlayingAudioInfoList.some((audio) => audio.id === data.id);
  }, [currentPlayingAudioInfoList, data]);

  const audioBuffer = useMemo(() => {
    if (!data.resource_id || !audioFileMap[data.resource_id]) return;
    return audioFileMap[data.resource_id].audioBuffer;
  }, [audioFileMap, data.resource_id]);

  const origWidth = useMemo(
    () => Math.round(data.origEndX - data.origStartX),
    [data.origEndX, data.origStartX]
  );

  const width = useMemo(
    () => Math.round(data.endX - data.startX),
    [data.endX, data.startX]
  );

  const height = useMemo(
    () => Math.round(data.endY - data.startY),
    [data.endY, data.startY]
  );

  return (
    <div
      className={classNames('block', {
        active: isPlaying,
        audio: data.type === 'music',
        disabled: data.disabled,
        dragging,
        'not-draggable': !isDraggable,
        editable: isEditable,
        hide,
        dimmed,
      })}
      id={data.id}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${data.startX}px, ${data.startY}px)`,
        backgroundColor: data.color,
        ...(data.type !== 'music' && {
          padding: `calc(0.64rem - 1px) ${
            width < 1.5 * BasicFontSize ? '0' : '0.5rem'
          }`,
        }),
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {data.type === 'music' && audioBuffer && (
        <div className="block-waveform">
          <Waveform
            audioBuffer={audioBuffer}
            width={origWidth}
            height={height}
            offsetX={data.startX - data.origStartX}
          />
        </div>
      )}
      <span title={data.content} className="block-text">
        {data.content}
      </span>
      {showResizeHandle && (
        <>
          <span className="block-resize block-resize-start">
            <ResizeHandleIcon />
          </span>
          <span className="block-resize block-resize-end">
            <ResizeHandleIcon />
          </span>
        </>
      )}
      {isHover && isEditable && (
        <IconButton className="block-delete">
          <DeleteBlockIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Block;
