import AlertModal from '@/components/Modal/AlertModal/AlertModal';
import ErrorIcon from '@/pages/screenplay/assets/images/ErrorIcon.png';
import { DISCORD_URL } from '@/pages/screenplay/stores/data/config';
import { Primary } from '@/styles/Colors';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const StyledMultipleTabError = styled.section`
  .sup-modal {
    .modal {
      width: 24.3rem;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      line-height: 150%;
      .discord-link {
        a {
          color: ${Primary[400]};
        }
      }
    }
    .modal-footer {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
`;

const MultipleTabError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledMultipleTabError>
      {AlertModal({
        isOpen: true,
        isPortal: false,
        hideBackdrop: true,
        title: (
          <>
            <img
              src={ErrorIcon}
              alt="Error"
              style={{
                width: '1.125rem',
                height: '1.125rem',
                marginRight: '0.5rem',
              }}
            />
            <span>{t('Please try again.')}</span>
          </>
        ),
        message: (
          <>
            <p>
              {t(
                "We're sorry. Something went wrong. If you're running in more than one tab, please close any unused windows and Refresh."
              )}
            </p>
            <p>
              {t(
                'If this does not resolve the issue, please contact us via our Discord channel.'
              )}
            </p>
            <p className="discord-link">
              <a href={DISCORD_URL}>{DISCORD_URL}</a>
            </p>
          </>
        ),
        onConfirm: () => {
          window.location.assign('/projects');
        },
      })}
    </StyledMultipleTabError>
  );
};
export default MultipleTabError;
