// tmp component for needs approval
import Button from '@/components/Button/Button';
import { Grey, Primary } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { FC } from 'react';

import { DISCORD_URL, ZENDESK_URL } from '../screenplay/stores/data/config';

const StyledWrapper = styled.div`
  width: 55%;
  display: flex;
  flex-flow: column;
  h1 {
    font-size: 3.75rem;
    color: ${Primary[400]};
    font-weight: ${FontWeight.SemiBold};
    display: flex;
    align-items: center;
    gap: 1rem;
    .icon {
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 4rem;
        height: 4rem;
      }
    }
  }
  .desc {
    margin-top: 2rem;
    font-size: 1.5rem;
    padding-left: 5rem;
    color: ${Grey[50]};
    font-family: Inter;
    &.discord {
      margin-top: 2.5rem;
      line-height: 150%;
      a {
        color: ${Primary[400]};
        text-decoration: none;
      }
      .arrow {
        font-family: Inter;
      }
    }
    .logout {
      width: 12.5rem;
      height: 2.5rem;
      font-size: 1.125rem;
    }
    &.button {
      margin-top: 3rem;
    }
  }
`;

const NeedsApproval: FC = () => {
  const { logout } = useSUPAuth();
  return (
    <StyledWrapper>
      <h1>
        <span className="icon">
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 64H64V0H0V64ZM32.0105 4.97333C38.2272 4.97333 43.9381 7.10175 48.511 10.621L33.0853 26.0468L37.9743 30.9358L53.4001 15.51C56.9193 20.083 59.0477 25.7939 59.0477 32.0105C59.0477 46.9305 46.9516 59.0477 32.0105 59.0477C17.0695 59.0477 4.97333 46.9305 4.97333 32.0105C4.97333 17.0905 17.0695 4.97333 32.0105 4.97333Z"
              fill="#0A5BFF"
            />
          </svg>
        </span>
        <span>Welcome aboard.</span>
      </h1>
      <section className="desc">
        You have successfully registered. We will sequentially grant permissions
        and notify you via email.
      </section>
      <section className="desc discord">
        <section>
          <a href={DISCORD_URL} target="_blank" rel="noreferrer">
            <em className="arrow">-&gt;</em> Go to Supertone Discord Channel
          </a>
        </section>
        <a href={ZENDESK_URL} target="_blank" rel="noreferrer">
          <em className="arrow">-&gt;</em> Go to Supertone Zendesk
        </a>
      </section>
      <section className="desc button">
        <Button
          className="logout"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          Log Out
        </Button>
      </section>
    </StyledWrapper>
  );
};

export default NeedsApproval;
