export const MODEL_TYPES = ['tts'] as const;

export type ModelType = (typeof MODEL_TYPES)[number];

export const ParameterType = {
  Slider: 'slider',
  // tmp 현재 미사용
  // Checkbox: 'checkbox',
  // Ratio: 'ratio',
} as const;

type Parameter = (typeof ParameterType)[keyof typeof ParameterType];

export interface CheckboxParameter {
  type?: Parameter;
  name: string;
  label: string;
  tooltip: string;
  defaultValue: boolean;
}
export interface SliderParameter
  extends Omit<CheckboxParameter, 'defaultValue'> {
  defaultValue: number;
  minValue: number;
  maxValue: number;
  step: number;
  minLabel?: string;
  maxLabel?: string;
  isToggleable?: boolean;
  defaultToggle?: boolean;
  valueFormatter?: (value: number) => string;
  disabled?: boolean;
}

const parameterMap: Record<string, SliderParameter> = {
  pitch_shift: {
    name: 'pitch_shift',
    label: 'Pitch Shift',
    tooltip: '반음 단위로 수정',
    defaultValue: 0,
    minValue: -24,
    maxValue: 24,
    step: 1,
  },

  pitch_variance: {
    name: 'pitch_variance',
    label: 'Pitch Variance',
    tooltip: '음정 편차 조절',
    defaultValue: 1,
    minValue: 0,
    maxValue: 2,
    step: 0.1,
    minLabel: 'static',
    maxLabel: 'dynamic',
  },

  speed: {
    name: 'speed',
    label: 'Speed',
    tooltip: '음원의 속도 조절',
    defaultValue: 1,
    minValue: 0.5,
    maxValue: 2,
    step: 0.1,
    minLabel: 'slow',
    maxLabel: 'fast',
  },

  similarity: {
    name: 'similarity',
    label: 'Target Similarity',
    tooltip: '타겟 유사도, 낮을수록 결과물이 랜덤하게 변합니다.',
    defaultValue: 2,
    minValue: 1,
    maxValue: 5,
    step: 0.1,
  },
};

export const ttsParameters = [
  parameterMap.pitch_shift,
  parameterMap.pitch_variance,
  parameterMap.speed,
  parameterMap.similarity,
];
export const defaultTTSParams = ttsParameters.reduce(
  (acc, param) => ({ ...acc, [param.name]: param.defaultValue }),
  {}
);

export const modelParamMap: Record<ModelType, SliderParameter[]> = {
  tts: ttsParameters,
};
