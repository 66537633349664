const MAX_WHEEL_DELTA = 100;

export const normalizeWheel = (e: WheelEvent) => {
  let { deltaX, deltaY } = e;

  if (e.shiftKey) {
    [deltaX, deltaY] = [deltaY, deltaX];
  }

  deltaX = Math.max(Math.min(deltaX, MAX_WHEEL_DELTA), -MAX_WHEEL_DELTA);
  deltaY = Math.max(Math.min(deltaY, MAX_WHEEL_DELTA), -MAX_WHEEL_DELTA);

  return [deltaX, deltaY];
};
