import axios from 'axios';

export interface AudioMetadataProps {
  props: {
    duration: number;
    sampleRate: number;
    channels: number;
    bitDepth?: number;
    bitRate?: number;
    codec?: string;
  };
}
export interface ResourceResponseData {
  resource_id: string;
  purpose: string;
  upload_url: string;
  message: string;
}
export interface ResourceResponse {
  status: string;
  message: string;
  data: ResourceResponseData;
}
export interface ResourceFileResponse {
  status: string;
  message: string;
  data: {
    resource_id: string;
    name: string;
    original: {
      url: string;
      metadata: AudioMetadataProps;
    };
    transcoded: {
      url: string;
      metadata: AudioMetadataProps;
    }[];
  };
}
export interface JobIdResponse {
  status: string;
  message: string;
  data: {
    data: { job_id: string };
  };
}

export const upload = async (upload_url: string, file: File) => {
  await axios.put(upload_url as string, file, {
    headers: {
      'Content-Type': file.type,
      withCredentials: true,
    },
  });
};
