import { scaleLinear } from 'd3-scale';
import {
  PointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Grey, Secondary } from '../../styles/Colors';
import { StyledWaveform } from './StyledWaveform';
import { resampleAudioData } from './utils';

interface WaveformProps {
  audioBuffer: AudioBuffer;
  playback?: number;
  onPlaybackChange?: (time: number) => void;
  progressColor?: string;
  waveColor?: string;
  showProgress?: boolean;
  cursorColor?: string;
  waveRectWidth?: number;
  waveRectMargin?: number;
  waveRectRadius?: number;
}

const RECT_WIDTH = 3;
const RECT_MARGIN = 1;
const RECT_RADIUS = 2;

const Waveform = ({
  audioBuffer,
  playback,
  onPlaybackChange,
  progressColor = Grey[300],
  waveColor = Grey[500],
  showProgress = true,
  cursorColor = Secondary[200],
  waveRectWidth = RECT_WIDTH,
  waveRectMargin = RECT_MARGIN,
  waveRectRadius = RECT_RADIUS,
}: WaveformProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const progressCanvasRef = useRef<HTMLCanvasElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0, ratio: 1 });
  const [waveData, setWaveData] = useState<Float32Array | null>(null);

  const xScale = useMemo(() => {
    return scaleLinear()
      .domain([0, audioBuffer?.duration || 0])
      .range([0, size.width]);
  }, [audioBuffer, size]);

  const drawWave = useCallback(
    (data: Float32Array, canvas: HTMLCanvasElement, color: string) => {
      const ctx = canvas?.getContext('2d');
      if (!ctx) return;
      let { width, height, ratio } = size;
      if (width === 0 || height === 0) return;
      width *= ratio;
      height *= ratio;

      const rectWidth = waveRectWidth * ratio;
      const rectMargin = waveRectMargin * ratio;
      const rectRadius = waveRectRadius * ratio;

      canvas.width = width;
      canvas.height = height;

      ctx.fillStyle = color;
      ctx.clearRect(0, 0, width, height);

      for (let i = 0; i < data.length; i++) {
        const x = i * (rectWidth + rectMargin);
        const y = Math.abs(data[i] * height) / 2;

        ctx.roundRect(x, height / 2 - y, rectWidth, y, [
          rectRadius,
          rectRadius,
          0,
          0,
        ]);
        ctx.roundRect(x, height / 2, rectWidth, y, [
          0,
          0,
          rectRadius,
          rectRadius,
        ]);
      }
      ctx.fill();
      const progress = xScale(playback || 0);
      if (typeof progress === 'number') {
        ctx.save();
        // draw progress
        ctx.globalCompositeOperation = 'source-atop';
        ctx.fillStyle = progressColor;
        ctx.fillRect(0, 0, progress * ratio, height);

        // draw line
        if (showProgress) {
          ctx.restore();
          ctx.beginPath();
          ctx.moveTo(progress * ratio, 0);
          ctx.lineTo(progress * ratio, height);
          ctx.strokeStyle = cursorColor;
          ctx.lineWidth = 1 * ratio;
          ctx.stroke();
        }
      }
      ctx.scale(ratio, ratio);
    },
    [
      size,
      showProgress,
      cursorColor,
      waveRectWidth,
      waveRectMargin,
      waveRectRadius,
      progressColor,
      xScale,
      playback,
    ]
  );

  useEffect(() => {
    if (!size.width || !size.height || !audioBuffer) return;
    const { width, ratio } = size;
    const newWidth = width * ratio;
    // TODO: 현재는 Mono만 지원, 추후 Stereo 등 다른 채널 지원
    const channelData = audioBuffer.getChannelData(0);
    const rectWidth = waveRectWidth * ratio;
    const rectMargin = waveRectMargin * ratio;

    const resampledData = resampleAudioData(
      channelData,
      newWidth,
      rectWidth,
      rectMargin
    );
    setWaveData(resampledData);
  }, [audioBuffer, size, waveRectWidth, waveRectMargin]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !waveData?.length) return;
    drawWave(waveData, canvas, waveColor);
  }, [drawWave, waveColor, waveData]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      if (width === 0 || height === 0) return;
      if (width === size.width && height === size.height) return;
      const ratio = window.devicePixelRatio || 1;
      setSize({ width, height, ratio });
    });

    resizeObserver.observe(wrapper);
    return () => {
      resizeObserver.unobserve(wrapper);
    };
  }, [size]);

  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      const wrapper = wrapperRef.current;
      if (!wrapper || !onPlaybackChange) return;
      const { left } = wrapper.getBoundingClientRect();
      const x = e.clientX - left;
      const time = xScale.invert(x);
      onPlaybackChange(time);
    },
    [onPlaybackChange, xScale]
  );

  return (
    <StyledWaveform
      className="sup-waveform"
      ref={wrapperRef}
      onPointerDown={handlePointerDown}
    >
      <canvas className="wave" ref={canvasRef} />
      {showProgress && (
        <canvas className="wave-progress" ref={progressCanvasRef} />
      )}
    </StyledWaveform>
  );
};

export default Waveform;
