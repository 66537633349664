import { Black } from '@/styles/Colors';
import styled from '@emotion/styled';

import { FontSize, FontWeight } from '../../styles/Typography';

const StyledLogin = styled.section`
  @media (max-width: 475px) {
    .btn-group {
      display: flex;
      flex-direction: column;
      bottom: 30%;
      gap: 1rem;
      .btn-login {
        padding: 1.26rem 1.875rem;
        font-size: ${FontSize['2Xl']};
        font-weight: ${FontWeight['Regular']};
        line-height: ${FontSize['4Xl']};
        border-radius: 0.46rem;
      }
    }
  }
  @media (min-width: 476px) and (max-width: 1024px) {
    .btn-group {
      bottom: 36%;
      gap: 1.2rem;
      .btn-login {
        padding: 1.26rem 1.875rem;
        font-size: ${FontSize['4Xl']};
        font-weight: ${FontWeight['Regular']};
        line-height: ${FontSize['6Xl']};
        border-radius: 0.46rem;
      }
    }
  }

  @media (min-width: 1025px) {
    .btn-group {
      bottom: 19%;
      gap: 1.2rem;
      .btn-login {
        padding: 1.26rem 1.875rem;
        font-size: ${FontSize['4Xl']};
        font-weight: ${FontWeight['Regular']};
        line-height: ${FontSize['6Xl']};
        border-radius: 0.46rem;
      }
    }
  }

  .btn-group {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    .btn-login {
      box-sizing: border-box;
      &.sign-up {
        color: ${Black};
      }
    }
  }
`;

export default StyledLogin;
