import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const loadAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

const LoadingContainer = styled.div`
  box-sizing: border-box;
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  animation: ${loadAnimation} 1.4s infinite ease-in-out both;

  &:nth-of-type(1) {
    left: 0.4rem;
    animation-delay: -0.32s;
  }

  &:nth-of-type(2) {
    left: 0.8rem;
    animation-delay: -0.16s;
  }

  &:nth-of-type(3) {
    right: 0.4rem;
  }
`;

const LoadingDots = () => (
  <LoadingContainer className="sup-loading-dots">
    <Dot />
    <Dot />
    <Dot />
  </LoadingContainer>
);

export default LoadingDots;
