import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useMemo } from 'react';

const useUserTier = () => {
  const { user } = useSUPAuth();
  const permissions = useMemo(() => user?.['permissions'] ?? [], [user]);
  const isTrial: boolean = useMemo(
    () =>
      !!permissions.find((permission) =>
        permission.toLowerCase().includes('play_obt_-_trial')
      ),
    [permissions]
  );
  const isPurchased: boolean = useMemo(
    () =>
      !!permissions.find((permission) =>
        permission.toLowerCase().includes('play_obt_-_seasonpass')
      ),
    [permissions]
  );
  return {
    isTrial,
    isNeedPurchasing: !isPurchased,
  };
};

export default useUserTier;
