import { MusicFile } from '../project';

export const defaultMusicFileList: MusicFile[] =
  // stg, prd 서버의 cdn 주소가 다르기 때문에 환경별로 다른 url 사용
  process.env.REACT_APP_ENV === 'dev'
    ? [
        {
          name: 'Wait before Departure',
          id: '82vMJBaY7G1mpKGSsiNzTK',
          url: 'https://d1mum4powiftsv.cloudfront.net/voice-library/resources/82vMJBaY7G1mpKGSsiNzTK.mp3',
        },
        {
          name: 'Enter the Maze',
          id: 'oGJjzyhFvNLPpPhjdkUkGJ',
          url: 'https://d1mum4powiftsv.cloudfront.net/voice-library/resources/oGJjzyhFvNLPpPhjdkUkGJ.mp3',
        },
        {
          name: 'Walk through a Cave',
          id: '7VeDG5w19tzeLFTSwGVjNB',
          url: 'https://d1mum4powiftsv.cloudfront.net/voice-library/resources/7VeDG5w19tzeLFTSwGVjNB.mp3',
        },
      ]
    : [
        {
          name: 'Wait before Departure',
          id: '6jGiEp9uMen91gzCTETPTR',
          url: 'https://d2hxfz33hpdvze.cloudfront.net/voice-library/resources/6jGiEp9uMen91gzCTETPTR.mp3',
        },
        {
          name: 'Enter the Maze',
          id: 'k1QYN4cqSxNByJ3vjHuzj7',
          url: 'https://d2hxfz33hpdvze.cloudfront.net/voice-library/resources/k1QYN4cqSxNByJ3vjHuzj7.mp3',
        },
        {
          name: 'Walk through a Cave',
          id: 'iYdkBD8QCeVkPzMT1ziA8W',
          url: 'https://d2hxfz33hpdvze.cloudfront.net/voice-library/resources/iYdkBD8QCeVkPzMT1ziA8W.mp3',
        },
      ];

export const extractFileName = (url: string) => {
  const lastSegment = url.split('/').pop();
  if (!lastSegment) return '';
  const fileNameWithoutExtension = lastSegment.substring(
    0,
    lastSegment.lastIndexOf('.')
  );
  return fileNameWithoutExtension;
};
