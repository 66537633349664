import { LayerPlacement } from '../../hooks/useLayerPosition/types';

export const DEFAULT_DROPDOWN_PROPS = {
  offsetY: 4,
  placement: 'bottom-left' as LayerPlacement,
  closeOnClickOutside: false,
};

export const DUMMY_DROPDOWN_OPTIONS = [
  {
    id: 'NANSY-V2',
    label: 'NANSY-V2',
    description:
      '수퍼톤의 가장 최신 모델입니다. Voice Conversion 기능을 지원합니다.',
  },
  {
    id: 'NANSY-V2 Interpolation',
    label: 'NANSY-V2 Interpolation',
    description:
      '수퍼톤의 가장 최신 모델에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다.',
  },
  {
    id: 'NANSY-V2 Maskgirl',
    label: 'NANSY-V2 Maskgirl',
    description:
      '마스크걸에 맞춰 튜닝한 수퍼톤의 가장 최신 모델입니다. Voice Conversion 기능을 지원하는 모드입니다.',
  },
  {
    id: 'NANSY-V2 Maskgirl Interpolation',
    label: 'NANSY-V2 Maskgirl Interpolation',
    description:
      '마스크걸에 맞춰 튜닝한 수퍼톤의 가장 최신 모델에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다. 마스크걸에 맞춰 튜닝한 수퍼톤의 가장 최신 모델에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다.',
  },
  {
    id: 'NANSY-V2 Amelia',
    label: 'NANSY-V2 Amelia',
    description: '아이유노 Polish POC 용 nansy-v2 finetuned 모델',
  },
  {
    id: 'NANSY-V2 Aleksandra',
    label: 'NANSY-V2 Aleksandra',
    description: '아이유노 Polish POC 용 nansy-v2 finetuned 모델',
  },
  {
    id: 'NANSY-V2 JPN',
    label: 'NANSY-V2 JPN',
    description: '아이유노 Japanese POC 용 nansy-v2 finetuned 모델',
  },
  {
    id: 'NANSY-V2 Flint',
    label: 'NANSY-V2 Flint',
    description: '플린트 POC용 nansy-v2 finetuned 모델',
  },
  {
    id: 'NANSY-V2 Jeongnyeon',
    label: 'NANSY-V2 Jeongnyeon',
    description: '정년이 nansy-v2 finetuned 모델',
  },
  {
    id: 'NANSY-V2 Jeongnyeon Interpolation',
    label: 'NANSY-V2 Jeongnyeon Interpolation',
    description:
      '정년이 nansy-v2 fine tuned 모델에서 여러 목소리를 섞어 Voice Conversion 할 수 있게 하는 모드입니다.',
  },
];
