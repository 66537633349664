import { Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import FileExportIcon from '../assets/icons/FileExportIcon.svg';
import FileImportIconHover from '../assets/icons/FileImportIcon_hover.svg';
import FileImportIcon from '../assets/icons/FileImportIcon.svg';

const StyledScriptTab = styled.section`
  .file-convert {
    padding: 1.25rem;
    border-bottom: 1px solid ${Grey[500]};
    .export-title {
      color: ${Grey[200]};
      font-size: 1rem;
      font-weight: ${FontWeight['SemiBold']};
      ::before {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 1.375rem;
        height: 1.5rem;
        margin-right: 0.85rem;
        background-image: url(${FileExportIcon});
        background-size: cover;
      }
    }
    .btn-import-file {
      color: ${Grey[200]};
      cursor: pointer;
      input {
        visibility: hidden;
        position: absolute;
        width: 0;
        height: 0;
      }
      &:hover {
        color: ${Grey[50]};
        ::before {
          background-image: url(${FileImportIconHover});
        }
      }
      ::before {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 1.375rem;
        height: 1.5rem;
        margin-right: 0.85rem;
        background-image: url(${FileImportIcon});
        background-size: cover;
      }
    }
    .export-format {
      display: flex;
      justify-content: space-between;
      padding: 1.875rem 0rem 0 2.25rem;
      .format-selector {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
      }
      .format-selector-value {
        display: flex;
        width: 3.5rem;
        height: 1.5rem;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: ${Grey[50]};
        text-align: center;
        border-radius: 0.25rem;
        background: ${Grey[800]};
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .export-btn {
      padding-top: 1.875rem;
      display: flex;
      justify-content: end;
      .btn-export {
        display: inline-flex;
        padding: 0.5rem 1.5rem;
      }
    }
  }
`;
export default StyledScriptTab;
