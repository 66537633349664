import classNames from 'classnames';
import { PropsWithChildren, forwardRef, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { StyledTooltipContent } from './StyledTooltip';
import { Placement } from './Tooltip';

interface TooltipContentProps {
  isActive: boolean;
  placement: Placement | null;
  maxWidth?: number;
}

const TooltipContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<TooltipContentProps>
>(({ isActive, placement, maxWidth = 0, children }, ref) => {
  // If the maxWidth is set, we need to wrap the content and break the text.
  const contentWrapStyle = useMemo(() => {
    if (maxWidth > 0)
      return {
        width: `${maxWidth}px`,
        maxWidth: `${maxWidth}px`,
        whiteSpace: 'pre-wrap' as const,
        wordBreak: 'break-all' as const,
      };
    else return {};
  }, [maxWidth]);

  return createPortal(
    <StyledTooltipContent
      ref={ref}
      className={classNames(
        'sup-tooltip',
        isActive && 'sup-tooltip-active',
        !placement && 'sup-tooltip-hidden'
      )}
    >
      <div className="sup-tooltip-content" style={contentWrapStyle}>
        {children}
      </div>
    </StyledTooltipContent>,
    document.getElementById('tooltip-portal') as HTMLElement
  );
});

export default TooltipContent;
