import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';
import { createPortal } from 'react-dom';

import { StyledDropdownLayer } from './StyledDropdownLayer';

interface DropdownLayerProps extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
}

const DropdownLayer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DropdownLayerProps>
>(({ isOpen, children, ...props }, ref) => {
  if (!isOpen) return null;

  return createPortal(
    <StyledDropdownLayer ref={ref} {...props}>
      {children}
    </StyledDropdownLayer>,
    document.getElementById('portal') as HTMLElement
  );
});

export default DropdownLayer;
