import Button, { ButtonProps } from '@/components/Button/Button';
import { Dropdown, DropdownItem } from '@/components/Dropdown';
import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';
import { useState } from 'react';

import { ReactComponent as ArrowDownIcon } from '../assets/icons/ArrowDownIcon.svg';
import { ReactComponent as SelectedIcon } from '../assets/icons/SelectedIcon.svg';

export interface SelectItem {
  label: string | number;
  value: string | number;
  thumb?: string;
}

interface SelectProps {
  items: SelectItem[];
  onChange: (item: SelectItem) => void;
  fixedLabel?: string;
  selectedItem?: SelectItem;
  // Button 컴포넌트에 전달할 props
  buttonProps?: Pick<ButtonProps, 'size' | 'variant' | 'color' | 'disabled'>;
}

const StyledDropdown = styled.ul`
  .sup-dropdown-item {
    padding: 0.5rem 1rem !important;
    &.selected {
      background: none !important;
    }
    &:hover {
      background-color: ${Grey[200]} !important;
      &.selected {
        background-color: ${Grey[200]} !important;
      }
    }
  }
  .sup-dropdown-item-label {
    max-width: 14rem;
    .label {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    .thumb {
      width: 2.625rem;
      height: 2rem;
      background-size: cover;
      display: inline-block;
    }
  }
`;

const Select = ({
  items,
  selectedItem,
  onChange,
  fixedLabel,
  buttonProps,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      anchorElement={
        <Button
          endIcon={<ArrowDownIcon />}
          color={buttonProps?.color || Grey[50]}
          variant={buttonProps?.variant || 'none'}
          size={buttonProps?.size || 'md'}
          disabled={buttonProps?.disabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          {fixedLabel ? fixedLabel : selectedItem?.label}
        </Button>
      }
      isOpen={isOpen}
      closeOnClickOutside={true}
      onClose={() => setIsOpen(false)}
      placement="bottom-right"
      style={{
        marginTop: '-0.5rem',
        border: `1px solid ${Grey[200]}`,
        background: 'rgba(68, 68, 68, 0.8)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        padding: '0.5rem 0.75rem',
        backdropFilter: 'blur(2px)',
      }}
    >
      <StyledDropdown className="sup-dropdown-list" style={{ padding: 0 }}>
        {items.map((item) => (
          <DropdownItem
            key={item.value}
            isSelected={selectedItem?.value === item.value}
            onClick={() => {
              onChange(item);
              setIsOpen(false);
            }}
            badgeLabel={
              (selectedItem?.value === item.value && <SelectedIcon />) || ''
            }
            label={
              item.thumb ? (
                <span className="label">
                  <span
                    className="thumb"
                    style={{ backgroundImage: `url(${item.thumb})` }}
                  ></span>
                  {item.label}
                </span>
              ) : (
                item.label
              )
            }
          />
        ))}
      </StyledDropdown>
    </Dropdown>
  );
};

export default Select;
