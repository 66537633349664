import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Primary, White } from '../../styles/Colors';
import { FontFamily } from '../../styles/Typography';
import { ButtonProps } from './Button';
import { ButtonGroupProps } from './ButtonGroup';
import { BORDER_RADIUS, FONT_SIZE } from './const';
import { ButtonBaseProps } from './types';

interface StyledButtonProps extends ButtonProps {
  fontSize: string;
}

interface StyledButtonBaseProps extends ButtonBaseProps {
  fontSize: string;
  isFillCurrentColor?: boolean;
}

const commonButtonStyle = <T extends StyledButtonBaseProps>({
  radius = 'md',
  variant = 'contained',
  color = Primary['400'],
  fontSize = FONT_SIZE['md'],
  isFillCurrentColor = true,
}: T) => css`
  /* reset button */
  margin: 0;
  background: none;
  border: none;
  outline: none;
  /* button style */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 150%;
  font-family: ${FontFamily['Inter']};
  border-radius: ${BORDER_RADIUS[radius]};
  ${variant === 'contained'
    ? css`
        border: 1px solid ${color};
        background-color: ${color};
        color: ${White};
      `
    : variant === 'outlined'
    ? css`
        border: 1px solid ${color};
        color: ${color};
      `
    : css`
        color: ${color};
      `}
  // TODO: Add hover, disabled style based on Design System
    /* button hover style */
    &:hover {
    opacity: 0.8;
  }
  /* button disabled style */
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  ${isFillCurrentColor &&
  css`
    & svg * {
      fill: currentColor;
    }
  `}
  svg: {
    width: ${fontSize};
    height: ${fontSize};
  }
`;

export const StyledButton = styled.button(
  <T extends StyledButtonProps>({
    fullWidth,
    radius,
    variant,
    color,
    fontSize,
    fontWeight,
    startIcon,
    endIcon,
    iconWidth,
    iconHeight,
  }: T) => {
    return css`
      ${commonButtonStyle({ radius, variant, color, fontSize })}
      padding: 0.25rem calc(${fontSize} - 0.125rem);
      width: ${fullWidth ? '100%' : 'auto'};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      /* button icon */
      .sup-button-icon {
        width: ${iconWidth ?? `calc(${fontSize} + 0.4rem)`};
        height: ${iconHeight ?? `calc(${fontSize} + 0.4rem)`};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: ${startIcon ? '0.4rem' : '0'};
        margin-left: ${endIcon ? '0.4rem' : '0'};
      }
    `;
  }
);

export const StyledIconButton = styled.button(
  <T extends StyledButtonBaseProps>({
    radius,
    variant,
    color,
    fontSize,
    isFillCurrentColor,
  }: T) => {
    return css`
      ${commonButtonStyle({
        radius,
        variant,
        color,
        fontSize,
        isFillCurrentColor,
      })}
      padding: 0;
      box-sizing: border-box;
      width: calc(${fontSize} + 0.375rem);
      height: calc(${fontSize} + 0.375rem);
      font-size: ${fontSize};
    `;
  }
);

export const StyledButtonGroup = styled.div(
  <T extends ButtonGroupProps>({ radius = 'md' }: T) => {
    return css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      button {
        margin: 0;
        border-radius: 0;
        &:first-of-type {
          border-top-left-radius: ${BORDER_RADIUS[radius]};
          border-bottom-left-radius: ${BORDER_RADIUS[radius]};
        }
        &:last-of-type {
          border-top-right-radius: ${BORDER_RADIUS[radius]};
          border-bottom-right-radius: ${BORDER_RADIUS[radius]};
        }
        &:not(:first-of-type) {
          margin-left: -1px;
        }
      }
    `;
  }
);
