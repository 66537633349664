import IconButton from '@/components/Button/IconButton';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { formatSToMMSS } from '../../../util/formatter';
import { ReactComponent as BackwardIcon } from '../assets/icons/BackwardIcon.svg';
import { ReactComponent as ForwardIcon } from '../assets/icons/ForwardIcon.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/PauseIcon.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/PlayIcon.svg';
import {
  AudioSource,
  useMultiAudioController,
} from '../hooks/useMultiAudioController';
import { useDataContext } from '../providers/DataContextProvider';
import { useTimelineContext } from '../providers/TimelineContextProvider';
import {
  audioFileMapAtom,
  audioPlayerStateAtom,
  currentPlaybackAtom,
  currentPlayingAudioInfoListAtom,
} from '../stores/atoms/audio';
import { lineListAtom, takeListAtom } from '../stores/atoms/project';
import {
  isLoadingProjectAtom,
  isTextEditingAtom,
  timelinePlaybackAtom,
  videoPlayerStateAtom,
} from '../stores/atoms/ui';
import { StyledGlobalAudioPlayer } from '../styles/StyledGlobalAudioPlayer';
import VolumeSlider from './VolumeSlider';

const GlobalAudioPlayer = () => {
  const { activeLineId, projectInfo } = useDataContext();
  const audioFileMap = useRecoilValue(audioFileMapAtom);
  const setCurrentPlayingAudioInfoList = useSetRecoilState(
    currentPlayingAudioInfoListAtom
  );
  const [audioPlayerState, setAudioPlayerState] =
    useRecoilState(audioPlayerStateAtom);
  const setCurrentPlayback = useSetRecoilState(currentPlaybackAtom);
  const [timelinePlayback, setTimelinePlayback] =
    useRecoilState(timelinePlaybackAtom);
  const isTextEditing = useRecoilValue(isTextEditingAtom);
  const { timelineList, musicGainValue } = useTimelineContext();
  const lineList = useRecoilValue(lineListAtom);
  const takeList = useRecoilValue(takeListAtom);
  const currentPlayingAudioInfoList = useRecoilValue(
    currentPlayingAudioInfoListAtom
  );
  const currentPlayingAudio = useMemo(() => {
    if (!timelineList.length) return null;
    const [info] = currentPlayingAudioInfoList;
    for (const tl of timelineList) {
      const item = tl.items.find((item) => item.id === info?.id);
      if (item) {
        return {
          id: item.id,
          type: item.type,
          lineId: item.lineId,
          content: item.content,
          takeLabel: item.takeLabel,
          thumbnail: tl.voice.thumbnail,
        };
      }
    }
    return null;
  }, [timelineList, currentPlayingAudioInfoList]);

  const selectedLine = useMemo(() => {
    return lineList?.find(
      (line) => line.id === activeLineId && !!line.selectedTakeId
    );
  }, [lineList, activeLineId]);

  const isLoadingProject = useRecoilValue(isLoadingProjectAtom);
  const setVideoPlayerState = useSetRecoilState(videoPlayerStateAtom);

  const [isLoaded, setIsLoaded] = useState(false);
  const [audioSources, setAudioSources] = useState<AudioSource[]>([]);

  useEffect(() => {
    setIsLoaded(!isLoadingProject);
  }, [isLoadingProject]);

  const {
    currentPosition,
    totalDuration,
    gainValue,
    isPlaying,
    isNext,
    isPrev,
    currentAudioInfo,
    play,
    pause,
    stop,
    forward,
    backward,
    updateVolume,
    seek,
    updateMusicVolume,
  } = useMultiAudioController({
    audioSources,
  });

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, play, pause]);

  // audioPlayerState에 오디오 재생 상태를 저장
  useEffect(() => {
    setAudioPlayerState({
      type: 'timeline',
      isPlaying,
    });
  }, [isPlaying, setAudioPlayerState]);

  // timeline 외(Line 플레이어 등)에서 재생 중이면 멈춤
  useEffect(() => {
    if (!audioPlayerState) {
      pause();
      return;
    }
    if (audioPlayerState?.type === 'timeline') return;
    if (audioPlayerState?.isPlaying) {
      pause();
    }
  }, [audioPlayerState, pause]);

  useEffect(() => {
    if (!isLoaded || !timelineList?.length) return;
    const sources = timelineList.reduce((acc, { items, voice }) => {
      items.forEach((item) => {
        if (
          (item.type !== 'music' && !audioFileMap[item.id]) ||
          (item.type === 'music' &&
            item.resource_id &&
            !audioFileMap[item.resource_id]) ||
          voice.disabled
        )
          return;
        const audioSource = {
          id: item.id,
          fileName: item.content,
          type: item.type,
          position: item.position,
          duration:
            item.type !== 'music'
              ? audioFileMap[item.id].audioBuffer?.duration || 0
              : item.duration || 0,
          audioBuffer:
            item.type === 'music' && item.resource_id
              ? audioFileMap[item.resource_id].audioBuffer
              : audioFileMap[item.id].audioBuffer,
          ...(item.type !== 'music' && {
            lineId: item.lineId,
          }),
          ...(item.type === 'music' && {
            startOffset: item.startOffset,
            endOffset: item.endOffset,
          }),
        };
        acc.push(audioSource);
      });
      return acc.sort((a, b) => a.position - b.position);
    }, [] as AudioSource[]);
    setAudioSources(sources);
  }, [timelineList, isLoaded, audioFileMap, setAudioSources]);

  useEffect(() => {
    if (!isLoaded) return;
    setCurrentPlayingAudioInfoList(currentAudioInfo);
  }, [currentAudioInfo, setCurrentPlayingAudioInfoList, isLoaded]);

  useEffect(() => {
    setCurrentPlayback(currentPosition);
  }, [currentPosition, setCurrentPlayback]);

  useEffect(() => {
    setVideoPlayerState({
      startPosition: currentPosition,
      isPlaying,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, setVideoPlayerState]);

  useEffect(() => {
    seek(timelinePlayback);
    setVideoPlayerState({
      startPosition: timelinePlayback,
      isPlaying: false,
    });
  }, [timelinePlayback, seek, setVideoPlayerState]);

  // TODO: Register hotkeys (tmp code)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isTextEditing) return;
      switch (e.code) {
        case 'Enter':
          e.preventDefault();
          seek(0);
          break;
        case 'Space':
          e.preventDefault();
          handlePlayPause();
          break;
        case 'ArrowRight':
          e.preventDefault();
          forward();
          break;
        case 'ArrowLeft':
          e.preventDefault();
          backward();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTextEditing, forward, backward, handlePlayPause, seek]);

  useEffect(() => {
    if (!selectedLine) return;
    // 아직 생성되지 않은 line의 경우 이전 line의 position으로 이동
    const generatedLines = lineList.filter((line) => !!line.selectedTakeId);
    if (!selectedLine.selectedTakeId) {
      // 이전 line의 id를 찾아서 해당 line의 position으로 이동
      const currentLineIdx = generatedLines.findIndex(
        (line) => line.id === selectedLine.id
      );
      if (currentLineIdx === -1) return;
      const prevLine = generatedLines[currentLineIdx - 1];
      const prevLineTake = takeList.find(
        (take) => take.id === prevLine?.selectedTakeId
      );
      if (!prevLineTake?.position) return;
      setTimelinePlayback(prevLineTake.position);
      return;
    }
    const selectedLineTake = takeList.find(
      (take) => take.id === selectedLine.selectedTakeId
    );
    if (selectedLineTake?.position) {
      setTimelinePlayback(selectedLineTake.position);
    }
  }, [selectedLine, lineList, takeList, setTimelinePlayback]);

  // audioSource의 변경이 발생한 경우(bgm on/off, timeline에 아이템 추가) 일시정지
  useEffect(() => {
    if (isPlaying) {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSources]);

  // Reset current playback when selected project changed
  useEffect(() => {
    requestAnimationFrame(() => {
      stop();
    });
  }, [projectInfo?.id, stop]);

  useEffect(() => {
    updateMusicVolume(musicGainValue);
  }, [musicGainValue, updateMusicVolume]);

  const progressPosition = useMemo(() => {
    const position =
      totalDuration === 0
        ? -100
        : ((currentPosition - totalDuration) / totalDuration) * 100;
    return position >= 0 ? 0 : position;
  }, [currentPosition, totalDuration]);

  const hasItem = useMemo(() => {
    return takeList.length > 0;
  }, [takeList]);

  return (
    <StyledGlobalAudioPlayer
      className={classNames('sup-player', !hasItem && 'no-takes')}
    >
      <section className="progress">
        <div
          className="progress-bar"
          style={{
            transform: `translateX(${progressPosition}%)`,
          }}
        ></div>
      </section>
      <section className="control-audio">
        <IconButton
          className={classNames('btn-play', isPlaying && 'active')}
          variant="none"
          onClick={handlePlayPause}
          disabled={!isLoaded || !hasItem}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <IconButton
          className="btn-backward"
          variant="none"
          disabled={!isPrev}
          onClick={backward}
        >
          <BackwardIcon />
        </IconButton>
        <IconButton
          className="btn-forward"
          variant="none"
          disabled={!isNext}
          onClick={forward}
        >
          <ForwardIcon />
        </IconButton>
      </section>
      <section
        className={classNames('scene-info', !currentPlayingAudio && 'empty')}
      >
        {currentPlayingAudio && currentPlayingAudio.type === 'tts' && (
          <div className="scene-info-tts">
            <div className="number">{currentPlayingAudio.takeLabel}</div>
            <div
              className="thumb"
              style={{
                background: `url(${currentPlayingAudio.thumbnail}) center center / cover no-repeat`,
              }}
            ></div>
            <div className="content">{currentPlayingAudio.content}</div>
          </div>
        )}
        {!hasItem && (
          <p className="scene-info-empty">
            There is no take to play it. Please generate a new take.
          </p>
        )}
        {currentPlayingAudio && currentPlayingAudio.type === 'music' && (
          <p className="scene-info-audio">PLAYING</p>
        )}
      </section>
      <section className="time-info">
        {`${formatSToMMSS(currentPosition)} / ${formatSToMMSS(totalDuration)}`}
      </section>
      <VolumeSlider value={gainValue} update={updateVolume} />
    </StyledGlobalAudioPlayer>
  );
};
export default GlobalAudioPlayer;
