import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useCallback } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Landing from '../Landing/Landing';

const PrivateOutlet = ({
  requiredPermission,
}: {
  requiredPermission?: string;
}) => {
  const { user, isLoading, error, isAuthenticated } = useSUPAuth();

  const hasPermission = useCallback(() => {
    // 만약 requiredPermission이 없고, 로그인이 되어있다면 permission이 있는 것으로 간주
    if (!requiredPermission) return true;
    let isPermitted = false;
    if (!user?.permissions) return isPermitted;
    // Check if user has required permission
    user.permissions.forEach(
      (permission) =>
        permission.toLowerCase().includes(requiredPermission) &&
        (isPermitted = true)
    );
    return isPermitted;
  }, [requiredPermission, user?.permissions]);

  // 에러 페이지
  // TODO: 추후 여러 에러 상황에 대한 페이지 추가 필요
  if (error) return <Navigate to="/error" />;

  if (isLoading) return <Landing />;

  // 사용자 정보가 없다면 로그인 페이지로 이동
  if (!isLoading && !isAuthenticated)
    return <Navigate to={`/signin?from=${window.location.pathname}`} />;

  // TODO: Voice Toolkit에도 dev계정 필요한지 확인
  // if (user && user.email === 'dev@supertone.ai') return <Outlet />;

  // 승인되지 않은 유저라면 승인 필요 페이지로 이동
  // if (isAuthenticated && user && !isPermitted)
  //   return <Navigate to="/message/needs-approval" />;

  // 승인된 유저라면 요청된 페이지로 이동
  if (isAuthenticated && hasPermission()) return <Outlet />;

  return null;
};

export default PrivateOutlet;
