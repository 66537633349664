import styled from '@emotion/styled';

import { IconButtonProps } from './IconButton';

export const StyledIconButton = styled.button<IconButtonProps>`
  /* button base style */
  margin: 0;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${(props) => props.radius};
  // TODO: Add hover, disabled style based on Design System
  /* button hover style */
  &:hover {
    opacity: 0.8;
  }
  /** custom */
  width: ${(props) => props.width || '1.5rem'};
  height: ${(props) => props.height || '1.5rem'};
  background: none;
  border: ${(props) =>
    props.variant === 'outlined' ? `2px solid ${props.iconColor}` : 'none'};
  &::after {
    content: ' ';
    display: flex;
    background-color: ${(props) => props.iconColor};
    mask-image: url(${(props) => props.icon});
    mask-position: center;
    mask-size: ${(props) => props.iconSize || 'normal'};
    mask-repeat: no-repeat;
    padding: 0.5rem;
    box-sizing: border-box;
    width: ${(props) => props.width || '1.5rem'};
    height: ${(props) => props.height || '1.5rem'};
  }
`;
