/**
 * 특정 엘리먼트의 부모 엘리먼트 중 가장 첫번째로 스크롤이 가능한 엘리먼트를 반환
 *
 * @param element 기준이 되는 엘리먼트
 * @returns HTMLElement | null
 */
export const getFirstScrollableParent = (
  element: HTMLElement | null
): HTMLElement | null => {
  if (!element) return null;

  if (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  ) {
    return element;
  }

  return getFirstScrollableParent(element.parentElement);
};

/**
 * 특정 엘리먼트의 부모 엘리먼트 중 스크롤이 가능한 엘리먼트들을 반환
 *
 * @param element 기준이 되는 엘리먼트
 * @returns HTMLElement[]
 */
export const getScrollableParents = (
  element: HTMLElement | null
): HTMLElement[] => {
  if (!element) return [];

  const parent = getFirstScrollableParent(element);

  if (!parent) return [];

  return [parent, ...getScrollableParents(parent.parentElement)];
};
