import { WebSocketContext } from '@/providers/WebSocketProvider';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import { useCallback, useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { voiceProfileListAtom } from '../stores/atoms/voice';
import { useVoice } from '../stores/recoilHooks/useVoice';
import useAxios from './useAxios';

const useVoiceProfile = () => {
  const { sessionId } = useContext(WebSocketContext);
  const { getVoiceLibrary } = useAxios();
  const setVoiceProfileList = useSetRecoilState(voiceProfileListAtom);
  const { getProfileEditInfoList, addProfileEditInfo } = useVoice();

  const loadVoiceProfileList = useCallback(
    async (id: string) => {
      const profiles = await getVoiceLibrary();

      if (!profiles) return [];
      const projectProfileEditInfoList = await getProfileEditInfoList(id);
      const projectVoiceProfileList = profiles.filter((profile) => {
        const editInfo = projectProfileEditInfoList.find(
          (info) => info.profileId === profile.id
        );
        return !!editInfo;
      });

      // 만약 프로젝트에 등록된 voiceProfile이 없다면, 1개를 추가해준다.
      if (!projectVoiceProfileList.length) {
        const item = profiles[0];
        await addProfileEditInfo({
          id: uuid(),
          profileId: item.id,
          projectId: id,
          selectedVoiceId: item.default_voice_id,
        });
      }
      setVoiceProfileList(profiles);
      return profiles;
    },
    [
      getVoiceLibrary,
      setVoiceProfileList,
      getProfileEditInfoList,
      addProfileEditInfo,
    ]
  );

  const loadVoiceFile = async (url: string) => {
    const audio = await fetchAudio(url);
    const audioBuffer = await getAudioBuffer(audio.arrayBuffer);

    return { audioBuffer: audioBuffer as AudioBuffer };
  };

  return {
    sessionId,
    loadVoiceFile,
    loadVoiceProfileList,
  };
};

export default useVoiceProfile;
