import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { _updateLine } from '../api/project';
import {
  audioFileMapAtom,
  currentPlayingAudioInfoListAtom,
} from '../stores/atoms/audio';
import { lineListAtom, takeListAtom } from '../stores/atoms/project';
import { projectVoiceProfileListAtom } from '../stores/atoms/voice';
import { AudioType } from '../stores/audios';
import { extractFileName } from '../stores/data/musics';
import { ExtendTake, Line, Take } from '../stores/project';
import { useMusic } from '../stores/recoilHooks/useMusic';
import { AudioInfo, TimelineListItem } from '../stores/timeline';
import { Profile } from '../stores/voice';
import { useDataContext } from './DataContextProvider';

// Scene Tab 의 State를 저장한다. active scene id에 따른 동작을 처리한다.
interface TimelineContextProviderProps {
  lineList: Line[];
  takeList: Take[];
  selectLine: (lineId: string) => void;
  selectTake: (takeId: string) => void;
  updateLinePosition: (takeId: string, position: number) => void;
  updateMusicStatus: (disabled: boolean) => void;
  musicInfo: AudioInfo;
  timelineList: TimelineListItem[];
  currentPlayingAudio: {
    id: string;
    type: AudioType;
    lineId: string | undefined;
    content: string | undefined;
    takeLabel: string | undefined;
    thumbnail: string | undefined;
  } | null;
  activeTakeList: ExtendTake[];
  // music gain control
  musicGainValue: number;
  setMusicGainValue: (value: number) => void;
}
const TimelineContext = createContext<TimelineContextProviderProps>(
  {} as TimelineContextProviderProps
);

export const useTimelineContext = () => {
  const context = useContext(TimelineContext);
  if (!context) {
    throw new Error('TimelineContext not supported');
  }
  return context;
};

const TimelineContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [lineList, setLineList] = useRecoilState(lineListAtom);
  const takeList = useRecoilValue(takeListAtom);
  const [musicInfo, setMusicInfo] = useState<AudioInfo>({
    id: 'music',
    name: 'music',
    type: 'music',
    disabled: false,
  });
  const [musicGainValue, setMusicGainValue] = useState(0.3);
  const {
    activeSceneId,
    updateActiveLineId,
    updateActiveTakeId,
    updateLineIndex,
  } = useDataContext();
  const audioFileMap = useRecoilValue(audioFileMapAtom);
  const currentPlayingAudioInfoList = useRecoilValue(
    currentPlayingAudioInfoListAtom
  );
  const { musicList, getMusicList } = useMusic();
  const projectVoiceProfileList = useRecoilValue(projectVoiceProfileListAtom);

  const updateMusicStatus = useCallback(
    (disabled: boolean) => {
      if (!musicInfo) return;
      setMusicInfo({
        ...musicInfo,
        disabled,
      });
    },
    [musicInfo]
  );

  const activeTakeList = useMemo(
    () =>
      lineList.reduce<ExtendTake[]>((acc, line) => {
        if (line.selectedTakeId) {
          const index = takeList.findIndex(
            (take) => take.id === line.selectedTakeId
          );
          const take = takeList[index];
          if (take) {
            acc.push({
              ...take,
              selectedNumber: takeList.length - index,
              audioBuffer: audioFileMap[take.id]?.audioBuffer,
              position: line.position,
            } as ExtendTake);
          }
        }
        return acc;
      }, []),
    [lineList, takeList, audioFileMap]
  );

  const timelineList = useMemo(() => {
    const sortedActiveTakes = activeTakeList.sort((a, b) => {
      if (a.position && b.position) {
        return a.position - b.position;
      }
      // position이 없는 경우는 뒤로 보냄
      return 1;
    });
    // duration 및 line 데이터 셋업
    const list = sortedActiveTakes.reduce<TimelineListItem[]>(
      (acc, take, index) => {
        const profile = projectVoiceProfileList.find((p: Profile) =>
          p.voices.find((v) => v.id === take.voiceId)
        );
        if (!take || !take.audioBuffer) return acc;

        // 여기가 take정보로 치환 하면 되는 부분 => take생성시 position 선행필요 이미 있는 로직이라 Take 생성쪽으로 이동 필요
        const timelineItem = {
          id: take.id,
          type: take.type,
          lineId: take.lineId,
          takeLabel: `${index + 1}-${take.selectedNumber as number}`,
          content: take.text,
          position: take.position || 0,
          duration: take.audioBuffer?.duration || 0,
        };

        const timeline = acc.find((item) => item.voice.id === profile?.id);
        if (timeline) {
          timeline.items.push(timelineItem);
        } else {
          const thumbnail = profile?.thumbnails.find(
            (t) => t.purpose === 'icon'
          )?.path;

          acc.push({
            voice: {
              id: profile?.id || '',
              name: profile?.name || '',
              type: 'voice',
              thumbnail,
            },
            items: [timelineItem],
          });
        }
        return acc;
      },
      []
    );

    let musics: TimelineListItem | undefined;
    if (musicList.length) {
      musics = {
        voice: musicInfo,
        items: musicList
          .map((music) => {
            const resourceId = (
              music.url ? extractFileName(music.url) : music.resource_id
            ) as string;
            const audioFile = audioFileMap[resourceId];
            if (!audioFile) return undefined;
            return {
              id: music.id,
              type: 'music',
              content: music.name,
              position: music.position || 0,
              duration: audioFile.audioBuffer?.duration || 0,
              startOffset: music.startOffset,
              endOffset: music.endOffset,
              resource_id: resourceId,
            };
          })
          .filter((item) => item !== undefined),
      } as TimelineListItem;
    }

    return musics ? [...list, musics] : list;
  }, [
    musicInfo,
    musicList,
    audioFileMap,
    projectVoiceProfileList,
    activeTakeList,
  ]);

  const currentPlayingAudio = useMemo(() => {
    if (!timelineList.length) return null;
    const [info] = currentPlayingAudioInfoList;
    for (const tl of timelineList) {
      const item = tl.items.find((item) => item.id === info?.id);
      if (item) {
        return {
          id: item.id,
          type: item.type,
          lineId: item.lineId,
          content: item.content,
          takeLabel: item.takeLabel,
          thumbnail: tl.voice.thumbnail,
        };
      }
    }
    return null;
  }, [timelineList, currentPlayingAudioInfoList]);

  const updateLinePosition = useCallback(
    async (lineId: string, position: number) => {
      const line = lineList.find((line) => line.id === lineId);
      const result = (await _updateLine({ ...line, position })) as Line;
      setLineList((prev) => {
        return prev.map((line) => {
          if (line.id === result.id) {
            return result;
          }
          return line;
        });
      });
      updateLineIndex();
    },
    [lineList, updateLineIndex, setLineList]
  );

  // 동일로직이 있으므로 훅으로 분리 필요
  // 프로젝트 변경사항 발생시 데이터 로딩
  useEffect(() => {
    // scene 정보가 없어질거라(단일 scene이 될거라 필요없어질 코드)
    getMusicList(activeSceneId as string);
  }, [activeSceneId, getMusicList]);

  return (
    <TimelineContext.Provider
      value={{
        takeList,
        lineList,
        selectLine: updateActiveLineId,
        selectTake: updateActiveTakeId,
        updateMusicStatus,
        updateLinePosition,
        musicInfo,
        timelineList,
        currentPlayingAudio,
        activeTakeList,
        musicGainValue,
        setMusicGainValue,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};
export default TimelineContextProvider;
