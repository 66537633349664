import { Grey } from '@/styles/Colors';
import React, { useId } from 'react';

import StyledCheckbox from './StyledCheckBox';

export interface CheckboxProps {
  className?: string;
  color?: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  caption?: string;
}
const Checkbox: React.FC<CheckboxProps> = ({
  className,
  caption,
  color,
  checked,
  disabled,
  onChange,
}) => {
  const checkboxId = useId();
  return (
    <StyledCheckbox className={className} color={disabled ? Grey[200] : color}>
      <input
        type="checkbox"
        id={checkboxId}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.checked)}
      />
      <label htmlFor={checkboxId}>
        <span className="screen-out">{checked ? 'checked' : 'unchecked'}</span>
      </label>
      {caption && <span className="checkbox-caption">{caption}</span>}
    </StyledCheckbox>
  );
};
export default Checkbox;
