import Button from '@/components/Button/Button';
import { StyledModal } from '@/components/Modal/StyledModal';
import Title from '@/components/Title/Title';
import useUserTier from '@/hooks/useUserTier';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { showPayModal } from '@/pages/screenplay/stores/atoms/ui';
import { WS_EVENTS, useWebSocketContext } from '@/providers/WebSocketProvider';
import { Grey } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { upload } from '../../../api';
import { ReactComponent as VoiceIcon } from '../assets/icons/Voice.svg';
import useAxios from '../hooks/useAxios';
import { useGenerateInfo } from '../hooks/useGenerateInfo';
import { useDataContext } from '../providers/DataContextProvider';
import {
  TakeOption,
  useSceneEditorContext,
} from '../providers/SceneEditorContextProvider';
import { Language } from '../stores/data/config';
import CvcLayer from './CvcLayer';
import LanguageDropDown from './LanguageDropDown';
import SpeechControls from './SpeechControl';
import StyleDropDown from './StyleDropDown';
import { StyledTakeGeneratePanel } from './StyledTakeGeneratePanel';

export const SPEECH_CONTROL_LIST = [
  {
    id: 'pitch_shift',
    title: 'Pitch Shift',
    min: -24,
    max: 24,
    step: 1,
    defaultValue: 0,
  },
  {
    id: 'pitch_variance',
    title: 'Pitch Variance',
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
  {
    id: 'speed',
    title: 'Speed',
    value: 1,
    min: 0.5,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
];
const DEFAULT_PARAMETER = {
  pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
  pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
  speed: SPEECH_CONTROL_LIST[2].defaultValue,
};
const TakeGeneratePanel = () => {
  const { track } = useLog();
  const { t } = useTranslation();
  const { activeLineId } = useDataContext();
  const { lineList, updateDraft, addTakes, controlState, setControlState } =
    useSceneEditorContext();

  const setShowPayModal = useSetRecoilState(showPayModal);
  const { isTrial, isNeedPurchasing } = useUserTier();

  const { language, voiceId, profile, activeTake, style } = useGenerateInfo();
  // 현재 선택된 라인, TakeGeneratePanel의 가장 기본데이터
  // selectedLineId가 없을 경우 해당 패널을 그리지 않기 때문에 Line 형으로 로 간주
  const selectedLine = useMemo(
    () => lineList.find((line) => line.id === activeLineId),
    [lineList, activeLineId]
  );

  // 선택된 라인 인덱스(타이틀에 표기하기 위함)
  const lineIndex = useMemo(() => {
    return lineList.findIndex((line) => line.id === activeLineId);
  }, [lineList, activeLineId]);

  // draft 상태에 따른 text
  const text = useMemo(() => {
    return selectedLine?.draft?.text || activeTake?.text || '';
  }, [selectedLine, activeTake]);

  const { getUploadInfo } = useAxios();

  // active Take가 변경되면 해당 Take의 SpeechControl을 설정한다.
  useEffect(() => {
    setControlState(selectedLine?.draft?.parameter || DEFAULT_PARAMETER);
  }, [selectedLine?.draft?.parameter, setControlState]);

  const updateLanguage = useCallback(
    (language: Language) => {
      activeLineId &&
        updateDraft(activeLineId, {
          language,
        });

      track('SELECT_LANGUAGE', {
        voiceId: voiceId,
        profileId: profile?.id,
        voiceName: profile?.name,
        language: profile?.language,
      });
    },
    [
      updateDraft,
      activeLineId,
      profile?.id,
      profile?.name,
      profile?.language,
      track,
      voiceId,
    ]
  );

  const updateStyle = useCallback(
    (style: string) => {
      const voice = profile?.voices.find((voice) => voice.style === style);
      activeLineId &&
        updateDraft(activeLineId, {
          voiceId: voice?.id,
        });
    },
    [updateDraft, activeLineId, profile]
  );

  // addTake
  // 원래 multi take가 가능했어서 여러 take를 추가할 수 있었으나, 현재(6월 스프린트 이후)는 한개의 take만 추가 가능하다.
  const addTake = useCallback(
    async (sourceId?: string) => {
      if (selectedLine) {
        const draft = { ...(activeTake || {}), ...(selectedLine?.draft || {}) };
        // 추가된 take를 기본 take 설정
        addTakes({
          ...draft,
          voiceId,
          parameter: { ...controlState },
          type: draft?.source_id || sourceId ? 'cvc' : 'tts',
          resource_id: draft?.source_id || sourceId,
          language,
        } as TakeOption);
      }
    },
    [selectedLine, addTakes, controlState, activeTake, voiceId, language]
  );

  // Draft 선택 여부에 따른
  const { pitch_shift, pitch_variance, speed } = useMemo(
    () => controlState,
    [controlState]
  );

  // 현재 선택된 라인의 SpeechControl 파라미터를 변경한다.
  const onChangeLineParameters = useCallback(
    (key: string) => (value: number) => {
      setControlState((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [setControlState]
  );
  const variantsMap: Record<
    string,
    {
      value: number;
      change: (value: number) => void;
    }
  > = useMemo(() => {
    return {
      pitch_shift: {
        value: pitch_shift,
        change: onChangeLineParameters('pitch_shift'),
      },
      pitch_variance: {
        value: pitch_variance,
        change: onChangeLineParameters('pitch_variance'),
      },
      speed: {
        value: speed,
        change: onChangeLineParameters('speed'),
      },
    };
  }, [pitch_shift, pitch_variance, speed, onChangeLineParameters]);

  const controls = useMemo(() => {
    return SPEECH_CONTROL_LIST.map((item) => {
      const { value, change } = variantsMap[item.id];
      return {
        ...item,
        value,
        change,
      };
    });
  }, [variantsMap]);

  const source_id = useMemo(() => {
    return activeTake?.source_id;
  }, [activeTake]);

  const [isOpenCVCLayer, setIsOpenCVCLayer] = useState(false);

  const openCvcLayer = useCallback(() => {
    setIsOpenCVCLayer(true);
  }, []);

  // 레이어가 분리되면, 기능이 분리되는 문제가 생긴다, cvc가 별도의 트랙을 타는 만큼 cvc를 선택 했을 때, 또 별도의 트랙을 태워야 하는 이슈가 생길수 있음(추상층 분리의 실패)
  // 이 문제를 해결하려면 일단 동일한 UX내부에 녹여져야 할 필요가 있어보임, 혹은 간단하게 text수정시 draft를 선택상태로 변경하는 방법도 있음 -> cvc파일의 경우 draft를 허용하지 않는다...?
  const closeCvcLayer = useCallback(() => {
    setIsOpenCVCLayer(false);
  }, []);

  const { sessionId, on, off } = useWebSocketContext();
  const [uploadState, setUploadState] = useState<
    Record<string, 'fetching' | 'done'>
  >({});

  const [uploadResourceId, setUploadResourceId] = useState<string>('');

  const addRecordFile = useCallback(
    async (files: File[]) => {
      // cvc인 경우 파일을 업로드 한다.
      const uploadInfo = await getUploadInfo(
        sessionId,
        files[0].name,
        files[0].type
      );
      const { resource_id, upload_url } = uploadInfo.data.data;
      setUploadResourceId(resource_id);
      setUploadState({
        [resource_id]: 'fetching',
      });
      await upload(upload_url, files[0]);
    },
    [sessionId, getUploadInfo]
  );

  useEffect(() => {
    const complete = ({ resource_id }: any) => {
      if (uploadState[resource_id] === 'fetching') {
        setUploadState((prev) => {
          if (prev[resource_id] === 'fetching') {
            return {
              ...prev,
              [resource_id]: 'done',
            };
          } else {
            return prev;
          }
        });
      }
    };
    on(WS_EVENTS.JOB_DONE, complete);
    return () => {
      off(WS_EVENTS.JOB_DONE, complete);
    };
  }, [
    on,
    off,
    uploadState,
    activeLineId,
    addTakes,
    setUploadState,
    updateDraft,
  ]);

  const onClickGenerate = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isTrial && isNeedPurchasing) {
        setShowPayModal(true);
        track('BUY_GENERATE', {});
        return;
      }

      addTake();
    },
    [addTake, isNeedPurchasing, isTrial, setShowPayModal, track]
  );

  return (
    <StyledTakeGeneratePanel className="take-generate-panel">
      <section className="take-generate-header">
        <Title size="md" className="speech-section-title">
          Line # {lineIndex + 1}
        </Title>
      </section>
      <section
        className={classNames(
          'take-generate-content',
          source_id && 'disabled-tts'
        )}
      >
        <section className="take-generate-content-section take-voice-setting">
          <LanguageDropDown value={language} update={updateLanguage} />
          <StyleDropDown
            list={profile?.voices.map(({ style }) => style)}
            value={style}
            update={updateStyle}
          />
        </section>
        <SpeechControls controls={controls} />

        <section className="take-cvc-control-buttons">
          <Button
            onClick={() => {
              openCvcLayer();
            }}
            endIcon={<VoiceIcon />}
            variant="outlined"
            color={Grey['400']}
            className="btn-cvc-layer-open"
          >
            Record or Import Audio
          </Button>
        </section>
        <section className="area-btn-generate">
          <Button
            disabled={!text}
            size="lg"
            className="btn-generate"
            onClick={onClickGenerate}
          >
            {t(selectedLine?.selectedTakeId ? 'Regenerate' : 'Generate')}
          </Button>
        </section>
      </section>
      {isOpenCVCLayer && (
        <StyledModal isPortal={true}>
          <section className="cvc-modal-wrapper">
            <section
              className="modal-overlay"
              onClick={closeCvcLayer}
            ></section>
            <section className="modal">
              <CvcLayer
                closeCvcLayer={closeCvcLayer}
                addFile={addRecordFile}
                uploadState={uploadState}
                uploadResourceId={uploadResourceId}
                addTake={addTake}
                reset={() => setUploadResourceId('')}
              />
            </section>
          </section>
        </StyledModal>
      )}
    </StyledTakeGeneratePanel>
  );
};
export default TakeGeneratePanel;
