import { Grey, PureBlack, White } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';
export const StyledSubTable = styled.section`
  margin-top: 0.5rem;
  width: 100%;
  background-color: ${PureBlack};
  display: flex;
  flex: auto;
  overflow: auto;
  flex-direction: column;
  .sup-files-control {
    background-color: ${Grey[500]};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    overflow: hidden;
    .sup-files-buttons {
      display: flex;
    }
    .sup-files-count {
      margin-left: 1rem;
      font-weight: ${FontWeight.SemiBold};
    }
  }
  .sup-files-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.625rem 1rem;
    border-bottom: 1px solid ${White};
    align-items: center;
    font-weight: ${FontWeight.SemiBold};
    .sup-files-name,
    .sup-files-length {
      color: ${Grey[400]};
    }
  }
`;
