import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { Input } from '@/components/FileInput';
import DropZone from '@/components/FileInput/DropZone';
import Record from '@/components/FileInput/Record';
import Loading from '@/components/Loading/Loading';
import { Grey, White } from '@/styles/Colors';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchAudio, getAudioBuffer } from '../../../util/audio';
import { ReactComponent as BackIcon } from '../assets/icons/BackIcon.svg';
import { ReactComponent as ChangeIcon } from '../assets/icons/ChangeIcon.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';
import { ReactComponent as ImportIcon } from '../assets/icons/ImportIcon.svg';
import { ReactComponent as MICIcon } from '../assets/icons/MICIcon.svg';
import { ReactComponent as RecordIcon } from '../assets/icons/RecordIcon.svg';
import { ReactComponent as SimplePlayIcon } from '../assets/icons/SimplePlayIcon.svg';
import { ReactComponent as SimpleStopIcon } from '../assets/icons/SimpleStopIcon.svg';
import useAxios from '../hooks/useAxios';
import useSingleAudioController from '../hooks/useSingleAudioController';
import LoadingDots from '../VoiceLibrary/Loading';
import { StyledCVCModal } from './StyledTakeGeneratePanel';

interface CvcLayerProps {
  closeCvcLayer: () => void;
  uploadState: Record<string, 'fetching' | 'done' | 'error'>;
  uploadResourceId: string;
  addTake: (uploadResourceId: string) => void;
  addFile: (files: File[]) => void;
  reset: () => void;
}
const CvcLayer: React.FC<CvcLayerProps> = ({
  closeCvcLayer,
  uploadState,
  uploadResourceId,
  addTake,
  addFile,
  reset,
}) => {
  const { t } = useTranslation();
  const isUploading = useMemo(() => {
    return !!uploadResourceId && uploadState[uploadResourceId] === 'fetching';
  }, [uploadState, uploadResourceId]);
  const isUploaded = useMemo(() => {
    return !!uploadResourceId && uploadState[uploadResourceId] === 'done';
  }, [uploadState, uploadResourceId]);

  const [layerStatus, setLayerStatus] = useState<
    'record' | 'record-end' | undefined
  >();
  const [isRecording, setIsRecording] = useState(false);

  const { play, stop, isPlaying, audioBuffer } = useSingleAudioController();
  const [isLoading, setIsLoading] = useState(false);
  const { getResourceInfo } = useAxios();

  const fetchResource = useCallback(async () => {
    setIsLoading(true);
    const { data } = await getResourceInfo(uploadResourceId);
    if (data.data.transcoded.length > 0) {
      const audio = await fetchAudio(data.data.transcoded[0].url);
      const audioBuffer = (await getAudioBuffer(
        audio.arrayBuffer
      )) as AudioBuffer;
      setIsLoading(false);
      return audioBuffer;
    }
  }, [uploadResourceId, getResourceInfo]);

  const handlePlayStop = useCallback(async () => {
    if (isPlaying) {
      stop();
    } else {
      const buffer = audioBuffer ? audioBuffer : await fetchResource();
      play(0, buffer);
    }
    return () => {
      stop();
    };
  }, [isPlaying, audioBuffer, play, stop, fetchResource]);

  const [fileName, setFileName] = useState<string>('');
  const onAddFile = useCallback(
    (files: File[]) => {
      setFileName(files[0].name);
      addFile(files);
    },
    [addFile]
  );

  return (
    <StyledCVCModal className="modal-cvc-layer">
      <section className="cvc-layer-title">
        <section className="cvc-layer-title-left">
          {layerStatus === 'record' && !isRecording && (
            <Button
              color={White}
              style={{ border: 0 }}
              variant="outlined"
              onClick={() => setLayerStatus(undefined)}
            >
              <BackIcon />
            </Button>
          )}
          <span className="title-text">Direct with Audio</span>
        </section>
        <section className="cvc-layer-title-right">
          <IconButton
            variant="none"
            className="btn-close"
            onClick={() => {
              reset();
              closeCvcLayer();
            }}
            color={White}
          >
            <CloseIcon />
          </IconButton>
        </section>
      </section>
      <section className="modal-cvc-content">
        {!isUploaded && !isUploading ? (
          <section className="content-wrapper">
            {!layerStatus && (
              <section className="item-uploader">
                <section className="uploader-item record">
                  <p className="uploader-item-title">
                    {t('Record your own voice')}
                  </p>
                  <section className="icon-area">
                    <MICIcon />
                  </section>
                  <button
                    className="btn-reset btn-record"
                    onClick={() => setLayerStatus('record')}
                  >
                    <RecordIcon /> {t('Record Voice')}
                  </button>
                </section>
                <DropZone
                  addFiles={onAddFile}
                  accept=".wav"
                  className="uploader-item  add-file"
                >
                  <p className="uploader-item-title">
                    {t('Drop a file here or browse')}
                  </p>
                  <section className="icon-area">
                    <ImportIcon />
                  </section>
                  <Input
                    addFiles={onAddFile}
                    className="btn-add-file"
                    caption={'Upload File'}
                    accept=".wav"
                  />
                </DropZone>
              </section>
            )}
            {(layerStatus === 'record' || layerStatus === 'record-end') && (
              <section className="uploader-item record">
                {!isRecording && layerStatus !== 'record-end' ? (
                  <section className="item-state">
                    {t('Click Record button to start recording your voice.')}
                  </section>
                ) : (
                  <section className="item-state fetching">
                    <section className="fetching-title">
                      <Loading />
                      {t('Recording')}
                    </section>
                  </section>
                )}
                <section className="modal-cvc-footer">
                  {layerStatus === 'record' && (
                    <Record
                      addFiles={addFile}
                      captions={[t('Record'), t('Stop')]}
                      defaultName="Recorded File"
                      usePrompt={false}
                      className="recorder"
                      onStartRecording={() => {
                        setIsRecording(true);
                      }}
                      onStopRecording={() => {
                        setIsRecording(false);
                        setLayerStatus('record-end');
                      }}
                    />
                  )}
                </section>
              </section>
            )}
          </section>
        ) : (
          <section className="content-wrapper">
            {isUploading && (
              <>
                <section className="item-state fetching">
                  <section className="fetching-title">
                    <Loading />
                    {t('Uploading')}
                  </section>
                </section>
                <section className="modal-cvc-footer center">
                  <Button
                    className="btn-cancel"
                    color={Grey[50]}
                    variant="outlined"
                    onClick={() => {
                      setLayerStatus(undefined);
                      reset();
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                </section>
              </>
            )}
            {isUploaded && (
              <>
                <section className="item-state result">
                  <section className="result-title">
                    {layerStatus === 'record-end' ? (
                      t('Recording complete')
                    ) : (
                      <span className="file-name">{fileName}</span>
                    )}
                  </section>
                </section>
                <section className="modal-cvc-footer">
                  <section className="left-area">
                    <Button
                      className="btn-file-play"
                      color={White}
                      variant="outlined"
                      startIcon={
                        isPlaying ? <SimpleStopIcon /> : <SimplePlayIcon />
                      }
                      onClick={handlePlayStop}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <LoadingDots />
                      ) : isPlaying ? (
                        t('Stop')
                      ) : (
                        t('Play')
                      )}
                    </Button>
                  </section>
                  <section className="right-area">
                    <Button
                      className="btn-change-file"
                      color={White}
                      variant="outlined"
                      startIcon={<ChangeIcon />}
                      onClick={() => {
                        reset();
                        setLayerStatus(undefined);
                      }}
                    >
                      {t('Change Audio')}
                    </Button>
                    <Button
                      className="btn-add-new-take"
                      onClick={() => {
                        addTake(uploadResourceId);
                        reset();
                        closeCvcLayer();
                      }}
                    >
                      {t('Add New Take')}
                    </Button>
                  </section>
                </section>
              </>
            )}
          </section>
        )}
      </section>
    </StyledCVCModal>
  );
};

export default CvcLayer;
