import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { StyledTitle } from './StyledTitle';

export interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  size: 'lg' | 'md' | 'sm';
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: ReactNode;
  color?: string;
  className?: string;
}

const Title = ({ size, className, ...rest }: PropsWithChildren<TitleProps>) => {
  return (
    <StyledTitle
      size={size}
      className={classNames('sup-title', `sup-title-${size}`, className)}
      {...rest}
    ></StyledTitle>
  );
};

export default Title;
