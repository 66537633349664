import { Grey, Primary } from '@/styles/Colors';
import styled from '@emotion/styled';

import PlayingIcon from '../assets/icons/PlayingIcon.svg';
import SingleTakeIcon from '../assets/icons/SingleTakeIcon.svg';

export const StyledGlobalAudioPlayer = styled.section`
  background-color: ${Grey[700]};
  box-shadow: 0px -16px 24px 0px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  min-width: 103.75rem;
  height: 4.5rem;
  width: calc(100vw - 7.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  &.no-takes {
    .scene-info {
      justify-content: center !important;
    }
    .scene-info-empty {
      text-align: center;
      color: ${Grey[450]};
    }
  }
  section {
    height: calc(100% - 0.125rem);
    :last-child {
      border-left: 1px solid ${Grey[500]};
    }
  }
  .progress {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0.125rem;
    border: none;
    background: ${Grey[500]};
    .progress-bar {
      width: 100%;
      height: 100%;
      background: ${Primary[400]};
    }
  }
  .panel-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.25rem;
    button {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.25rem;
      display: flex;
      color: ${Grey[400]};
      &.icon-timeline {
        padding: 0;
        &.active {
          transform: rotate(180deg);
        }
      }
      &:hover,
      &.active {
        color: ${Grey[50]};
      }
    }
  }
  .control-audio {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    button {
      width: 3.25rem;
      height: 4.25rem;
      color: ${Grey[400]};
      border-radius: unset;
      svg {
        width: 1.375rem;
        height: 1.375rem;
      }
      &:hover {
        background-color: ${Grey[400]};
        color: ${Grey[800]};
        &[disabled] {
          background: none;
        }
      }
      &.btn-play {
        color: ${Grey[50]};
        &:hover {
          color: ${Grey[800]};
          background-color: ${Grey[50]};
        }
        &:disabled:hover {
          color: ${Grey[500]};
          background-color: transparent;
        }
      }
      &.active {
        background-color: ${Grey[50]};
        color: ${Grey[800]};
      }
      &[disabled] {
        background: none;
        opacity: 1;
        color: ${Grey[500]};
      }
    }
  }
  .scene-info {
    padding: 0 2rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-right: none;
    &.empty {
      justify-content: flex-end;
    }
    &-tts {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      .number {
        margin-right: 3rem;
        white-space: nowrap;
        display: flex;
        align-items: center;
        &:before {
          content: '';
          display: inline-block;
          width: 1.2rem;
          height: 2.5rem;
          background: url(${SingleTakeIcon}) center center / contain no-repeat;
          margin-right: 0.5rem;
        }
      }
      .thumb {
        box-sizing: border-box;
        width: 2.5rem;
        min-width: 2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
        background-color: ${Grey[500]};
      }
      .content {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    &-audio {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      &::before {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background: url(${PlayingIcon}) no-repeat center center;
        background-size: contain;
        margin-right: 0.8rem;
      }
    }
  }
  .time-info {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-variant-numeric: tabular-nums;
  }
`;
