import VoiceDefaultImage from '../../assets/images/VoiceDefaultImage.svg';
import { TimelineListItem } from '../../stores/timeline';
import { COLUMN_HEIGHT, X_AXIS_HEIGHT } from './const';
import MusicAxisItem from './MusicAxisItem';

interface AudioAxisProps {
  data: TimelineListItem[];
}

const AudioAxis = ({ data }: AudioAxisProps) => {
  return (
    <ul className="timeline-axis-y">
      {data?.length &&
        data.map(({ voice }, index) => {
          return (
            <li
              key={`voice-${voice.id}`}
              className="axis-y-item"
              style={{
                transform: `translate(0, ${
                  index * COLUMN_HEIGHT + X_AXIS_HEIGHT
                })`,
              }}
            >
              {voice.type === 'voice' ? (
                <img
                  src={voice.thumbnail || VoiceDefaultImage}
                  alt={voice.name}
                />
              ) : (
                <MusicAxisItem voice={voice} />
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default AudioAxis;
