import styled from '@emotion/styled';

import { Black, Grey } from '../../styles/Colors';
import { Z_INDEX } from '../../styles/const';
import { customScrollbar, ellipsis } from '../../styles/mixins';
import { FontSize, FontWeight } from '../../styles/Typography';

export const StyledDropdownLayer = styled.div`
  box-sizing: border-box;
  /* padding: 0.4rem 0.2rem 0.4rem 0.6rem; */
  padding: 0.5rem 0.25rem 0.5rem 0.75rem;

  background-color: ${Grey[450]};
  border: 1px solid ${Grey[200]};
  border-radius: 0.25rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);

  position: absolute;
  overflow: hidden;
  z-index: ${Z_INDEX.DROPDOWN_LAYER};

  display: flex;
  flex-direction: column;

  // DropdownHeader
  .sup-dropdown-header {
    ${customScrollbar}

    box-sizing: border-box;
    margin-right: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid ${Grey[50]};
    overflow: auto;

    color: ${Grey[50]};
    font-size: ${FontSize['Md']};
    line-height: 150%;

    flex-shrink: 0;
  }

  // DropdownList
  ul.sup-dropdown-list {
    ${customScrollbar}

    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 0.5rem 0 0;
    height: 100%;
    overflow: auto;

    flex-grow: 1;

    // DropdownItem
    li.sup-dropdown-item {
      list-style: none;

      font-size: ${FontSize['Md']};
      line-height: 150%;
      background-color: transparent;
      color: ${Grey[50]};
      padding: 0.6rem 1rem;
      border-radius: 0.25rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.75rem;

      transition: 0.1s ease-in-out;
      transition-property: background-color, color;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: ${Grey[500]};
      }

      &.selected {
        background-color: ${Black};

        span.sup-dropdown-item-label {
          font-weight: ${FontWeight['SemiBold']};
        }
      }

      span.sup-dropdown-item-label {
        ${ellipsis()}
        flex-grow: 1;
      }

      span.sup-dropdown-item-badge-label {
        flex-shrink: 0;
        color: ${Grey[400]};
      }
    }

    // DropdownSectionTitle
    li.sup-dropdown-section-title {
      list-style: none;
      padding: 0.6rem 1rem 0.6rem 0.5rem;
      font-size: ${FontSize['Md']};
      line-height: 150%;
      color: ${Grey[400]};
      user-select: none;
    }
  }
`;
