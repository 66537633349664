import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import {
  _addMusicFiles,
  _deleteMusicFile,
  _loadMusicFiles,
  _updateMusicFile,
} from '../../api/project';
import { musicFileListAtom } from '../atoms/music';
import { defaultMusicFileList } from '../data/musics';
import { MusicFile } from '../project';

export const useMusicFile = () => {
  const [musicFileList, setMusicFileList] = useRecoilState(musicFileListAtom);
  const getMusicFileList = useCallback(
    async (projectId: string) => {
      const result = (await _loadMusicFiles(projectId)) as MusicFile[];
      setMusicFileList([...defaultMusicFileList, ...result]);
      return result;
    },
    [setMusicFileList]
  );

  const addMusicFiles = useCallback(
    async (value: MusicFile[]) => {
      const result = (await _addMusicFiles(value)) as MusicFile[];
      setMusicFileList([...defaultMusicFileList, ...result]);
    },
    [setMusicFileList]
  );

  const updateMusicFile = useCallback(
    async (value: MusicFile) => {
      const result = (await _updateMusicFile(value)) as MusicFile[];
      setMusicFileList([...defaultMusicFileList, ...result]);
    },
    [setMusicFileList]
  );

  const deleteMusicFile = useCallback(
    async (value: MusicFile) => {
      const result = (await _deleteMusicFile(value)) as MusicFile[];
      setMusicFileList([...defaultMusicFileList, ...result]);
    },
    [setMusicFileList]
  );
  return {
    musicFileList,
    getMusicFileList,
    addMusicFiles,
    updateMusicFile,
    deleteMusicFile,
  };
};
