import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Header from './screenplay/components/Header';
import QuickMenu from './screenplay/components/QuickMenu';
import { isShowFeedbackAtom } from './screenplay/stores/atoms/ui';
import { StyledLayout } from './screenplay/styles/StyledLayout';

const Layout: React.FC<PropsWithChildren> = () => {
  const isShowFeedback = useRecoilValue(isShowFeedbackAtom);
  return (
    <StyledLayout className={isShowFeedback ? 'feedback-open' : ''}>
      <Header />
      <QuickMenu />
      <section className="layout-content">
        <Outlet />
      </section>
    </StyledLayout>
  );
};
export default Layout;
