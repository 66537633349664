import PermissionError from '@/error/PermissionError';
import {
  exportAudioBufferToFile,
  getAudioBuffer,
  getAudioContext,
} from '@/util/audio';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useCallback, useEffect, useMemo } from 'react';

import { ResourceFileResponse, ResourceResponse, upload } from '../../../api';
import { CVCParams, TTSParams, ZeroShotParameter } from '../api';
import {
  _deleteLine,
  _deleteProfileEditInfo,
  _deleteTake,
  _loadLines,
  _loadProfileEditInfoList,
  _loadTakes,
} from '../api/project';
import { SPEECH_CONTROL_LIST } from '../ControlPanel/TakeGeneratePanel';
import {
  DEFAULT_VOICE,
  Language,
  MEME_VOICE,
  PERMISSION_ERROR_CODE,
} from '../stores/data/config';
import { Profile, ProfilesResponse, VoiceTtsRequest } from '../stores/voice';
import { languagePriority } from '../VoiceLibrary/const';

type ZeroShotLanguage = 'ko-nn' | 'en-us' | 'ja';
const languageMap: Record<Language, ZeroShotLanguage> = {
  ko: 'ko-nn',
  en: 'en-us',
  ja: 'ja',
};

const createInstances = () => ({
  screenplay: axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
  }),
  shift: axios.create({
    baseURL: process.env.REACT_APP_SHIFT_API_HOST,
  }),
  voiceLibrary: axios.create({
    baseURL: process.env.REACT_APP_VOICE_LIBRARY_API_HOST,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      'service-name': 'screenplay',
    },
  }),
  zeroShot: axios.create({
    baseURL: process.env.REACT_APP_ZERO_SHOT_API_HOST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'arraybuffer',
  }),
});

const useAxios = () => {
  const { getAccessTokenSilently } = useSUPAuth();
  const instances = useMemo(() => createInstances(), []);

  useEffect(() => {
    const interceptorIDs: Record<string, number> = {};
    const setupInterceptors = (instance: AxiosInstance, key: string) => {
      const requestInterceptorId = instance.interceptors.request.use(
        async (config: InternalAxiosRequestConfig) => {
          try {
            const token = await getAccessTokenSilently();
            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          } catch (error) {
            return Promise.reject(error);
          }
          return config;
        },
        (error: any) => {
          return Promise.reject(error);
        }
      );
      interceptorIDs[key] = requestInterceptorId;
    };

    Object.entries(instances).forEach(([key, instance]) => {
      if (key === 'zeroShot') return;
      setupInterceptors(instance, key);
    });

    return () => {
      Object.entries(instances).forEach(([key, instance]) => {
        if (key === 'zeroShot') {
          instance.interceptors.request.eject(interceptorIDs[key]);
        }
      });
    };
  }, [getAccessTokenSilently, instances]);

  const getVoiceLibrary = useCallback(async () => {
    try {
      const { data } = await instances.voiceLibrary.get<ProfilesResponse>(
        '/profiles'
      );

      // tmp: sort 인터널 툴에서 처리하기 전 임시로 사용
      // a-z, profile.language -> ko > en > ja
      const profiles = data.data.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name);
        if (nameComparison !== 0) return nameComparison;
        return languagePriority[a.language] - languagePriority[b.language];
      });

      // [workaround]
      // profiles 과 voiceId를 돌며 take와 라인의 무결성을 검사한다.
      // 무결성이 보장되지 않는 Take와 라인 ProfileEditInfo의 아이템들을 제거한다.
      const voiceList = data.data.reduce((acc, profile) => {
        return acc.concat(profile.voices.map((voice) => voice.id));
      }, [] as string[]);
      // voice포함한 테이크 제거
      const takeList = await _loadTakes();
      takeList?.forEach(async (take) => {
        if (!voiceList.includes(take.voiceId)) {
          await _deleteTake(take.id);
        }
      });
      // voice포함한 라인 제거
      const lineList = await _loadLines();
      lineList?.forEach(async (line) => {
        if (line.draft?.voiceId && !voiceList.includes(line.draft?.voiceId)) {
          await _deleteLine(line.id);
        }
      });
      // profile 포함한 profileEditInfo 제거
      const profileEditInfo = await _loadProfileEditInfoList();
      profileEditInfo?.forEach(async (info) => {
        if (!profiles.some((p) => p.id === info.profileId)) {
          await _deleteProfileEditInfo(info.id);
        }
      });

      const assignDisplayName = (profile: Profile) => ({
        ...profile,
        displayName: profile.name
          .replace(MEME_VOICE, '')
          .replace(DEFAULT_VOICE, ''),
      });
      /**
       * []Meme], []Deafult]
       * */
      return (profiles || []).map((profile: Profile) => {
        return assignDisplayName(profile);
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          // 403번의 경우 권한이 없는 경우로 간주, 관리자 승인 페이지로 리다이렉트
          if (e.response.data.message?.statusCode === PERMISSION_ERROR_CODE) {
            window.location.assign('/message/needs-approval');
          }
        }
      } else {
        console.error('Unexpected error: ', e);
      }
    }
  }, [instances.voiceLibrary]);

  const createTtsZeroShot = async (
    tgt: File,
    text: string,
    lang: Language,
    parameter: ZeroShotParameter,
    num_takes: number
  ) => {
    const { data } = await instances.zeroShot.post('/predictions/tts', {
      text,
      tgt: tgt,
      lang: languageMap[lang],
      pitch_shift: parameter.pitch_shift,
      pitch_variance: parameter.pitch_variance,
      speed: parameter.speed,
      similarity: parameter.similarity,
      num_takes,
      api: 'tts',
      model_type: 'v1.2.1',
    });

    const audioCtx = getAudioContext();
    const audioBuffer = await getAudioBuffer(data);
    let buffers = [];
    for (let i = 0; i < (audioBuffer?.numberOfChannels || 0); i++) {
      const channelData = audioBuffer?.getChannelData(i);
      if (channelData) {
        let buffer = audioCtx.createBuffer(
          1,
          channelData.length,
          audioCtx.sampleRate
        );
        buffer.copyToChannel(channelData, 0);
        buffers.push(buffer);
      }
    }
    return buffers;
  };

  const getUploadInfo = useCallback(
    async (sessionId: string, name: string, type: string) => {
      return (await instances.screenplay.post(
        '/resources',
        {
          name,
          content_type: type,
          purposes: ['playback'],
        },
        { headers: { 'x-session-id': sessionId } }
      )) as AxiosResponse<ResourceResponse>;
    },
    [instances.screenplay]
  );

  const getResourceInfo = useCallback(
    async (resource_id: string) => {
      return (await instances.screenplay.get(
        `/resources/${resource_id}`
      )) as AxiosResponse<ResourceFileResponse>;
    },
    [instances.screenplay]
  );

  const createTts = useCallback(
    async (params: TTSParams, sessionId: string) => {
      try {
        const result = await instances.screenplay.post('/tts', params, {
          headers: { 'X-Session-Id': sessionId },
        });
        return result;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.data.message?.statusCode === PERMISSION_ERROR_CODE) {
            throw new PermissionError('permissionError');
            // // 403번의 경우 권한이 없는 경우로 간주, 관리자 승인 페이지로 리다이렉트
            // window.location.assign('/message/needs-approval');
          }
        } else {
          console.error('Unexpected error: ', e);
        }
      }
    },
    [instances.screenplay]
  );

  const createCvc = useCallback(
    async (params: CVCParams, sessionId: string) => {
      try {
        const result = await instances.screenplay.post('/cvc', params, {
          headers: { 'X-Session-Id': sessionId },
        });
        return result;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.data) {
            // 403번의 경우 권한이 없는 경우로 간주, 관리자 승인 페이지로 리다이렉트
            if (e.response.data.message?.statusCode === PERMISSION_ERROR_CODE) {
              window.location.assign('/message/needs-approval');
            }
          }
        } else {
          console.error('Unexpected error: ', e);
        }
      }
    },
    [instances.screenplay]
  );

  // tmp: 추후 cdn 적용되면 대체 예정
  const createTtsForVoice = useCallback(
    async (voice: VoiceTtsRequest, text: string, sessionId: string) => {
      const { data } = await instances.screenplay.post(
        '/tts',
        {
          text,
          language: voice.language,
          voice_id: voice.voice_id,
          take_count: 1,
          parameters: {
            pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
            pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
            speed: SPEECH_CONTROL_LIST[2].defaultValue,
          },
        } as TTSParams,
        {
          headers: { 'X-Session-Id': sessionId },
        }
      );
      const audioBuffer = await getAudioBuffer(data);
      const file = exportAudioBufferToFile(
        audioBuffer as AudioBuffer,
        'tts.wav'
      );
      const uploadInfo = await getUploadInfo(
        sessionId,
        'audio.wav',
        'audio/wav'
      );
      upload(uploadInfo.data.data.upload_url, file);
      return {
        resource_id: uploadInfo.data.data.resource_id,
        audioBuffer,
      };
    },
    [getUploadInfo, instances.screenplay]
  );

  const checkTrial = useCallback(async () => {
    return await instances.shift.post<{ isTrialStarted: boolean }>(
      `/play/check-trial`
    );
  }, [instances.shift]);

  return {
    getVoiceLibrary,
    createTtsZeroShot,
    getUploadInfo,
    getResourceInfo,
    createTts,
    createCvc,
    createTtsForVoice,
    checkTrial,
  };
};

export default useAxios;
