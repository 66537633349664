import React, { useId } from 'react';

import StyledCheckbox from './StyledCheckbox';

export interface CheckboxProps {
  className?: string;
  defaultColorSet?: {
    bg: string;
    border: string;
    icon: string;
  };
  selectColorSet?: {
    bg: string;
    border: string;
    icon: string;
  };
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  caption?: string;
}
const Checkbox: React.FC<CheckboxProps> = ({
  className,
  caption,
  defaultColorSet,
  selectColorSet,
  checked,
  disabled,
  onChange,
}) => {
  const checkboxId = useId();
  return (
    <StyledCheckbox
      className={className}
      defaultColorSet={defaultColorSet}
      selectColorSet={selectColorSet}
    >
      <input
        type="checkbox"
        id={checkboxId}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.checked)}
      />
      <label htmlFor={checkboxId}>
        <span className="screen-out">{checked ? 'checked' : 'unchecked'}</span>
      </label>
      {caption && <span className="checkbox-caption">{caption}</span>}
    </StyledCheckbox>
  );
};
export default Checkbox;
