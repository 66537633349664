import ObtIcon from '@/pages/screenplay/assets/images/Obt.png';

const PurchsedUser = () => {
  return (
    <>
      <div>
        <img src={ObtIcon} alt="trial" width="42" height="22" />
      </div>
      <div>Until Jan 31</div>
    </>
  );
};

export default PurchsedUser;
