import './index.css';
import './api/index';

import * as Sentry from '@sentry/react';
import {
  SUPAuthProvider,
  SupertoneApps,
  SupertoneAuthEnv,
} from '@supertone-inc/auth-sdk-react';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isProduction = process.env.REACT_APP_ENV === 'prd';
root.render(
  // <React.StrictMode>
  <SUPAuthProvider
    supertoneApp={SupertoneApps.Play}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    env={isProduction ? SupertoneAuthEnv.PRODUCTION : SupertoneAuthEnv.STAGING}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SUPAuthProvider>
  // </React.StrictMode>
);

if (process.env.REACT_APP_ENV === 'prd') {
  Sentry.init({
    dsn: 'https://f2ab8e4b296ee02941508cff3d9239c1@o933116.ingest.us.sentry.io/4508170810425344',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
