import Button from '@/components/Button/Button';
import TrialIcon from '@/pages/screenplay/assets/images/Trial.png';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { showPayModal } from '@/pages/screenplay/stores/atoms/ui';
import { PurchaseBtn } from '@/styles/Colors';
import StyledEndTrialUser from '@/user/StyledEndTrialUser';
import { useSetRecoilState } from 'recoil';

const NeedPurchasingUser = () => {
  const setShowPayModal = useSetRecoilState(showPayModal);
  const { track } = useLog();
  return (
    <StyledEndTrialUser>
      <div>
        <img src={TrialIcon} alt="trial" width="42" height="22" />
      </div>
      <div>2-week trial from sign-up</div>
      <Button
        size={'lg'}
        variant={'none'}
        color={PurchaseBtn}
        className="purchase-now-button"
        onClick={() => {
          track('PURCHASE_DOWNLOAD', {});
          setShowPayModal(true);
        }}
      >
        Purchase now
      </Button>
    </StyledEndTrialUser>
  );
};

export default NeedPurchasingUser;
