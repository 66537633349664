import Title from '@/components/Title/Title';
import {
  ZeroShotParameter,
  ZeroShotParameterKey,
} from '@/pages/screenplay/api';
import { useCallback, useContext, useEffect, useState } from 'react';

import { SliderControl } from '../components/SliderControl';
import { ZeroShotContext } from '../ZeroShotContextProvider';
import { SliderParameter } from './models';

const SpeechControl = () => {
  const { selectedSource, parameters, parameterMap, setParameterMap } =
    useContext(ZeroShotContext);

  const [parametersValue, setParametersValue] = useState<ZeroShotParameter>(
    {} as ZeroShotParameter
  );

  // 파라미터의 변경이 발생하면 슬라이더 초기화
  useEffect(() => {
    if (!parameters) return;
    const newParameterMap = {} as ZeroShotParameter;
    parameters.forEach((parameter) => {
      newParameterMap[parameter.name as ZeroShotParameterKey] =
        parameter.defaultValue as number;
    });
    setParametersValue(newParameterMap);
  }, [parameters]);

  const onChangeParameters = useCallback(
    (value: { [key: string]: number | boolean }) => {
      setParametersValue((pre) => {
        return {
          ...pre,
          ...value,
        };
      });
      setParameterMap({
        ...parameterMap,
        [selectedSource?.id || '']: {
          ...parameterMap[selectedSource?.id || ''],
          ...value,
        },
      });
    },
    [setParametersValue, setParameterMap, parameterMap, selectedSource?.id]
  );

  useEffect(() => {
    if (!selectedSource?.id) {
      setParametersValue({} as ZeroShotParameter);
      return;
    }
    setParametersValue(parameterMap[selectedSource.id] || {});
  }, [selectedSource?.id, setParametersValue, parameterMap]);

  return (
    <section className="section-control">
      <Title size="lg">SPEECH CONTROL</Title>
      {parameters?.map((parameter, index) => {
        return (
          <section key={parameter.name} className="model-parameter">
            <SliderControl
              key={`${parameter.name}_${index}`}
              value={
                (parametersValue[parameter.name as ZeroShotParameterKey] ??
                  parameter.defaultValue) as number
              }
              parameter={
                {
                  ...parameter,
                  disabled: !selectedSource,
                } as SliderParameter
              }
              onChange={onChangeParameters}
            />
          </section>
        );
      })}
    </section>
  );
};

export default SpeechControl;
