import { Black, Grey, Primary, Secondary } from '@/styles/Colors';
import styled from '@emotion/styled';

import DraftsIcon from '../assets/icons/DraftsIcon.svg';

export const StyledLineItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${Grey[700]};
  &.no-takes {
    .line-editor-wrap {
      .line-num {
        color: ${Grey[200]};
      }
      .line-editor {
        .line-editor-text {
          color: ${Grey[200]};
        }
      }
      &.selected {
        .line-num {
          color: ${Grey[50]};
        }
        .line-editor {
          .line-editor-text {
            color: ${Grey[50]};
          }
        }
      }
    }
  }
  .line-editor-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 4rem;
    height: 100%;
    &.empty {
      filter: grayscale(100%);
      .thumbnail {
        opacity: 0.5;
      }
      &.selected {
        filter: grayscale(0%);
        .thumbnail {
          opacity: 1;
        }
      }
    }
    .line-editor-left {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: calc(100% - 4rem);
      align-items: center;
    }
    &.selected {
      background: ${Grey[700]};
    }
    padding: 0.75rem 0.5rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    .line-checkbox {
      display: flex;
      margin-left: 1.025rem;
      width: 1.25rem;
    }
    .line-num {
      width: 2.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .line-voice {
      width: 2.25rem;
      height: 2.25rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      position: relative;
      &.voice-changed {
        &::after {
          content: '';
          position: absolute;
          width: 1rem;
          height: 1rem;
          right: -0.5rem;
          top: -0.5rem;
          background-image: url(${DraftsIcon});
          background-size: cover;
        }
      }
      img {
        border-radius: 50%;
        overflow: hidden;
        width: 2.25rem;
        height: 2.25rem;
        cursor: pointer;
      }
    }
    .line-editor {
      margin-left: 0.5rem;
      width: 100%;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: space-between;
      &.line-open {
        .line-editor-left {
          width: calc(100% - 7rem);
        }
      }
      .line-editor-left {
        position: relative;
        width: 100%;
        .shadow-text {
          visibility: hidden;
          min-height: 1.5rem;
          width: 100%;
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
      .content-editor {
        font-size: 1rem;
        line-height: 1.5rem;
        position: absolute;
        outline: none;
        width: 100%;
        height: 100%;
        background-color: transparent;
        color: ${Grey[50]};
        border: 0;
        outline: 0;
        overflow: visible;
        display: block;
        width: 100%;
      }
      &.cvc {
        color: ${Secondary[300]};
      }
    }
    .line-button {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      .line-play {
        visibility: hidden;
        margin-left: 1rem;
        .btn-play {
          width: 2rem;
          height: 2rem;
          background-color: transparent;
          border: 0;
          padding: 0;
          color: transparent;
          &:hover {
            color: ${Grey[200]};
          }
          svg {
            width: 100%;
            height: 100%;
          }
          cursor: pointer;
        }
      }
    }
    .btn-toggle {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      border: 0;
      background-color: transparent;
      &.active {
        background-color: ${Primary[400]};
      }
      &.disabled {
        color: ${Grey[500]};
      }
    }
  }
  .line-takes {
    margin-left: 2.45rem;
    padding-left: 1.227rem;
    width: calc(100% - 2.45rem);
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    ::before {
      content: ' ';
      position: absolute;
      border-left: 1px solid ${Grey[600]};
      height: calc(100% - 1.6rem);
      top: 0;
      margin-left: -1.3rem;
    }
    .line-inner-item {
      position: relative;
      background-color: ${Grey[800]};
      cursor: pointer;
      ::before {
        content: ' ';
        position: absolute;
        border-top: 1px solid ${Grey[600]};
        width: 1.2rem;
        margin-left: -1.3rem;
      }
      .line-takes-label {
        width: 3.3rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.65rem;
        border-right: 1px solid ${Black};
        .icon {
          width: 2rem;
          height: 2rem;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .line-takes-content {
        width: calc(100% - 4.3rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .line-draft {
      background: ${Grey[800]};
      height: 3.25rem;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      border: 1px solid ${Grey[800]};
      &.active {
        background-color: ${Grey[600]};
        border: 1px solid ${Grey[300]};
      }
      .draft-date {
        font-size: 1rem;
        color: ${Grey[500]};
      }
    }
    .line-take-delete {
      width: 3.25rem;
      border-left: 1px solid ${Black};
      .btn-take-delete {
        width: 3.25rem;
        height: 3.25rem;
        border-radius: 0;
        background-color: transparent;
        border: none;
      }
    }
  }
  .line-add {
    display: none;
    position: absolute;
    left: 50%;
    margin-left: -0.875rem;
    bottom: -1rem;
    z-index: 1;
    .add {
      cursor: pointer;
      padding: 0;
      border: 0;
      outline: 0;
      width: 1.75rem;
      height: 1.75rem;
      background-color: transparent;
      color: ${Grey[500]};
      &:hover {
        color: ${Grey[50]};
      }
    }
  }
  &:hover {
    position: relative;
    .line-add {
      display: inline-block;
    }
    .line-button {
      .line-play {
        visibility: visible;
      }
    }
  }
`;
