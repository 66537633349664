import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  isModalOpenAtom,
  isShowMediaAtom,
  isShowTimelineAtom,
  isShowVoiceLibraryAtom,
  selectedQuickMenuAtom,
} from '../atoms/ui';

export const useResetUI = () => {
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const setIsShowTimeline = useSetRecoilState(isShowTimelineAtom);
  const setIsShowMedia = useSetRecoilState(isShowMediaAtom);
  const setIsShowVoiceLibrary = useSetRecoilState(isShowVoiceLibraryAtom);
  const setSelectedQuickMenu = useSetRecoilState(selectedQuickMenuAtom);
  const resetUI = useCallback(() => {
    setIsModalOpen(false);
    setIsShowTimeline(false);
    setIsShowMedia(false);
    setIsShowVoiceLibrary(false);
    setSelectedQuickMenu('Voice');
  }, [
    setIsModalOpen,
    setIsShowTimeline,
    setIsShowMedia,
    setIsShowVoiceLibrary,
    setSelectedQuickMenu,
  ]);
  return {
    resetUI,
  };
};
