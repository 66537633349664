import { Black, Grey, Secondary } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import CheckIcon from '../assets/icons/CheckIcon.svg';

export const StyledScriptDropDown = styled.section`
  background-color: ${Black};
  width: 5rem;
  border-radius: 0.5rem;
  .sp-drawer {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sup-dropdown-item {
    &:hover {
      background-color: ${Grey[450]};
    }
  }
  .sup-dropdown-item-label {
    margin-left: 0.25rem;
  }
  .selected {
    display: flex;
    justify-content: space-between;
    .sup-dropdown-item-badge-label {
      width: 0.75rem;
      height: 0.75rem;
      display: inline-block;
      margin-right: 0.25rem;
      color: ${Black};
      background-image: url(${CheckIcon});
      background-size: cover;
    }
  }
`;
export const StyledMultiTakeDropDown = styled.section`
  background-color: ${Black};
  width: 4rem;
  border-radius: 0.5rem;
  .sp-drawer {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sup-dropdown-item {
    &:hover {
      background-color: ${Grey[450]};
    }
  }
  .sup-dropdown-item-label {
    margin-left: 0.25rem;
  }
  .selected {
    display: flex;
    justify-content: space-between;
    .sup-dropdown-item-badge-label {
      width: 0.75rem;
      height: 0.75rem;
      display: inline-block;
      margin-right: 0.25rem;
      color: ${Black};
      background-image: url(${CheckIcon});
      background-size: cover;
    }
  }
`;
export const StyledDirectWithAudio = styled.section`
  border-radius: 0.75rem;
  border: 1px solid ${Grey[200]};
  background: rgba(68, 68, 68);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.48);
  backdrop-filter: blur(2px);
  padding: 1rem 0;
  .title {
    font-size: 1.125rem;
    font-weight: ${FontWeight['Bold']};
    color: ${Grey[50]};
    text-align: center;
    padding-bottom: 0.75rem;
  }
  .cvc-direct-list {
    li {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.31rem;
      padding-bottom: 0.31rem;
      cursor: pointer;
      &:hover {
        background-color: ${Grey[400]};
      }
    }
    .cvc-label {
      display: flex;
      align-items: center;
      gap: 1rem;
      .label-icon {
        width: 2.5rem;
        height: 2.5rem;
        > svg {
          width: 100%;
          height: 100%;
        }
      }
      &.recording {
        background-color: ${Secondary[200]} !important;
        font-weight: ${FontWeight['SemiBold']};
      }
    }
  }
`;
export const StyledVoiceListDropDown = styled.section`
  width: 5.875rem;
  border-radius: 0.75rem;
  border: 1px solid ${Grey[200]};
  background: rgba(68, 68, 68);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.48);
  backdrop-filter: blur(2px);
  padding: 1rem 0;
  .voice-dropdown-item {
    padding: 0.5rem 1rem 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: ${Grey[400]};
    }
    &.selected {
      cursor: default;
      position: relative;
      ::after {
        content: '';
        position: absolute;
        width: 0.75rem;
        height: 0.5625rem;
        right: 1rem;
        top: 1.1rem;
        background-image: url(${CheckIcon});
        background-size: cover;
      }
    }
    .voice-image {
      width: 2rem;
      height: 2rem;
    }
  }
`;
export const StyledLanguageDropDownLayer = styled.section`
  width: 8.375rem;
  border-radius: 0.75rem;
  border: 1px solid ${Grey[200]};
  background: rgba(68, 68, 68);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.48);
  backdrop-filter: blur(2px);
  padding: 1rem 0;
  .dropdown-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem 0.5rem;
    &:hover {
      background-color: ${Grey[400]};
    }
    &.selected {
      cursor: default;
      position: relative;
      ::after {
        content: '';
        position: absolute;
        width: 0.75rem;
        height: 0.5625rem;
        right: 1rem;
        top: 1.1rem;
        background-image: url(${CheckIcon});
        background-size: cover;
      }
    }
    .flag {
      width: 2.625rem;
      height: 2rem;
    }
    .label {
      font-weight: ${FontWeight['SemiBold']};
    }
  }
`;
