import classNames from 'classnames';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { ReactComponent as LineAddIcon } from '../assets/icons/LineAddIcon.svg';
import TakeGeneratePanel from '../ControlPanel/TakeGeneratePanel';
import { useDataContext } from '../providers/DataContextProvider';
import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import { isShowMediaAtom } from '../stores/atoms/ui';
import StyledSceneWriterPanel from '../styles/StyledSceneWriterPanel';
import LineControl from './LineControl';
import LineItem from './LineItem';

const SceneWriterPanel = () => {
  const isShowMedia = useRecoilValue(isShowMediaAtom);
  const { activeLineId, activeSceneId } = useDataContext();
  const { lineList, addLine } = useSceneEditorContext();
  const activeLine = useMemo(
    () => lineList.find((line) => line.id === activeLineId),
    [lineList, activeLineId]
  );

  return (
    <>
      <StyledSceneWriterPanel
        className={classNames('line-active', isShowMedia && 'media-active')}
      >
        <section className="sp-top-panel sp-scene-writer">
          <section className="sp-scene-title">
            <LineControl />
            {!lineList.length && (
              <section className="line-add">
                <button className="add" onClick={() => addLine()}>
                  <LineAddIcon />
                </button>
              </section>
            )}
          </section>
          <section className="sp-scene-content">
            <section className="sp-scene-content-list">
              {!!lineList.length && (
                <ul>
                  {lineList.map((line, index) => (
                    <LineItem
                      key={`${activeSceneId}_${line.id}`}
                      line={line}
                      num={index + 1}
                    />
                  ))}
                </ul>
              )}
            </section>
          </section>
        </section>
      </StyledSceneWriterPanel>
      {activeLine && (activeLine.draft?.text || activeLine.selectedTakeId) && (
        <section className="scene-writer-right-panel">
          <TakeGeneratePanel />
        </section>
      )}
    </>
  );
};

export default SceneWriterPanel;
