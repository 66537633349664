import { useEffect } from 'react';

/**
 * 특정 Element에 이벤트 리스너를 등록하는 훅
 *
 * @param eventName 등록하고자 하는 이벤트 이름 혹은 이름 목록
 * @param handler 이벤트가 감지되었을 때 실행되는 함수
 * @param targetElement 이벤트 리스너를 등록하고자 하는 Element 혹은 Element 목록 (default: document)
 */
const useElementEvent = (
  eventName: keyof DocumentEventMap | (keyof DocumentEventMap)[],
  handler: (event: Event) => void,
  targetElement: Document | HTMLElement | HTMLElement[] | null = document
) => {
  useEffect(() => {
    if (!targetElement) return;

    const eventNameArray = Array.isArray(eventName) ? eventName : [eventName];
    const elements = Array.isArray(targetElement)
      ? targetElement
      : [targetElement];

    eventNameArray.forEach((name) => {
      elements.forEach((element) => {
        element.addEventListener(name, handler);
      });
    });

    return () => {
      eventNameArray.forEach((name) => {
        elements.forEach((element) => {
          element.removeEventListener(name, handler);
        });
      });
    };
  }, [eventName, handler, targetElement]);
};

export default useElementEvent;
