import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  isShowMediaAtom,
  isShowTimelineAtom,
  isShowVoiceLibraryAtom,
} from '../atoms/ui';

export const useToggleTimeline = () => {
  const setIsShowTimeline = useSetRecoilState(isShowTimelineAtom);
  const toggleTimeline = useCallback(
    () => setIsShowTimeline((prev) => !prev),
    [setIsShowTimeline]
  );
  return {
    toggleTimeline,
  };
};

export const useToggleMedia = () => {
  const setIsShowMedia = useSetRecoilState(isShowMediaAtom);
  const toggleMedia = useCallback(
    () => setIsShowMedia((prev) => !prev),
    [setIsShowMedia]
  );
  return {
    toggleMedia,
  };
};

export const useToggleVoiceLibrary = () => {
  const setIsShowVoiceLibrary = useSetRecoilState(isShowVoiceLibraryAtom);
  const toggleVoiceLibrary = useCallback(
    () => setIsShowVoiceLibrary((prev) => !prev),
    [setIsShowVoiceLibrary]
  );
  const closeVoiceLibrary = useCallback(() => {
    setIsShowVoiceLibrary(false);
  }, [setIsShowVoiceLibrary]);
  return {
    toggleVoiceLibrary,
    closeVoiceLibrary,
  };
};
