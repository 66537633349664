import styled from '@emotion/styled';

import { Grey } from '../../styles/Colors';

export const StyledScrollbar = styled.div`
  box-sizing: border-box;

  .sup-scrollbar-track {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
  }

  .sup-scrollbar-thumb {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${Grey[800]};
  }
`;
