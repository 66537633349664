import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import { isTextEditingAtom } from '../stores/atoms/ui';
import { MAX_LINE_TEXT_COUNT } from '../stores/data/config';
import { Line } from '../stores/project';

interface LineEditorProps {
  text: string;
  setText: (text: string) => void;
  line: Line;
  onUpdate?: (text: string) => void;
  isOpen: boolean;
  isLastLine: boolean;
}
const LineEditor: React.FC<LineEditorProps> = ({
  text: textProp,
  setText: setTextProp,
  line,
  isOpen,
  isLastLine,
}) => {
  const { t } = useTranslation();
  const [, setIsTextEditing] = useRecoilState(isTextEditingAtom);
  const { updateDraft, addLine } = useSceneEditorContext();
  const [text, setText] = useState(textProp);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const onFocus = useCallback(() => {
    setIsTextEditing(true);
  }, [setIsTextEditing]);

  const onBlur = useCallback(() => {
    setIsTextEditing(false);
  }, [setIsTextEditing]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      switch (e.code) {
        case 'Escape':
          setTimeout(() => inputRef.current?.blur());
          return;
      }
    },
    []
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // 줄바꿈 문자를 공백으로 치환한다.
      const text = e.target.value
        .replace(/\r?\n|\r/g, '')
        .slice(0, MAX_LINE_TEXT_COUNT);

      setText(text);
      updateDraft(line.id, {
        text,
        source_id: undefined,
      });

      // 라인에 데이터를 입력했을떄, 해당라인이 마지막 라인이면 무조건 빈라인을 추가한다.
      isLastLine && addLine(line, true);
    },
    [updateDraft, line, isLastLine, addLine]
  );

  // 라인 오픈시 에디터 영역 포커스
  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setTextProp(text);
  }, [text, setTextProp]);

  useEffect(() => {
    setText(textProp);
  }, [textProp]);

  return (
    <section className={classNames('line-editor', isOpen && 'line-open')}>
      <section className="line-editor-left">
        <section className="shadow-text">{text}</section>
        <textarea
          ref={inputRef}
          className={classNames('content-editor', 'line-editor-text')}
          maxLength={MAX_LINE_TEXT_COUNT}
          placeholder={t('Write your story here') as string}
          data-hj-allow
          value={text}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </section>
      {isOpen && (
        <section className="line-text-count">
          ({text.length}/{MAX_LINE_TEXT_COUNT})
        </section>
      )}
    </section>
  );
};
export default LineEditor;
