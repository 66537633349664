import { CardUI, CardUIContent, CardUIHeader } from '@/components/CardUI';
import Slider from '@/components/Slider/Slider';
import Stepper from '@/components/Stepper/Stepper';
import Title from '@/components/Title/Title';
import { Black } from '@/styles/Colors';
import { useCallback } from 'react';

import { SliderParameter } from './config';

interface SliderControlProps {
  parameter: SliderParameter;
  value: number | boolean;
  onChange: (value: { [key: string]: number | boolean }) => void;
}
export const SliderControl: React.FC<SliderControlProps> = ({
  parameter,
  value,
  onChange,
}) => {
  const setValue = useCallback(
    (value: number) => {
      onChange({ [parameter.name]: value as number });
    },
    [onChange, parameter]
  );
  return (
    <CardUI
      alignContent="flex-start"
      alignItems="flex-start"
      backgroundColor={Black}
      className=""
      flexDirection="column"
      gap="0"
      justifyContent="flex-start"
      padding={'0.75rem'}
      style={{ borderRadius: '0.25rem' }}
    >
      <CardUIHeader padding="0">
        <Title size="md" tag="h4">
          {parameter.label}
        </Title>
        <Stepper
          min={parameter.minValue}
          max={parameter.maxValue}
          step={parameter.step}
          value={(value as number) ?? parameter.defaultValue}
          onChange={setValue}
          disabled={parameter.disabled}
        />
      </CardUIHeader>
      <CardUIContent padding="0.75rem 0 0.375rem 0">
        <Slider
          max={parameter.maxValue}
          maxLabel={parameter.maxLabel || 'Max'}
          min={parameter.minValue}
          minLabel={parameter.minLabel || 'Min'}
          step={parameter.step}
          value={(value as number) ?? parameter.defaultValue}
          onChange={setValue}
          disabled={parameter.disabled}
          defaultValue={parameter.defaultValue}
        />
      </CardUIContent>
    </CardUI>
  );
};
