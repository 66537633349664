import { Black, Grey, White } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import UserInfo from '@/user/UserInfo';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as ProjectIcon } from '../assets/icons/menu/Project.svg';
import { useLog } from '../hooks/useLog/useLog';
import {
  HEADER_HEIGHT,
  isShowFeedbackAtom,
  isShowVoiceLibraryAtom,
  selectedQuickMenuAtom,
} from '../stores/atoms/ui';
import {
  QUICK_MENU,
  QuickMenuType,
  VOICE_CLONNING_FORM_URL,
} from '../stores/data/config';
import { useToggleVoiceLibrary } from '../stores/recoilHooks/useToggles';

const StyledMenu = styled.section`
  width: 7.5rem;
  flex-direction: column;
  height: calc(100vh - ${HEADER_HEIGHT}rem);
  display: flex;
  background-color: ${Grey[700]};
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  &.expanded {
    height: calc(100vh - ${HEADER_HEIGHT * 2}rem);
  }
  .user-info {
    margin-bottom: 1.5rem;
  }
  .nav-menu {
    display: flex;
    width: 5.825rem;
    padding: 1rem 0;
    flex-direction: column;
    border-radius: 0.25rem;
    background: ${Black};
    gap: 1rem;
    &-item {
      flex-direction: column;
      gap: 1rem;
      color: ${Grey[500]};
      width: 100%;
      height: 5.825rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      cursor: pointer;
      &.selected {
        color: ${White};
      }
      &.project {
        margin-top: 1rem;
        border-radius: 0.25rem;
        width: 5.625rem;
        height: 5.625rem;
      }
      .title {
        font-size: 1rem;
        font-weight: ${FontWeight.SemiBold};
        text-align: center;
      }
    }
  }
`;

const QuickMenu = () => {
  const { t } = useTranslation();
  const [selectedQuickMenu, setSelectedQuickMenu] = useRecoilState(
    selectedQuickMenuAtom
  );
  const isShowVoiceLibrary = useRecoilValue(isShowVoiceLibraryAtom);
  const isShowFeedback = useRecoilValue(isShowFeedbackAtom);
  const { toggleVoiceLibrary } = useToggleVoiceLibrary();

  const { track } = useLog();
  const handleQuickMenuClick = useCallback(
    (name: QuickMenuType) => {
      if (name !== 'Voice' && isShowVoiceLibrary) {
        toggleVoiceLibrary();
      }
      if (name === 'Voice') {
        track('OPEN_VOICE_TAB');
      } else if (name === 'Script') {
        track('OPEN_SCRIPT_TAB');
      } else if (name === 'Voice Cloning') {
        track('OPEN_VOICE_CLONING_TAB');
        window.open(VOICE_CLONNING_FORM_URL, '_blank')?.focus();
        return;
      }
      setSelectedQuickMenu(name);
    },
    [setSelectedQuickMenu, isShowVoiceLibrary, toggleVoiceLibrary, track]
  );

  return (
    <StyledMenu
      className={classNames('gnb-menu', isShowFeedback && 'expanded')}
    >
      <Link className="nav-menu-item project" to="/projects">
        <ProjectIcon />
        <span className="title">{t('Home')}</span>
      </Link>
      <ul className="nav-menu">
        {QUICK_MENU.map((m) => {
          const Icon = m.icon;
          return (
            <li
              key={`quick-menu-${m.name}`}
              className={classNames(
                'nav-menu-item',
                selectedQuickMenu === m.name && 'selected'
              )}
              onClick={() => handleQuickMenuClick(m.name)}
            >
              <Icon />
              <span className="title">{m.name}</span>
            </li>
          );
        })}
      </ul>
      <UserInfo />
    </StyledMenu>
  );
};
export default QuickMenu;
