import Slider from '@/components/Slider/Slider';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import { useDataContext } from '../providers/DataContextProvider';
import { useSceneEditorContext } from '../providers/SceneEditorContextProvider';
import { Parameter } from '../stores/project';

interface SpeechControlsProps {
  controls: (
    | {
        value: number;
        change: (value: number) => void;
        id: string;
        title: string;
        min: number;
        max: number;
        step: number;
        defaultValue: number;
      }
    | {
        value: number;
        change: (value: number) => void;
        id: string;
        title: string;
        min: number;
        max: number;
        step: number;
        defaultValue: number;
      }
  )[];
}
const SpeechControls: React.FC<SpeechControlsProps> = ({ controls }) => {
  const { activeLineId } = useDataContext();
  const { changeDraft, lineList, takeList } = useSceneEditorContext();
  const onChangeDraft = useCallback(
    (key: string, value: number) => {
      const activeLine = lineList.find((line) => line.id === activeLineId);
      const draftParams = activeLine?.draft?.parameter;
      const activeTakeParams = takeList.find(
        (take) => take.id === activeLine?.selectedTakeId
      )?.parameter;
      const preParams = draftParams || activeTakeParams;
      activeLineId &&
        changeDraft(activeLineId, {
          parameter: {
            ...(preParams as Parameter),
            [key]: value,
          },
        });
    },
    [changeDraft, activeLineId, lineList, takeList]
  );
  return (
    <section className="take-generate-content-section take-speech-control">
      <ul>
        {controls.map((item, index) => (
          <li
            key={index}
            className={classNames(
              'slider-control',
              index === controls.length - 1 && 'speed'
            )}
          >
            <section className="slider-control-header">
              <span className="slider-control-title">{item.title}</span>
              <span className={classNames('slider-value')}>{item.value}</span>
            </section>
            <span className="slider-control-content">
              <span className="control-min">{item.min}</span>
              <Slider
                min={item.min}
                max={item.max}
                value={item.value}
                showMarks={false}
                step={item.step}
                defaultValue={item.defaultValue}
                useDefaultTrack={true}
                onChange={(value) => item.change(value)}
                onEnd={(value) => onChangeDraft(item.id, value)}
              />
              <span className="control-max">{item.max}</span>
            </span>
          </li>
        ))}
      </ul>
    </section>
  );
};
export default SpeechControls;
