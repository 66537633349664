import { getGroupInfo } from '@/util/user';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

import { LOG_EVENTS } from './logEvents';

export const useLog = () => {
  const { user } = useSUPAuth();
  // [workaround] 내부로직을 사용해서 넘기는 경우를 대비해서 훅으로 분리
  const track = useCallback(
    (eventName: LOG_EVENTS, props?: any) => {
      const user_group = getGroupInfo(user?.permissions);
      if (
        process.env.REACT_APP_ENV !== 'prd' ||
        user?.email?.endsWith('@supertone.ai')
      )
        return;
      const params = { user: user?.email, user_group };
      mixpanel.track(eventName, { ...params, ...(props || {}) });
    },
    [user]
  );
  return {
    track,
  };
};
