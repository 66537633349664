import { atom } from 'recoil';

import { Music, MusicFile } from '../project';

export const musicListAtom = atom<Music[]>({
  key: 'musicList',
  default: [],
});

export const musicFileListAtom = atom<MusicFile[]>({
  key: 'musicFileList',
  default: [],
});
