import { Dropdown, DropdownItem, DropdownList } from '@/components/Dropdown';
import { Black } from '@/styles/Colors';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LineToggleIconOver } from '../assets/icons/LineToggleIcon_over.svg';
import { StyledDropDownList } from './StyledTakeGeneratePanel';

interface StyleDropDownProps {
  value?: string;
  update: (style: string) => void;
  list?: string[];
}
const StyleDropDown: React.FC<StyleDropDownProps> = ({
  list,
  value,
  update,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const ref = useRef(null);
  return (
    <section className="take-voice-setting-item style-setting">
      <span className="setting-title">{t('Style')}</span>
      <section className="dropdown-area">
        <Dropdown
          style={{
            width: '10.5rem',
            marginLeft: '0.25rem',
            paddingLeft: 0,
            paddingRight: 0,
            overflowY: 'auto',
            backgroundColor: Black,
            borderColor: Black,
          }}
          // tmp: 테스트 중 짤림 현상 해결을 위해 maxHeight 추가
          maxHeight={250}
          isOpen={isOpen}
          onClose={onClose}
          preventRef={ref}
          closeOnClickOutside={true}
          placement="bottom-left"
          anchorElement={
            <section
              className="take-voice-setting-dropdown"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="drop-down-text">{value}</span>
              <span className="toggle-icon">
                <LineToggleIconOver />
              </span>
            </section>
          }
        >
          <StyledDropDownList>
            <DropdownList className="sp-drawer">
              {list?.map((v) => (
                <DropdownItem
                  key={`dropdown_list_${v}`}
                  label={v.toString()}
                  isSelected={v === value}
                  onClick={() => {
                    update(v);
                    onClose();
                  }}
                />
              ))}
            </DropdownList>
          </StyledDropDownList>
        </Dropdown>
      </section>
    </section>
  );
};
export default StyleDropDown;
