import Modal from '@/components/Modal/Modal';
import StylePadle from '@/components/Portal/StyledPaddle';
import { showPaymentOverray } from '@/pages/screenplay/stores/atoms/ui';
import parseJwt from '@/util/parseJWT';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

const Paddle = () => {
  const [uid, setUid] = useState('');
  const { getAccessTokenSilently } = useSUPAuth();
  const paymentUri = process.env.REACT_APP_PAYMENT_URI;
  const setShowPaymentOverrayOpen = useSetRecoilState(showPaymentOverray);

  const completePayment = useCallback(
    (event: MessageEvent) => {
      if (event.data === 'closePayment') {
        setShowPaymentOverrayOpen(false);
      }
      if (event.data === 'paymentSuccess') {
        window.location.assign('/');
      }
    },
    [setShowPaymentOverrayOpen]
  );

  useEffect(() => {
    window.addEventListener('message', completePayment, false);

    return () => {
      window.removeEventListener('message', completePayment);
    };
  }, [completePayment]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const result = parseJwt(token);
      setUid(result['sub'] ?? '');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const discountId = process.env.REACT_APP_PADDLE_DISCOUNT_ID ?? '';
  const priceId = process.env.REACT_APP_PADDLE_PRICE_ID;

  if (!uid) {
    return <></>;
  }
  return (
    <Modal
      isPortal={true}
      isOpen={true}
      hideBackdrop={true}
      hideCloseButton={true}
    >
      <StylePadle>
        <iframe
          src={`${paymentUri}?discountId=${discountId}&priceIds=${priceId}&uid=${encodeURIComponent(
            uid
          )}`}
          width={'100%'}
          height={'100%'}
          title={'payment'}
        />
      </StylePadle>
    </Modal>
  );
};

export default Paddle;
