import { HTMLAttributes, ReactNode } from 'react';

interface ModalHeaderProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const ModalHeader = ({ children, ...rest }: ModalHeaderProps) => {
  return (
    <div className="modal-header" {...rest}>
      <h3 className="modal-header-title">{children}</h3>
    </div>
  );
};

export default ModalHeader;
