import Button from '@/components/Button/Button';
import { Grey, Primary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';
import { useCallback, useContext, useMemo } from 'react';

import { ZeroShotParameter } from '../../../screenplay/api';
import { ZeroShotContext } from '../ZeroShotContextProvider';

interface ParameterInfoLayerProps {
  values: ZeroShotParameter;
}

const StyledParameterInfoLayer = styled.section`
  padding: 0;
  width: 11.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: ${FontSize['Lg']};
    font-weight: ${FontWeight['SemiBold']};
  }
  ul {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
    color: ${Grey[200]};
    li {
      list-style: disc;
      line-height: 1.5;
    }
  }
  .sup-button {
    width: 100%;
  }
`;

const ParameterInfoLayer = ({ values }: ParameterInfoLayerProps) => {
  const { parameters, parameterMap, setParameterMap, selectedSource } =
    useContext(ZeroShotContext);

  const parameterList = useMemo(
    () =>
      Object.entries(values).map(([key, value]) => {
        const label = parameters.find((param) => param.name === key)?.label;
        return {
          label,
          value,
        };
      }),
    [values, parameters]
  );

  const handleApply = useCallback(() => {
    if (!selectedSource?.id) return;
    setParameterMap({
      ...parameterMap,
      [selectedSource.id]: values,
    });
  }, [values, selectedSource?.id, setParameterMap, parameterMap]);

  return (
    <StyledParameterInfoLayer>
      <h3>Applied Values</h3>
      <ul>
        {parameterList.map((param) => (
          <li key={param.label}>
            {param.label} : {param.value}
          </li>
        ))}
      </ul>
      <Button variant="outlined" color={Primary[400]} onClick={handleApply}>
        Apply
      </Button>
    </StyledParameterInfoLayer>
  );
};

export default ParameterInfoLayer;
