import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import {
  _addMusic,
  _deleteMusic,
  _loadMusics,
  _updateMusic,
} from '../../api/project';
import { musicListAtom } from '../atoms/music';
import { Music } from '../project';

export const useMusic = () => {
  const [musicList, setMusicList] = useRecoilState(musicListAtom);
  const getMusicList = useCallback(
    async (sceneId: string) => {
      const result = (await _loadMusics(sceneId)) as Music[];
      setMusicList(result);
      return result;
    },
    [setMusicList]
  );

  const addMusic = useCallback(
    async (value: Music) => {
      const result = (await _addMusic(value)) as Music[];
      setMusicList(result);
    },
    [setMusicList]
  );

  const updateMusic = useCallback(
    async (value: Music) => {
      const result = (await _updateMusic(value)) as Music[];
      setMusicList(result);
    },
    [setMusicList]
  );

  const deleteMusic = useCallback(
    async (value: Music) => {
      const result = (await _deleteMusic(value)) as Music[];
      setMusicList(result);
    },
    [setMusicList]
  );

  return {
    musicList,
    getMusicList,
    addMusic,
    updateMusic,
    deleteMusic,
  };
};
