import classNames from 'classnames';
// temp code for drag and drop with file upload
import React, { PropsWithChildren, useCallback, useMemo, useRef } from 'react';

import StyledDropZone from './StyledDropZone';

const typeRegex = /^.*\/.*$/;
const extensionRegex = /^\.[^\s]+$/;

export type DIRECTION =
  | 'upper_left'
  | 'upper_right'
  | 'lower_left'
  | 'lower_right';
interface DropZoneProps {
  dargKey?: string;
  className?: string;
  addFiles: (files: File[], direction?: DIRECTION) => void;
  dropzoneText?: string;
  accept?: string;
  onDrop?: (e: React.DragEvent<HTMLElement>) => void;
}
const DropZone: React.FC<PropsWithChildren<DropZoneProps>> = ({
  dargKey,
  children,
  className,
  addFiles,
  accept,
  dropzoneText = 'Drop files here',
  onDrop,
}) => {
  const accepts = useMemo(() => accept?.split(',') || [], [accept]);
  const ref = useRef<HTMLElement>(null);
  const attachFile = useCallback(
    (files: File[], direction: DIRECTION) => {
      let acceptedFiles = [] as File[];
      accepts.forEach((accept) => {
        if (typeRegex.test(accept || '')) {
          files.forEach((file) => {
            if (!file.type.startsWith(accept || '')) {
              // fixme toast or ignore?
              // fixme throw new Error(`File ${file.name} is not supported`);
            }
          });
          acceptedFiles = acceptedFiles.concat(
            files.filter((file) => file.type.startsWith(accept))
          );
        } else if (extensionRegex.test(accept || '')) {
          files.forEach((file) => {
            if (!file.name.endsWith(accept || '')) {
              // fixme toast or ignore?
              // fixme throw new Error(`File ${file.name} is not supported`);
            }
          });
          acceptedFiles = acceptedFiles.concat(
            files.filter((file) => file.name.endsWith(accept || ''))
          );
        }
        // for duplicated case .wav,audio/wav
        files = files.filter((file) => !acceptedFiles.some((f) => f === file));
      });
      addFiles(acceptedFiles, direction);
      ref.current?.classList.remove('selected');
    },
    [accepts, addFiles]
  );

  return (
    <StyledDropZone
      className={classNames('sup-file-content', className)}
      ref={ref}
      onDragLeave={(e) => {
        e.preventDefault();
        if (!ref.current?.contains(e.relatedTarget as Node)) {
          ref.current?.classList.remove('selected');
        }
      }}
      onDragOver={(e) => {
        e.preventDefault();
        const isFileDrag = e.dataTransfer?.types[0] === 'Files';
        isFileDrag && ref.current?.classList.add('selected');
      }}
      onDrop={(e) => {
        e.preventDefault();
        if (dargKey && e.dataTransfer?.getData(dargKey)) {
          return onDrop?.(e);
        }
        let files = Array.prototype.slice.call(e.dataTransfer.files);
        const { width, left, top, height } =
          e.currentTarget.getBoundingClientRect();
        const vertical = e.clientY - top > height / 2 ? 'lower' : 'upper';
        const horizontal = e.clientX - left > width / 2 ? 'right' : 'left';
        attachFile(files, `${vertical}_${horizontal}`);
      }}
    >
      {children}
      <p className="dropzone-text">{dropzoneText}</p>
    </StyledDropZone>
  );
};

export default DropZone;
