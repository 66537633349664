import { HTMLAttributes, ReactNode } from 'react';

interface ModalBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const ModalBody = ({ children, ...rest }: ModalBodyProps) => {
  return (
    <div className="modal-body" {...rest}>
      {children}
    </div>
  );
};

export default ModalBody;
