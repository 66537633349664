import IconButton from '@/components/Button/IconButton';
import Loading from '@/components/Loading/Loading';
import Scrollbar from '@/components/Scrollbar/Scrollbar';
import classNames from 'classnames';
import { scaleLinear } from 'd3-scale';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ReactComponent as SnapIcon } from '../../assets/icons/SnapIcon.svg';
import { ReactComponent as ZoomInIcon } from '../../assets/icons/ZoomInIcon.svg';
import { ReactComponent as ZoomOutIcon } from '../../assets/icons/ZoomOutIcon.svg';
import { useTimelineContext } from '../../providers/TimelineContextProvider';
import { currentPlaybackAtom } from '../../stores/atoms/audio';
import { isLoadingProjectAtom } from '../../stores/atoms/ui';
import AudioAxis from './AudioAxis';
import AudioContainer from './AudioContainer';
import {
  DEFAULT_TIME_RANGE,
  DEFAULT_VIEWPORT_RANGE,
  ZOOM_SCALE_RANGE,
} from './const';
import Indicator from './Indicator';
import TimeAxis from './TimeAxis';

export interface Size {
  width: number;
  height: number;
}
interface TimelineProps {
  size: Size;
}

const Timeline = ({ size }: TimelineProps) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const currentPlayback = useRecoilValue(currentPlaybackAtom);

  const { timelineList } = useTimelineContext();
  const isLoadingProject = useRecoilValue(isLoadingProjectAtom);

  const [viewportXRange, setViewportXRange] = useState<[number, number]>(
    DEFAULT_VIEWPORT_RANGE
  );

  // TODO: 추후 양 옆 특정 위치 도달 시 타임라인 이동
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeRange, setTimeRange] =
    useState<[number, number]>(DEFAULT_TIME_RANGE);

  const [scrollInfo, setScrollInfo] = useState({
    position: 0,
    contentSize: 0,
    viewportSize: 0,
  });

  const [isSnap, setIsSnap] = useState(true);

  const xScale = useMemo(() => {
    return scaleLinear().domain(viewportXRange).range([0, size.width]);
  }, [size.width, viewportXRange]);

  const isZoomOutDisabled = useMemo(() => {
    return (
      viewportXRange[0] === 0 &&
      viewportXRange[1] ===
        ZOOM_SCALE_RANGE[1] *
          (DEFAULT_VIEWPORT_RANGE[1] - DEFAULT_VIEWPORT_RANGE[0])
    );
  }, [viewportXRange]);

  const isZoomInDisabled = useMemo(() => {
    return (
      (viewportXRange[1] - viewportXRange[0]) /
        (DEFAULT_VIEWPORT_RANGE[1] - DEFAULT_VIEWPORT_RANGE[0]) <
      ZOOM_SCALE_RANGE[0]
    );
  }, [viewportXRange]);

  const updateViewportXPosition = useCallback(
    (p: number) => {
      if (!viewportXRange) return;
      const [start, end] = viewportXRange;
      const [min, max] = timeRange;

      // If viewport range is equal to audio buffer duration, do not move viewport
      if (start === min && end === max) return;

      const center = (p * max) / 100;
      const centerPx = xScale(center);
      let newStart = xScale.invert(centerPx - size.width / 2);
      let newEnd = xScale.invert(centerPx + size.width / 2);
      // 시작점이 0보다 작은 경우 시작점을 0에 맞춰서 끝점을 조정
      if (newStart < min) {
        newStart = min;
        newEnd = xScale.invert(xScale(end - start));
      }
      // 끝점이 오디오 버퍼의 끝보다 큰 경우 끝점을 오디오 버퍼의 끝에 맞춰서 시작점을 조정
      else if (newEnd > max) {
        newStart = xScale.invert(xScale(newStart - newEnd + max));
        newEnd = max;
      }
      if (start === newStart && end === newEnd) return;
      setViewportXRange?.([newStart, newEnd]);
    },
    [viewportXRange, timeRange, xScale, size.width]
  );

  const updateViewportXScale = useCallback(
    (ratio: number, centerP?: number) => {
      if (!viewportXRange) return;
      const [start, end] = viewportXRange;
      const unit = DEFAULT_VIEWPORT_RANGE[1] - DEFAULT_VIEWPORT_RANGE[0];

      if (ratio > 1 && isZoomOutDisabled) return;
      if (ratio < 1 && isZoomInDisabled) return;

      const startP = xScale(start);
      const endP = xScale(end);

      const newWidth = (endP - startP) * ratio;
      const mid = centerP ? xScale.invert(centerP) : currentPlayback;

      let newStart = xScale.invert(xScale(mid) - newWidth / 2);
      let newEnd = xScale.invert(xScale(mid) + newWidth / 2);

      const max = ZOOM_SCALE_RANGE[1] * unit;

      if (newStart < 0) {
        newStart = 0;
        newEnd = Math.min(xScale.invert(xScale(0) + newWidth), max);
      } else if (newEnd > max) {
        newEnd = max;
        newStart = Math.max(xScale.invert(xScale(max) - newWidth), 0);
      }

      if (start === newStart && end === newEnd) return;
      setViewportXRange?.([newStart, newEnd]);
    },
    [
      viewportXRange,
      xScale,
      currentPlayback,
      isZoomOutDisabled,
      isZoomInDisabled,
    ]
  );

  useEffect(() => {
    if (!viewportXRange) return;
    const viewportSize = size.width;

    // Only update scrollInfo when viewportSize is changed.
    if (viewportSize !== scrollInfo.viewportSize) {
      setScrollInfo((prev) => ({ ...prev, viewportSize }));
    }

    const contentSize = Math.round(xScale(timeRange[1]) - xScale(timeRange[0]));

    // Only update scrollInfo when contentSize is changed.
    if (contentSize !== scrollInfo.contentSize) {
      setScrollInfo((prev) => ({ ...prev, contentSize }));
    }

    const rangeAvg = (viewportXRange[0] + viewportXRange[1]) / 2;
    let position = (rangeAvg / timeRange[1]) * 100;

    // Only update scrollInfo when position is changed.
    if (position !== scrollInfo.position) {
      setScrollInfo((prev) => ({ ...prev, position }));
    }
  }, [size.width, viewportXRange, timeRange, xScale, scrollInfo]);

  useEffect(() => {
    if (!isLoadingProject) return;
    setViewportXRange(DEFAULT_VIEWPORT_RANGE);
  }, [isLoadingProject]);

  return (
    <div className="sup-timeline" ref={timelineRef}>
      {/* x axis timebase */}
      <TimeAxis size={size} xScale={xScale} />
      {timelineList?.length ? (
        <>
          <div
            className="timeline-body"
            style={{ height: `calc(${size.height}px - 3rem)` }}
          >
            {/* y axis voice */}
            <AudioAxis data={timelineList} />
            {/* timeline item */}
            <AudioContainer
              xScale={xScale}
              data={timelineList}
              xRange={viewportXRange}
              timeRange={timeRange}
              updateXPosition={updateViewportXPosition}
              updateXScale={updateViewportXScale}
              isSnap={isSnap}
            />
          </div>
          {!isLoadingProject && (
            <Indicator
              xScale={xScale}
              currentPlayback={currentPlayback}
              height={size.height}
            />
          )}
          <div className="timeline-scroll">
            <Scrollbar
              viewportSize={scrollInfo.viewportSize}
              position={scrollInfo.position}
              contentSize={scrollInfo.contentSize}
              autoHide={false}
              orientation="horizontal"
              thickness={5}
              onChange={(p) => {
                updateViewportXPosition(p);
              }}
            />
          </div>
          <div className="timeline-btn">
            <IconButton
              className={classNames('snap', { active: isSnap })}
              variant="outlined"
              onClick={() => setIsSnap(!isSnap)}
            >
              <SnapIcon />
            </IconButton>
            <IconButton
              className="zoom-out"
              variant="outlined"
              disabled={isZoomOutDisabled}
              onClick={() => updateViewportXScale(1.25)}
            >
              <ZoomOutIcon />
            </IconButton>
            <IconButton
              className="zoom-in"
              variant="outlined"
              disabled={isZoomInDisabled}
              onClick={() => updateViewportXScale(0.75)}
            >
              <ZoomInIcon />
            </IconButton>
          </div>
        </>
      ) : null}
      {isLoadingProject && (
        <div
          className="timeline-loading"
          style={{ height: `calc(${size.height}px - 3rem)` }}
        >
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Timeline;
