import Button from '@/components/Button/Button';
import { Dropdown } from '@/components/Dropdown';
import useUserTier from '@/hooks/useUserTier';
import { Black, Grey } from '@/styles/Colors';
import NeedPurchasingUser from '@/user/NeedPurchasingUser';
import PurchasedUser from '@/user/PurchasedUser';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledUserInfo, StyledUserLayer } from './StyledUserInfo';

const UserInfo = () => {
  const { isAuthenticated, logout, user } = useSUPAuth();
  const { isNeedPurchasing } = useUserTier();
  const [showUserInfo, setShowUserInfo] = useState(false);
  // get Eng Name & initial
  const name = useMemo(() => {
    if (user?.name) {
      return user.name.split('/').pop();
    } else if (user?.nickname) {
      return user.nickname;
    } else {
      return user?.email;
    }
  }, [user]);
  const initialChar = useMemo(() => {
    return name?.charAt(0).toUpperCase();
  }, [name]);
  const { t } = useTranslation();

  return isAuthenticated ? (
    <StyledUserInfo className="user-info">
      <Dropdown
        isOpen={showUserInfo}
        closeOnClickOutside={true}
        placement={'bottom-right'}
        onClose={() => setShowUserInfo(false)}
        anchorElement={
          <>
            <strong
              className="initial"
              onClick={() => setShowUserInfo(!showUserInfo)}
            >
              <span>{initialChar}</span>
            </strong>
          </>
        }
        style={{
          backgroundColor: Grey[500],
        }}
      >
        <StyledUserLayer className="user-layer">
          {user?.family_name && <strong className="info">{name}</strong>}
          <span className="info">{user?.email}</span>
          <div className="status">
            {isNeedPurchasing ? <NeedPurchasingUser /> : <PurchasedUser />}
          </div>
          <Button
            className="logout"
            color={Black}
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            {t('LOG OUT')}
          </Button>
        </StyledUserLayer>
      </Dropdown>
    </StyledUserInfo>
  ) : null;
};
export default UserInfo;
