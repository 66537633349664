import { Black, Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledZeroShotResultDropDown = styled.section`
  .dropdown-title {
    text-align: center;
    font-size: 1.25rem;
    font-weight: ${FontWeight.SemiBold};
    padding: 0.625rem 0;
  }
  .download-file-name {
    padding: 0 2.125rem;
    .download-file-title {
      font-weight: ${FontWeight.SemiBold};
      padding-bottom: 1rem;
    }
    .download-file-input {
      border-radius: 4px;
      border: 1px solid ${Grey[50]};
      background: ${Black};
      width: 100%;
      box-sizing: border-box;
      text-align: right;
      padding: 0.75rem 1rem;
      color: ${Grey[50]};
    }
  }
  .download-file-language {
    padding: 1.5rem 1.5rem;
    .title {
      margin-left: 0.625rem;
    }
    .lang-check {
      margin-top: 1rem;
      &.disabled {
        color: ${Grey[200]};
      }
    }
  }
  .description {
    margin-top: 1rem;
    color: ${Grey[200]};
    line-height: 150%;
  }
  .download-button-area {
    text-align: right;
    padding: 0.5rem 1.875rem 1rem;
  }
`;
export default StyledZeroShotResultDropDown;
