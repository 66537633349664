import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import useSingleAudioController from '../hooks/useSingleAudioController';
import { audioFileMapAtom, audioPlayerStateAtom } from '../stores/atoms/audio';

const useSingleTakePlay = (takeId?: string) => {
  const { audioBuffer, load, play, stop, isPlaying, currentPosition } =
    useSingleAudioController();

  const [audioPlayerState, setAudioPlayerState] =
    useRecoilState(audioPlayerStateAtom);
  const audioFileMap = useRecoilValue(audioFileMapAtom);

  const handlePlayStop = useCallback(
    (time?: number) => {
      if (isPlaying && typeof time !== 'number') {
        stop();
      } else {
        play(time);
      }
      setAudioPlayerState({
        type: 'standalone',
        takeId: takeId,
        isPlaying: !isPlaying,
      });
      return () => {
        stop();
      };
    },
    [isPlaying, stop, play, setAudioPlayerState, takeId]
  );

  useEffect(() => {
    if (takeId && audioFileMap[takeId]?.audioBuffer) {
      load(audioFileMap[takeId].audioBuffer);
    }
  }, [audioFileMap, takeId, load, audioBuffer]);

  useEffect(() => {
    if (!audioPlayerState) {
      stop();
      return;
    }
    if (
      audioPlayerState.type === 'standalone' &&
      audioPlayerState.takeId === takeId &&
      isPlaying
    )
      return;
    // type이 timeline이면 stop
    // type이 standalone이면서 takeId가 현재 takeId와 같으면 stop
    if (
      (audioPlayerState.type === 'timeline' && audioPlayerState.isPlaying) ||
      (audioPlayerState.type === 'standalone' &&
        audioPlayerState.takeId !== takeId &&
        audioPlayerState.isPlaying)
    ) {
      stop();
    }
  }, [audioPlayerState, stop, takeId, isPlaying]);
  return {
    handlePlayStop,
    isPlaying,
    currentPosition,
  };
};
export default useSingleTakePlay;
