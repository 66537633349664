import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import IconButton from '../../../../components/Button/IconButton';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { Grey } from '../../../../styles/Colors';
import { BasicFontSize } from '../../../../styles/Typography';
import { ReactComponent as MuteNoteIcon } from '../../assets/icons/MuteNoteIcon.svg';
import { ReactComponent as NoteIcon } from '../../assets/icons/NoteIcon.svg';
import VolumeSlider from '../../GlobalAudioPlayer/VolumeSlider';
import { useTimelineContext } from '../../providers/TimelineContextProvider';
import { AudioInfo } from '../../stores/timeline';
import { StyledMusicVolumeContext } from '../../styles/StyledTimelinePanel';

interface MusicAxisItemProps {
  voice: AudioInfo;
}

const MusicContext = ({
  isOpen,
  onClose,
  position,
}: {
  isOpen: boolean;
  onClose?: () => void;
  position: { x: number; y: number };
}) => {
  const musicContextRef = useRef<HTMLDivElement>(null);
  const { musicGainValue, setMusicGainValue } = useTimelineContext();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const CLOSE_TIMEOUT = 2000;

  useOnClickOutside(musicContextRef, () => onClose?.());

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const handleChange = () => {
    if (timeoutId) clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      onClose?.();
    }, CLOSE_TIMEOUT);
    setTimeoutId(newTimeoutId);
  };

  if (!isOpen) return null;

  return createPortal(
    <StyledMusicVolumeContext
      ref={musicContextRef}
      className="volume-context"
      style={{
        left: position.x,
        top: position.y,
      }}
    >
      <h3 className="label">Volume</h3>
      <p className="value">{Math.floor(musicGainValue * 100)}</p>
      <VolumeSlider
        showMute={false}
        value={musicGainValue}
        update={setMusicGainValue}
        onChange={handleChange}
      />
    </StyledMusicVolumeContext>,
    document.getElementById('portal') as HTMLElement
  );
};

const MusicAxisItem = ({ voice }: MusicAxisItemProps) => {
  const musicAxisRef = useRef<HTMLDivElement>(null);
  const [openVolumeContext, setOpenVolumeContext] = useState(false);
  const [contextPosition, setContextPosition] = useState({ x: 0, y: 0 });
  const { musicGainValue } = useTimelineContext();

  useEffect(() => {
    if (!musicAxisRef.current) return;
    const { right, bottom } = musicAxisRef.current.getBoundingClientRect();
    const OFFSET = 8;
    const CONTEXT_HEIGHT = 5.25 * BasicFontSize;
    setContextPosition({
      x: right + OFFSET,
      y: bottom - CONTEXT_HEIGHT,
    });
  }, [openVolumeContext]);

  return (
    <div className="axis-y-item-music" ref={musicAxisRef}>
      <IconButton
        id={`${voice.id}`}
        aria-label={`${voice.name}`}
        className={classNames(musicGainValue === 0 && 'mute')}
        onPointerDown={(e) => {
          e.stopPropagation();
          setOpenVolumeContext(!openVolumeContext);
        }}
        variant="none"
        color={Grey[50]}
        isFillCurrentColor={false}
      >
        {musicGainValue === 0 ? <MuteNoteIcon /> : <NoteIcon />}
      </IconButton>
      <MusicContext
        isOpen={openVolumeContext}
        position={contextPosition}
        onClose={() => setOpenVolumeContext(false)}
      />
    </div>
  );
};

export default MusicAxisItem;
