import Title from '@/components/Title/Title';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ZeroShotContext } from '../ZeroShotContextProvider';
import {
  LanguageParam,
  languageList,
  languageParamMap,
  sampleSentences,
} from './const';
import Select, { SelectItem } from './Select';

const TextField = () => {
  const { selectedSource, textMap, setTextMap, setLanguage, languageMap } =
    useContext(ZeroShotContext);
  const [selectedLanguage, setSelectedLanguage] = useState<SelectItem>(
    languageList[0]
  );
  const onChangeLanguage = useCallback(
    (item: SelectItem) => {
      setSelectedLanguage(item);
      setLanguage(item.value as LanguageParam);
    },
    [setSelectedLanguage, setLanguage]
  );
  const sentenceList = useMemo(
    () =>
      sampleSentences
        .filter((sentence) => sentence.lang === selectedLanguage.value)
        .map((sentence, idx) => ({
          label: `${idx + 1}. ${sentence.content}`,
          value: sentence.content,
        })),
    [selectedLanguage]
  );

  const [selectedSentence, setSelectedSentence] = useState<SelectItem>(
    sentenceList[0]
  );

  const [textareaValue, setTextareaValue] = useState(selectedSentence.value);

  const handleTextChange = useCallback(
    (text: string) => {
      if (!selectedSource?.id) {
        setTextareaValue(selectedSentence.value);
        return;
      }
      setTextareaValue(text);
      setTextMap({
        ...textMap,
        [selectedSource.id]: text,
      });
    },
    [selectedSource?.id, textMap, setTextMap, selectedSentence]
  );

  const handleSentenceChange = useCallback(
    (sentence: SelectItem) => {
      setSelectedSentence(sentence);
      handleTextChange(
        typeof sentence.value === 'number'
          ? sentence.value.toString()
          : sentence.value
      );
    },
    [setSelectedSentence, handleTextChange]
  );

  useEffect(() => {
    if (!selectedSource?.id) return;
    // 선택된 언어 설정이 없는 경우 첫번째 언어로 초기화
    const language =
      languageList.find(
        (lang) =>
          languageParamMap[lang.value as LanguageParam] ===
          languageMap[selectedSource.id]
      ) || languageList[0];
    setSelectedLanguage({ ...language });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSource?.id]);

  useEffect(() => {
    if (!selectedSource?.id) return;
    // 비어있는 문장이면 첫번째 문장으로 초기화
    const sentence = textMap[selectedSource.id] || sentenceList[0].value;
    setTextareaValue(sentence);
    setTextMap({
      ...textMap,
      [selectedSource.id]: sentence,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <section className="section-tts">
      <Title size="lg">TEXT TO SPEECH</Title>
      <section className="select-wrapper">
        {/* Language Selector */}
        <Select
          items={languageList}
          selectedItem={selectedLanguage}
          onChange={onChangeLanguage}
          buttonProps={{
            disabled: !selectedSource,
          }}
        />

        <Select
          items={sentenceList}
          onChange={(item) => {
            handleSentenceChange(item);
          }}
          fixedLabel="SENTENCE"
          buttonProps={{
            disabled: !selectedSource,
          }}
        />
      </section>

      {/* Sample Sentence Selector */}
      <textarea
        className="text-field"
        spellCheck={false}
        placeholder="Write something here ..."
        value={textareaValue}
        disabled={!selectedSource}
        onChange={(e) => handleTextChange(e.target.value)}
      ></textarea>
    </section>
  );
};

export default TextField;
